import {states} from "../../constants/States";
import {createSelector} from "reselect";
import {getActiveCompany} from "./companySelector";

/**
 * Get info current user
 *
 *  @returns {object}
 */
export const getUser = (state) => {
    return state.auth.user;
}

/**
 * Check if token is set in redux store
 *
 *  @returns {boolean}
 */
export const isTokenSet = (state) => {
    return Boolean(state.auth.token);
}

/**
 * Check if User is loggedIn by localState
 *
 *  @returns {boolean}
 */
export const isLoggedIn = ()=>{
    const exists = localStorage.getItem('loggedin');
    return exists || false
}

/**
 * Check if Scoretrace Admin
 *
 *  @returns {boolean}
 */
export const isScoretraceAdmin = createSelector(
    [
        getActiveCompany,
        getUser
    ],
    (activeCompany, user) => {
        if (!activeCompany) {
            return user.isAdmin || false;
        }
        return activeCompany?.roles?.includes(states.STATE_ROLE_ADMIN) || false;
    }
);
