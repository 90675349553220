import i18n from "../../../../i18n/i18n";

export default {
    formField: {
        remarks: {
            label: 'Opmerking planning',
            name: 'remarks',
        },
        remarks_driver: {
            name: 'remarks_driver',
            label: 'Opmerking chauffeur',
        },
        form_type: {
            name: 'form_type',
            label: 'Formulier type'
        },
        share_template: {
            name: 'share_template',
            label: 'Template delen met bedrijf'
        },
        //================================
        //Blok 1
        //================================
        project_id: {
            name: 'project_id',
        },
        client_id: {
            name: 'client_id',
        },
        region_id: {
            name: 'region_id',
        },
        disposer_type: {
            name: 'disposer_type',
            label: 'disposer type',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        senderparty_name: {
            name: 'name',
            label: 'afzender',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectName: 'Naam ongeldig',
        },
        senderpartyaddress_street_name: {
            name: 'address_street_name',
            label: 'straat + nr',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectAddress: 'Straat ongeldig',
        },
        senderpartyaddress_house_no: {
            name: 'address_house_no',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            positiveErrorMsg: 'Huisnummer kan niet negatief zijn',
        },
        senderpartyaddress_house_no_addition: {
            name: 'address_house_no_addition',
        },
        senderpartyaddress_postal_code: {
            name: 'address_postal_code',
            label: 'postc. + woonpl.',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectPostalCode: 'Postcode ongeldig',
            maxLength: 'Maximale lengte is 10 karakters'
        },
        senderpartyaddress_city_name: {
            name: 'address_city_name',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectCityName: 'Plaats ongeldig',
        },
        senderparty_identifier_value: {
            name: 'identifier_value',
            label: 'VIHB-nummer',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            vihbIncorrectMsg: 'VIHB nummer is niet correct ingevuld (code + nummers)',
            minErrorMsg: 'VIHB nummer bestaat uit minimaal 10 karakters',
            maxErrorMsg: 'VIHB nummer bestaat uit maximaal 10 karakters',
        },
        //================================
        // Blok 2
        //================================
        invoiceaddress_street_name: {
            name: 'street_name',
            label: 'postbus / straat + nr',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        invoiceaddress_house_no: {
            name: 'house_no',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            positiveErrorMsg: 'Huisnummer kan niet negatief zijn',
        },
        invoiceaddress_description: {
            name: 'description',
            label: 'factuuradres',
            labelExtra: 'MELDER',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        invoiceaddress_house_no_addition: {
            name: 'house_no_addition',
        },
        invoiceaddress_postal_code: {
            name: 'postal_code',
            label: 'postc. + woonpl.',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            maxLength: 'Maximale lengte is 10 karakters'
        },
        invoiceaddress_city_name: {
            name: 'city_name',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },

        //================================
        // Blok 3a
        //================================
        disposerparty_name: {
            name: 'name',
            label: 'ontdoener',
            labelExtra: 'Toepasser eigenaar',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectName: 'Naam ongeldig',
        },
        disposerpartyaddress_street_name: {
            name: 'address_street_name',
            label: 'straat + nr',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectAddress: 'Straat ongeldig',
        },
        disposerpartyaddress_house_no: {
            name: 'address_house_no',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            positiveErrorMsg: 'Huisnummer kan niet negatief zijn',
        },
        disposerpartyaddress_house_no_addition: {
            name: 'address_house_no_addition',
        },
        disposerpartyaddress_postal_code: {
            name: 'address_postal_code',
            label: 'postc. + woonpl.',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectPostalCode: 'Postcode ongeldig',
            maxLength: 'Maximale lengte is 10 karakters'
        },
        disposerpartyaddress_city_name: {
            name: 'address_city_name',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectCityName: 'Plaats ongeldig',
        },

        //================================
        // Blok 3e
        //================================
        locationorigin_description: {
            name: 'description',
            label: 'locatie van herkomst',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectName: 'Naam ongeldig',
            maxLength: 'Mag maar maximaal 50 karakters lang zijn',
        },
        locationoriginaddress_street_name: {
            name: 'address_street_name',
            label: 'straat + nr',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectAddress: 'Straat ongeldig',
        },
        locationoriginaddress_house_no: {
            name: 'address_house_no',
            positiveErrorMsg: 'Huisnummer kan niet negatief zijn',
        },
        locationoriginaddress_house_no_addition: {
            name: 'address_house_no_addition',
        },
        locationoriginaddress_postal_code: {
            name: 'address_postal_code',
            label: 'postc. +',
            maxLength: 'Maximale lengte is 10 karakters'
        },
        locationoriginaddress_city_name: {
            name: 'address_city_name',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            label: ' woonpl.',
            incorrectCityName: 'Plaats ongeldig',
        },

        //================================
        // BLok 4a
        //================================
        agentparty_name: {
            name: 'name',
            label: 'uitbesteed vervoerder',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        agentpartyaddress_street_name: {
            name: 'address_street_name',
            label: 'straat + nr',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        agentpartyaddress_house_no: {
            name: 'address_house_no',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            positiveErrorMsg: 'Huisnummer kan niet negatief zijn',
        },
        agentpartyaddress_house_no_addition: {
            name: 'address_house_no_addition',
        },
        agentpartyaddress_postal_code: {
            name: 'address_postal_code',
            label: 'postc. + woonpl.',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            maxLength: 'Maximale lengte is 10 karakters'
        },
        agentpartyaddress_city_name: {
            name: 'address_city_name',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },

        //================================
        //Blok 4e
        //================================
        locationdestination_description: {
            name: 'description',
            label: 'locatie van bestemming',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectName: 'Naam ongeldig',
            maxLength: 'Mag maximaal 50 karakters lang zijn',
        },
        locationdestinationaddress_street_name: {
            name: 'address_street_name',
            label: 'straat + nr',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectAddress: 'Straat ongeldig',
        },
        locationdestinationaddress_house_no: {
            name: 'address_house_no',
            positiveErrorMsg: 'Huisnummer kan niet negatief zijn',
        },
        locationdestinationaddress_house_no_addition: {
            name: 'address_house_no_addition',
        },
        locationdestinationaddress_postal_code: {
            name: 'address_postal_code',
            label: 'postc. +',
            maxLength: 'Maximale lengte is 10 karakters'
        },
        locationdestinationaddress_city_name: {
            name: 'address_city_name',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            label: ' woonpl.',
            incorrectCityName: 'Plaats ongeldig',
        },

        //================================
        //Blok 5
        //================================
        template_transported_by_type: {
            name: 'transported_by_type',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        carrierparty_name: {
            name: 'name',
            label: 'ontvanger / inzamelaar / vervoerder',
            labelB2: 'ontv/ inzam/ vervoerder',
            labelExtra: 'Toepasser uitvoerder',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        carrierpartyaddress_street_name: {
            name: 'address_street_name',
            label: 'straat + nr',
        },
        carrierpartyaddress_house_no: {
            name: 'address_house_no',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            positiveErrorMsg: 'Huisnummer kan niet negatief zijn',
        },
        carrierpartyaddress_house_no_addition: {
            name: 'address_house_no_addition',
        },
        carrierpartyaddress_postal_code: {
            name: 'address_postal_code',
            label: 'postc. + woonpl.',
            maxLength: 'Maximale lengte is 10 karakters'
        },
        carrierpartyaddress_city_name: {
            name: 'address_city_name',
        },
        carrierparty_identifier_value: {
            name: 'identifier_value',
            label: 'VIHB-nummer',
            vihbIncorrectMsg: 'VIHB nummer is niet correct ingevuld (code + nummers)',
            minErrorMsg: 'VIHB nummer bestaat uit minimaal 10 karakters',
            maxErrorMsg: 'VIHB nummer bestaat uit maximaal 10 karakters',
        },
        template_is_route_collection: {
            name: 'is_route_collection',
            label: 'route-inzameling',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        template_is_collection_scheme: {
            name: 'is_collection_scheme',
            label: 'inzamelaarsregeling',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        template_is_repetitive_loads: {
            name: 'is_repetitive_loads',
            label: 'repeterende vrachten',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },

        //================================
        //Blok 6
        //================================
        template_waste_stream_id: {
            name: 'waste_stream_id',
            label: 'Afvalstroomnummer',
            labelExtra: 'Meldingnummer (uit meldsysteem)',
            labelReport: 'Meldingsnummer',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            maxErrorMsg: 'Afvalstroom nr. mag maximaal 12 karakters bevatten',
        },
        gooditem_description: {
            name: 'description',
            label: 'Gebruikelijke benaming van de afvalstoffen',
            labelExtra: 'Materiaal type',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectDescription: 'Omschrijving ongeldig'
        },
        gooditem_eural_code: {
            name: 'eural_code',
            label: 'eural code',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectEuralCode: 'Eural code ongeldig'
        },
        gooditem_processing_method: {
            name: 'processing_method',
            label: 'verw. meth',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            incorrectProcessingMethod: 'Verw. methode ongeldig'
        },
        gooditem_estimated_unit_value: {
            name: 'estimated_unit_value',
            label: 'geschatte hoeveelheid (kg)',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            minErrorMsg: 'Geschatte hoeveelheid kan niet negatief zijn',
            maxErrorMsg: 'Geschatte hoeveelheid is te veel',
        },

        //================================
        // Settings
        //================================
        template_project_description: {
            name: 'project_description',
            label: 'Project omschrijving',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        template_work_description: {
            name: 'work_description',
            label: 'Werk omschrijving',
        },
        template_logo_image: {
            name: 'logo_image',
            label: 'File upload',
        },
        order_type: {
            name: 'order_type',
            label: 'Werkzaamheden',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        gooditem_unit_type_id: {
            name: 'unit_type_id',
            label: 'Eenheidstype',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        template_show_color: {
            name: 'show_color',
            label: 'Kleur',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },

        //================================
        //Weegbrug
        //================================
        template_weight_bridge_uuid: {
            name: 'weight_bridge_uuid',
            label: 'Weegbrug',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        template_weight_bridge_name: {
            name: 'weight_bridge_name',
        },
        template_reference: {
            name: 'reference',
            label: 'Referentie',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            maxLength: 'Maximale lengte is 36 karakters'
        },

        //================================
        //Temp Unittype description
        //================================
        unitType_description: {
            name: 'description',
        },

        //================================
        //Report number
        //================================
        remark: {
            name: 'remark',
            label: 'OPMERKINGEN',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        type: {
            name: 'type',
            label: 'VERKLARING TYPE',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        document_number: {
            name: 'document_number',
            label: 'VERKLARING DOCUMENTNUMMER',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },
        organisation_name: {
            name: 'organisation_name',
            label: 'VERKLARING ORGANISATIENAAM',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
        },

        //================================
        //OUDFooter
        //================================
        senderpartyaddress_mail: {
            name: 'address_mail',
            label: 'Mail afzender:',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            emailErrorMsg: 'Email is niet geldig',
        },
        disposerpartyaddress_mail: {
            name: 'address_mail',
            label: 'Mail ontdoener:',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            emailErrorMsg: 'Email is niet geldig',
        },
        locationdestinationaddress_mail: {
            name: 'address_mail',
            label: 'Mail ontvanger:',
            requiredErrorMsg: i18n.t('FIELD_IS_MANDATORY'),
            emailErrorMsg: 'Email is niet geldig',
        },
    }
};