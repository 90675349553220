import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";
import update from "immutability-helper";

const initialState = {
    loading: false,
    error: null,
    errorState:false,
    success:false,
    data: {},
    activationKeyFound: null,
    activateError: null,
    activated: false
}

const startLoading = (state) => {
    return updateObject(state, {loading: true});
}

const setError = (state, action) => {
    return updateObject(state, {loading: false, error: action.error,errorState:true});
}
const closeError = (state, action) => {
    return updateObject(state, {error: "", errorState:action.data});
}

const fetchDataSuccess = (state, action) => {
    return update(state , {$set : {data: action.data, loading: false,success:true}});
}

const registerCheckActivationKeySucces = (state, action) => {
    return updateObject(state, {loading: false, activationKeyFound: action.data, activateError: null});
}

const setActivateError = (state, action) => {
    return updateObject(state, {loading: false, activateError : action.error});
}

const activateSuccess = (state) => {
    return updateObject(state, {loading: false, activateError: null, activated: true});
}

const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.REGISTER_START_LOADING: return startLoading(state);
        case actionTypes.REGISTER_USER_START: return startLoading(state, action);
        case actionTypes.REGISTER_USER_SUCCESS: return fetchDataSuccess(state, action);
        case actionTypes.REGISTER_USER_FAIL: return setError(state, action);
        case actionTypes.REGISTER_USER_FAIL_ALERT: return closeError(state, action);
        case actionTypes.REGISTER_CHECK_ACTIVATION_KEY_SUCCESS: return registerCheckActivationKeySucces(state, action);
        case actionTypes.SET_ACTIVATE_ERROR: return setActivateError(state, action);
        case actionTypes.ACTIVATE_SUCCESS: return activateSuccess(state, action);
        default: return state;
    }
}

export default reducer;