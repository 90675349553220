import React from "react";
import HelpIcon from '@mui/icons-material/Help';
import {Link as RouterLink} from "react-router-dom";
import {IconButton, Tooltip} from "@mui/material";
import i18n from "../../../i18n/i18n";

export default function HeaderHelp() {
    return (
        <>
            <Tooltip title={i18n.t('NEED_HELP')}>
                <IconButton
                    component={RouterLink}
                    key="Handleiding"
                    to={'/hulp-nodig'}
                    aria-label="delete"
                    size="small"
                    disableRipple
                    sx={{color: '#FBE2D4', justifyContent:'center'}}
                >
                    <HelpIcon/>
                </IconButton>
            </Tooltip>
        </>
    )
}