import Grid from "@mui/material/Grid";
import React from "react";
import customClasses from "./TemplateForm.module.css";
import PropTypes from "prop-types";
import {FormTextField} from "./Components/FormTextField";
import {FastField} from "formik";

export default function TemplateFormReportStatement(props) {
    const group = "report_statement";

    const {
        formField: {
            remark,
            type,
            document_number,
            organisation_name
        }
    } = props;


    return <React.Fragment>
        {/*===========================================================================*/}
        {/*Block Divider*/}
        {/*===========================================================================*/}
        <Grid container item xs={12}/>
        {/*===========================================================================*/}
        {/*Block Content
        {/*===========================================================================*/}
        {/*<Grid className={customClasses.containerReportNumber} container item xs={12}>*/}
        {/*    <Grid item xs={11} md={2}>*/}
        {/*        {remark.label}*/}
        {/*    </Grid>*/}
        {/*    /!*<Grid item xs={12} md={10}>*!/*/}
        {/*    /!*    <FastField*!/*/}
        {/*    /!*        autoComplete="new-password"*!/*/}
        {/*    /!*        name={group + "." + remark.name}*!/*/}
        {/*    /!*        disabled={props.disabled}*!/*/}
        {/*    /!*        sizeClass={customClasses.inputFull}*!/*/}
        {/*    /!*        component={FormTextField}*!/*/}
        {/*    /!*    />*!/*/}
        {/*    /!*</Grid>*!/*/}
        {/*</Grid>*/}
        <Grid className={customClasses.containerReportNumber} container item xs={12}>
            <Grid item xs={11} md={12}>
                <strong style={{hyphens: 'auto'}}>BEWIJSMIDDEL</strong>
            </Grid>
            <Grid item xs={11} md={2}>
                {type.label}
            </Grid>
            <Grid item xs={12} md={10}>
                <FastField
                    name={group + "." + type.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputFull}
                    component={FormTextField}
                    autoComplete="new-password"
                />
            </Grid>
            <Grid item xs={11} md={2}>
                {document_number.label}
            </Grid>
            <Grid item xs={12} md={10}>
                <FastField
                    name={group + "." + document_number.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputFull}
                    component={FormTextField}
                    autoComplete="new-password"
                />
            </Grid>
            <Grid item xs={11} md={2}>
                {organisation_name.label}
            </Grid>
            <Grid item xs={12} md={10}>
                <FastField
                    name={group + "." + organisation_name.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputFull}
                    component={FormTextField}
                    autoComplete="new-password"
                />
            </Grid>
        </Grid>
    </React.Fragment>
}

TemplateFormReportStatement.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    values: PropTypes.object,
};