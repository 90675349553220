import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import TemplateForm from "../../TemplatesV2/TemplateForm";
import {Card, Paper} from "@mui/material";
import customClasses from "./Check.module.css"
import Header from "../../../Header/Formklub/Header";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../../../Animation/Loading";
import CommentForm from "./CommentForm";
import {getInfo, sendComment, setCommentData} from "../../../../store/actions/templateAudit";
import ConfirmDialog from "../../../Dialog/ConfirmDialog";
import {Alert} from "@mui/material";
import {useParams} from "react-router-dom";
import {isLoggedIn} from "../../../../store/selectors/userSelector";

export default function Check() {
    const dispatch = useDispatch();
    const [templateStateText, setTemplateStateText] = React.useState('');
    const [templateState, setTemplateState] = React.useState('');
    const { auth_key } = useParams();
    const loggedIn = useSelector(state => isLoggedIn(state));

    const loading = useSelector(state => {
        return state.templateAudit.loading ?? null
    });

    const error = useSelector(state => {
       return state.templateAudit.error ?? null
    });

    const auditData = useSelector(state => {
        return state.templateAudit ?? null;
    });

    const auditorData = useSelector(state => {
        return state.templateAudit.auditor ?? null;
    });

    const templateData = useSelector(state => {
        return state.templateAudit.template ?? null;
    });

    const commentData = useSelector(state => {
        return state.templateAudit.commentData ?? null;
    })

    const commentSended = useSelector(state => {
        return state.templateAudit.commentSended;
    })

    useEffect(() => {
        if(!auditData){
            return;
        }
        switch(auditData.state_id){
            case '20':
                setTemplateStateText('Deze template is goedgekeurd');
                setTemplateState('success');
                break;
            case '90':
                setTemplateStateText('Deze template is afgekeurd');
                setTemplateState('error');
                break;
        }
    },[auditData]);

    useEffect(() => {
        dispatch(getInfo(auth_key));
    },[auth_key]);

    if(loading) {
        return <Loading size={300}/>
    }

    let confirmationPopup = "";
    if(commentData !== null) {
        confirmationPopup = <ConfirmDialog
            open={true} title={"Versturen?"}
            handleClose={() => {
                dispatch(setCommentData(null))
            }}
            handleAgreed={() => {
                dispatch(sendComment(auth_key, commentData))
            }}></ConfirmDialog>
    }

    let content = "";
    if(error !== null || (!commentSended && (auditorData == null || templateData == null))) {
        content =
            <Grid item xs={12} md={12} >
                <Alert severity="error">Kan niet gevonden worden of is verlopen.</Alert>
            </Grid>
    } else if(commentSended) {
        content =
            <Grid item xs={12} md={12} >
                <Alert severity="success">Opgeslagen</Alert>
            </Grid>
    } else {
        content = <>
            <Grid item xs={12} sm={12} md={4} xl={4}>
                <Card elevation={3} className={customClasses.containerInfo}>
                    <Paper variant="outlined" className={customClasses.checkPaperInfo}>
                        <Typography variant="h5" component="h2">
                            Template van: {templateData.owner}<br />
                            Regio: {templateData.region}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            {auditorData.name}
                        </Typography>
                        <Typography variant="body2" component="p">
                            Email: {auditorData.email_address}
                        </Typography>
                    </Paper>
                    <Divider className={customClasses.checkDivider}/>
                    {
                        auditData.state_id === '10' ? (
                            <CommentForm template_id={templateData.id} template_auditor_id={auditorData.id}/>
                        ) : (
                            <Alert severity={templateState}>{templateStateText}</Alert>
                        )
                    }

                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={8} xl={8} >
                <Paper variant="outlined" elevation={0} className={customClasses.checkPaper}>
                    <TemplateForm disabled={true} data={templateData} action={'view'}/>
                </Paper>
            </Grid>
        </>
    }

    return <React.Fragment>
        {!loggedIn && <Header headerOnly={true}/>}
        <div className={!loggedIn ? customClasses.checkContainer : customClasses.checkContainerLoggedIn }>
            <Grid container spacing={4} alignItems="flex-start">
                {content}
            </Grid>
        </div>
        { confirmationPopup }
    </React.Fragment>;
}

Check.propTypes = {
    data: PropTypes.object,
};
