import axios, {InternalAxiosRequestConfig} from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_FORMKLUB_URL,
    withCredentials: true,
    headers: {
        "Content-Type": `application/json`,
        Accept: `application/json`,
    }
});


instance.interceptors.request.use(
    function (config: InternalAxiosRequestConfig) {

        config.headers = config.headers ?? {};
        config.url = config.url ?? '';

        const token = localStorage.getItem('token')
        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;
        } else {
            console.log('No token given!');
        }

        if (config.url !== '/authorize/introspect') {
            let questionMarked = config.url.includes("?");

            const regionsSelected = localStorage.getItem("regionsSelected") ?? '[]';
            const selectedRegions: any[] = JSON.parse(regionsSelected);

            if (selectedRegions != null) {
                config.url += (questionMarked ? "&" : '?') + "selected_regions=" + selectedRegions.join(',');
            }
        }
        return config;
    }, function (err) {
        return Promise.reject(err);
    });


//instance.defaults.withCredentials = true

export default instance;