import React, {useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import {Collapse, Typography, List, ListItemText, ListItemIcon, ListItemButton} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import SideMenuItems from "./SideMenuItems";
import {useSelector} from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import {
    getActiveCompanyUUID, getCompanyOverloadModuleRight,
    getCompanyRoles,
} from "../../../store/selectors/companySelector";
import {getScopes} from "../../../store/selectors/scopeSelector";
import {getUser} from "../../../store/selectors/userSelector";
import {getHasRightsByIndex} from "../../../store/selectors/actionsRightsSelector";
import PropTypes from "prop-types";

const PREFIX = 'SideMenu';

const classes = {
    root: `${PREFIX}-root`,
    button: `${PREFIX}-button`,
    selected: `${PREFIX}-selected`,
    menuItem: `${PREFIX}-menuItem`,
    menuItemAdmin: `${PREFIX}-menuItemAdmin`,
    nested: `${PREFIX}-nested`,
    nestedText: `${PREFIX}-nestedText`,
    nestedContainer: `${PREFIX}-nestedContainer`,
    nestedContainerAdmin: `${PREFIX}-nestedContainerAdmin`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
    [`& .${classes.menuItem}`]: {
        paddingTop: '10px',
        paddingBottom: '10px ',
        paddingLeft: '13px',
        userDrag: 'none',
        minWidth: 49,
        marginBottom: 5,
        borderRadius: '10px',
        '& .MuiSvgIcon-root': {
            color: '#D2D8DD',
        },
        '&:hover': {
            backgroundColor: "#203A56",
            boxShadow: "0 0 5px #395069",
            "& .MuiSvgIcon-root": {color: "#D2D8DD",},
        },
        "&.Mui-selected": {
            boxShadow: "0 0 5px #395069",
            backgroundColor: "#203A56",
            color: "#D2D8DD",
            "& .MuiSvgIcon-root": {color: "#D2D8DD",},
        },
    },

    [`& .${classes.menuItemAdmin}`]: {
        paddingTop: '10px',
        paddingBottom: '10px ',
        borderRadius: '10px',
        paddingLeft: '13px',
        userDrag: 'none',
        backgroundColor: '#252525 !important',
        minWidth: 49,
        marginBottom: 5,
        '& .MuiSvgIcon-root': {
            color: '#D2D8DD',
        },
    },

    [`& .${classes.nested}`]: {
        paddingLeft: '13px',
        userDrag: 'none',
        margin: '0 !important',
        "&:first-of-type": {
            borderBottomRightRadius: '0 !important',
            borderBottomLeftRadius: '0 !important',
            borderTopRightRadius: '10px !important',
            borderTopLeftRadius: '10px !important',
        },
        "&:last-of-type": {
            borderTopRightRadius: '0 !important',
            borderTopLeftRadius: '0 !important',
            borderBottomRightRadius: '10px !important',
            borderBottomLeftRadius: '10px !important',
        },
        "&.Mui-selected": {
            boxShadow: "0 0 5px #395069",
            backgroundColor: "#203A56",
            color: "#D2D8DD",
            borderRadius: 0,
            "& .MuiSvgIcon-root": {color: "#D2D8DD",},
        },
        '&:hover': {
            backgroundColor: "#203A56",
            boxShadow: "0 0 5px #395069",
            "& .MuiSvgIcon-root": {color: "#D2D8DD",},
        },
    },

    [`& .${classes.nestedText}`]: {
        fontSize: '13.5px',
        color: '#D2D8DD'
    },

    [`& .${classes.nestedContainer}`]: {
        minWidth: 49,
        backgroundColor: '#4B6077',
        borderRadius: '10px',
        marginBottom: '10px',
        color: '#D2D8DD',
        boxShadow: 'inset 1px 1px 1px #203a56, 0 0 1px #203a56',
    },

    [`& .${classes.nestedContainerAdmin}`]: {
        minWidth: 49,
        backgroundColor: '#323232',
        borderRadius: '10px',
        boxShadow: 'inset 1px 1px 1px #252525, 0 0 1px #252525',
    }
}));

const SideMenu = (props) => {

    const companyUuid = useSelector(state => getActiveCompanyUUID(state));
    const companyRoles = useSelector(state => getCompanyRoles(state));
    const companyOverloadModule = useSelector(state => getCompanyOverloadModuleRight(state));
    const scopes = useSelector(state => getScopes(state));
    const user = useSelector(state => getUser(state));
    const [menuInfo, setMenuInfo] = useState([])
    const rightsPerIndex = useSelector(state => getHasRightsByIndex(state));

    // Menu item Icon
    const ShowIcon = (data) => {
        const Icon = data.icon;
        return (
            <ListItemIcon sx={{color: '#D2D8DD'}}>
                {Icon}
            </ListItemIcon>
        )
    }

    //Styling menu tooltip
    const MenuTooltip = ({...props}) => (
        <Tooltip {...props} componentsProps={{
            tooltip: {
                sx: {
                    color: '#D2D8DD',
                    backgroundColor: `${props.color}`,
                    marginLeft: '10px',
                },
            },
            arrow: {
                sx: {
                    color: `${props.color}`
                }
            },
        }}/>
    );

    MenuTooltip.propTypes = {
        color : PropTypes.string
    }

    // Check right per index should menu option be shown
    const checkRights = (index, action) => {
        const checkIndex = index?.toLowerCase();
        const rights = rightsPerIndex[checkIndex] || []
        return !!(rights && rights.includes(action)) || (typeof rightsPerIndex === "boolean" && rightsPerIndex);
    }

    useEffect(() => {
        if (!scopes || !user || !companyUuid) {
            return;
        }
        setMenuInfo(SideMenuItems(scopes, companyRoles, user, companyOverloadModule))
    }, [scopes, user, companyUuid])

    let menu;
    menu = menuInfo.map((value) => {
        //Set Variables
        let collapse = null;

        //Build Submenu if necessary
        if (value.submenu) {
            collapse =
                <Collapse className={value.admin ? classes.nestedContainerAdmin : classes.nestedContainer}
                          in={props.openCollapse[value.key]?.open || false} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            value.submenu.map((subValue) => {
                                if (!checkRights(subValue.index, 'read')) {
                                    return;
                                }
                                return (
                                    <MenuTooltip
                                        color={value.admin ? '#252525' : '#203A56'}
                                        placement="right"
                                        arrow={true}
                                        key={subValue.key}
                                        title={
                                            !props.openMainMenu ?
                                                subValue.key : ""
                                        }
                                    >
                                        <ListItemButton
                                            component={RouterLink}
                                            selected={props.selectedItem === subValue.key}
                                            to={subValue.url}
                                            key={subValue.key}
                                            className={classes.nested}
                                            onClick={() => props.itemClickHandler(subValue.key)}
                                            classes={{
                                                root: classes.root,
                                                button: classes.button,
                                                selected: classes.selected,
                                                hover: classes.hover
                                            }}>
                                            <ShowIcon icon={subValue.icon} toolTipTitle={subValue.key}/>
                                            <ListItemText
                                                disableTypography
                                                primary={<Typography type="body2"
                                                                     className={`${classes.nestedText}`}>{subValue.key}</Typography>}
                                            />
                                        </ListItemButton>
                                    </MenuTooltip>
                                );
                            })
                        }
                    </List>
                </Collapse>
        }

        if (!checkRights(value.index, 'read') && !value.submenu.length) {
            return;
        }

        //Build Menu items
        return (
            <Root key={value.key}>
                <MenuTooltip
                    color={value.admin ? '#252525' : '#203A56'}
                    placement="right"
                    arrow={true}
                    label={value.key}
                    title={
                        !props.openMainMenu ?
                            value.key : ""
                    }>
                    <ListItemButton
                        className={value.admin ? classes.menuItemAdmin : classes.menuItem}
                        component={RouterLink}
                        selected={props.selectedItem === value.key}
                        to={value.url}
                        key={value.key}
                        onClick={() => props.itemClickHandler(value.key, value.submenu)}
                        classes={{
                            root: classes.root,
                            button: classes.button,
                            selected: classes.selected
                        }}>
                        <ShowIcon icon={value.icon} toolTipTitle={value.key}/>
                        <ListItemText primary={value.key} sx={{color: '#D2D8DD'}}/>
                        {value.submenu ? props.openCollapse[value.key]?.open ? <ExpandLess/> : <ExpandMore/> : ""}
                    </ListItemButton>
                </MenuTooltip>
                {collapse}
            </Root>
        );
    });
    return menu;
}

export default SideMenu;