import React, {useState} from "react";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import SearchBar from "../../Controls/SearchBar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useDispatch, useSelector} from "react-redux";
import {setActiveCompany} from "../../../store/actions/auth";
import {clearAllTableSettings, resetDataTable} from "../../../store/actions/dataTable";
import PropTypes from "prop-types";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Business} from "@mui/icons-material";
import {getActiveCompany, getAllCompaniesAsArray} from "../../../store/selectors/companySelector";
import {getUser} from "../../../store/selectors/userSelector";
import {darken, lighten, ListItemButton} from "@mui/material";
import {styled} from "@mui/system";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {resetGPS} from "../../../store/actions/gps";
import i18n from "../../../i18n/i18n";

export default function HeaderAccountMenuCompanySelector(props) {
    const dispatch = useDispatch();
    const activeCompany = useSelector(state => getActiveCompany(state));
    const companies = useSelector(state => getAllCompaniesAsArray(state));
    const user = useSelector(state => getUser(state));
    const [filteredData, setFilteredData] = useState(companies);
    const [company, setCompany] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState('');

    //-------------------------
    // Select active company
    //------------------------
    const selectCompany = (company) => {
        dispatch(resetDataTable())
        dispatch(resetGPS())
        dispatch(clearAllTableSettings())
        dispatch(setActiveCompany(company));
        props.handleClose();
        setCompany(null)
    }

    //-------------------------
    // Filter Companies array
    //------------------------
    const handleCompanyFilter = (searchValue) => {
        const result = companies.filter((item) => {
            return item.name.toLowerCase().includes(searchValue.toLowerCase());
        });
        setSearchValue(searchValue.toLowerCase())
        setFilteredData(result);
    }

    //-------------------------
    // Clear filter
    //------------------------
    const clearFilter = () => {
        setFilteredData(companies);
    }

    //-------------------------
    // Company list
    //------------------------
    let companiesList = '';
    if (companies.length) {

        //List group
        const groupedData = filteredData?.reduce((groups, item) => {
            if (item.role === 'inactive') {
                return groups;
            }
            const firstLetter = item.name.charAt(0).toUpperCase();
            if (!groups[firstLetter]) {
                groups[firstLetter] = [];
            }
            groups[firstLetter].push(item);
            return groups;
        }, {});

        const GroupHeader = styled('li')(({theme}) => ({
            top: '-8px',
            padding: '8px 16px',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            backgroundColor:
                theme.palette.mode === 'light'
                    ? lighten(theme.palette.primary.light, 0.85)
                    : darken(theme.palette.primary.main, 0.8),
        }));

        // list primary text (company name)
        const primaryListText = (item, parts) => {
            return (
                parts.map((part, index) => (
                    <span
                        key={index}
                        style={{
                            fontWeight: part.highlight ? 'bold' : 400,
                        }}
                    >
                        {part.text}
                    </span>
                ))
            )
        }

        //List secondary text (account blocked / active company)
        const secondaryListText = (item) => {
            if (item.state_id === '90') {
                return <span style={{color: '#F50057'}}>Bedrijf is inactief</span>
            }
            if (!item.roles || !item?.roles.length) {
                return <span style={{color: '#F50057'}}>Account is geblokkeerd</span>
            }
            if (activeCompany !== undefined && item === activeCompany) {
                return <span style={{fontWeight: 'bold'}}>(Actief)</span>
            }

            return null
        }

        companiesList =
            <List sx={{minWidth: 450, maxWidth: 450}}>
                {user?.isAdmin &&
                    <ListItem sx={{marginTop: '-8px', backgroundColor: '#FAFAFA'}}>
                        {/* Add your SearchBar component here */}
                        <SearchBar darkMode={false} label={`Admin: ${i18n.t('TITLE_SEARCH_COMPANY')}`} handleChange={handleCompanyFilter}
                                   clearFilter={clearFilter}/>
                    </ListItem>
                }
                {groupedData &&
                    Object.entries(groupedData).map(([letter, items]) => (
                        <React.Fragment key={letter}>
                            <GroupHeader>
                                <span>{letter}</span>
                            </GroupHeader>
                            {items.map((item, i) => {
                                const matches = match(item.name, searchValue.toLowerCase(), {
                                    insideWords: true,
                                    requireMatchAll: true
                                });
                                const parts = parse(item.name, matches);

                                return (
                                    <ListItemButton key={i} onClick={() => selectCompany(item)}
                                                    disabled={(activeCompany !== undefined && item === activeCompany) || !item.roles}>
                                        <ListItemText primary={primaryListText(item, parts)} secondary={secondaryListText(item)}/>
                                    </ListItemButton>
                                )
                            })}
                        </React.Fragment>
                    ))
                }
            </List>
    }

    //-------------------------
    // Return popover company list
    //------------------------
    return (
        <>
            <ListItemButton
                key="Bedrijf selecteren"
                onClick={(event) => {
                    if (company !== null) {
                        setCompany(null)
                    } else {
                        setCompany(event.currentTarget)
                    }
                }}>
                <ListItemIcon> <Business/> </ListItemIcon>
                <ListItemText primary={i18n.t('BUTTON_CHANGE_COMPANY')}/>
            </ListItemButton>
            <Popover
                anchorEl={company}
                open={Boolean(company)}

                onClose={() => {
                    setCompany(null)
                    props.handleClose()
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    '.MuiPopover-paper': {
                        overflowX: 'hidden',
                        '&::-webkit-scrollbar': {width: 5, borderLeft: '2px solid #FFFFFF'},
                        '&::-webkit-scrollbar-track': {background: ' #EFEFEF', boxShadow: 'inset 0 0 5px grey'},
                        '&::-webkit-scrollbar-thumb': {background: '#192E44'}
                    }
                }}>
                {
                    companiesList
                }
            </Popover>
        </>
    )
}

HeaderAccountMenuCompanySelector.propTypes = {
    handleClose: PropTypes.func.isRequired,
};