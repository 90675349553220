import formModel from './formModel';

const {
    formField: {
        client_id,
        project_id,
        region_id,
        disposer_type,
        senderparty_name,
        senderpartyaddress_street_name,
        senderpartyaddress_house_no,
        senderpartyaddress_house_no_addition,
        senderpartyaddress_postal_code,
        senderpartyaddress_city_name,
        senderparty_identifier_value,
        invoiceaddress_description,
        invoiceaddress_street_name,
        invoiceaddress_house_no,
        invoiceaddress_house_no_addition,
        invoiceaddress_postal_code,
        invoiceaddress_city_name,
        disposerparty_name,
        disposerpartyaddress_street_name,
        disposerpartyaddress_house_no,
        disposerpartyaddress_house_no_addition,
        disposerpartyaddress_postal_code,
        disposerpartyaddress_city_name,
        locationorigin_description,
        locationoriginaddress_street_name,
        locationoriginaddress_house_no,
        locationoriginaddress_house_no_addition,
        locationoriginaddress_postal_code,
        locationoriginaddress_city_name,
        agentparty_name,
        agentpartyaddress_street_name,
        agentpartyaddress_house_no,
        agentpartyaddress_house_no_addition,
        agentpartyaddress_postal_code,
        agentpartyaddress_city_name,
        locationdestination_description,
        locationdestinationaddress_street_name,
        locationdestinationaddress_house_no,
        locationdestinationaddress_house_no_addition,
        locationdestinationaddress_postal_code,
        locationdestinationaddress_city_name,
        template_transported_by_type,
        carrierparty_name,
        carrierpartyaddress_street_name,
        carrierpartyaddress_house_no,
        carrierpartyaddress_house_no_addition,
        carrierpartyaddress_postal_code,
        carrierpartyaddress_city_name,
        carrierparty_identifier_value,
        template_is_route_collection,
        template_is_collection_scheme,
        template_is_repetitive_loads,
        template_waste_stream_id,
        gooditem_description,
        template_logo_image,
        gooditem_eural_code,
        gooditem_processing_method,
        gooditem_estimated_unit_value,
        template_project_description,
        template_work_description,
        order_type,
        gooditem_unit_type_id,
        unitType_description,
        template_weight_bridge_uuid,
        template_weight_bridge_name,
        template_show_color,
        template_reference,
        senderpartyaddress_mail,
        disposerpartyaddress_mail,
        locationdestinationaddress_mail,
        remarks,
        remarks_driver,
        remark,
        type,
        document_number,
        organisation_name,
        form_type,
        share_template
    }
} = formModel

export default {

    //================================
    //Blok 1
    //================================
    senderparty: {
        [senderparty_name.name]: '',
        [senderparty_identifier_value.name]: '',
        [senderpartyaddress_street_name.name]: '',
        [senderpartyaddress_house_no.name]: '',
        [senderpartyaddress_house_no_addition.name]: '',
        [senderpartyaddress_postal_code.name]: '',
        [senderpartyaddress_city_name.name]: '',
        [senderpartyaddress_mail.name]: '',
    },
    //================================
    //Blok 2
    //================================
    invoiceaddress: {
        [invoiceaddress_description.name]: '',
        [invoiceaddress_street_name.name]: '',
        [invoiceaddress_house_no.name]: '',
        [invoiceaddress_house_no_addition.name]: '',
        [invoiceaddress_postal_code.name]: '',
        [invoiceaddress_city_name.name]: '',
    },
    //================================
    //Blok 3a
    //================================
    disposerparty: {
        [disposerparty_name.name]: '',
        [disposerpartyaddress_street_name.name]: '',
        [disposerpartyaddress_house_no.name]: '',
        [disposerpartyaddress_house_no_addition.name]: '',
        [disposerpartyaddress_postal_code.name]: '',
        [disposerpartyaddress_city_name.name]: '',
        [disposerpartyaddress_mail.name]: '',
    },
    //================================
    //Blok 3e
    //================================
    locationorigin: {
        [locationorigin_description.name]: '',
        [locationoriginaddress_street_name.name]: '',
        [locationoriginaddress_house_no.name]: '',
        [locationoriginaddress_house_no_addition.name]: '',
        [locationoriginaddress_postal_code.name]: '',
        [locationoriginaddress_city_name.name]: '',
    },
    //================================
    //Blok 4a
    //================================
    agentparty: {
        [agentparty_name.name]: '',
        [agentpartyaddress_street_name.name]: '',
        [agentpartyaddress_house_no.name]: '',
        [agentpartyaddress_house_no_addition.name]: '',
        [agentpartyaddress_postal_code.name]: '',
        [agentpartyaddress_city_name.name]: '',
    },
    //================================
    //Blok 4e
    //================================
    locationdestination: {
        [locationdestination_description.name]: '',
        [locationdestinationaddress_street_name.name]: '',
        [locationdestinationaddress_house_no.name]: '',
        [locationdestinationaddress_house_no_addition.name]: '',
        [locationdestinationaddress_postal_code.name]: '',
        [locationdestinationaddress_city_name.name]: '',
        [locationdestinationaddress_mail.name]: '',
    },
    //================================
    //Blok 5
    //================================
    carrierparty: {
        [carrierparty_name.name]: '',
        [carrierpartyaddress_street_name.name]: '',
        [carrierpartyaddress_house_no.name]: '',
        [carrierpartyaddress_house_no_addition.name]: '',
        [carrierpartyaddress_postal_code.name]: '',
        [carrierpartyaddress_city_name.name]: '',
        [carrierparty_identifier_value.name]: '',
    },
    //================================
    //Blok 6
    //================================
    gooditem: {
        [gooditem_description.name]: '',
        [gooditem_eural_code.name]: '',
        [gooditem_processing_method.name]: '',
        [gooditem_estimated_unit_value.name]: '',
    },
    unitType: {
        [unitType_description.name]: '',
    },

    //================================
    //Blok report number
    //================================
    reportStatement: {
        [remark.name]: '',
        [type.name]: '',
        [document_number.name]: '',
        [organisation_name.name]: '',
    },
    [client_id.name]: '',
    [project_id.name]: '',
    [disposer_type.name]: 4,
    [template_waste_stream_id.name]: '',
    [template_transported_by_type.name]: 5,
    [template_is_route_collection.name]: 0,
    [template_is_collection_scheme.name]: 0,
    [template_is_repetitive_loads.name]: 0,
    [template_project_description.name]: '',
    [template_work_description.name]: '',
    [template_logo_image.name]: '',
    [order_type.name]: '',
    [gooditem_unit_type_id.name]: '',
    [template_weight_bridge_uuid.name]: '',
    [template_weight_bridge_name.name]: null,
    [template_show_color.name]: '',
    [template_reference.name]: '',
    [remarks.name]: '',
    [remarks_driver.name]: '',
    [form_type.name] : 'C1',
    [share_template.name] : []
};