import * as actionTypes from './actionTypes';
import axios from "../../api/axios-peer";

export const fetchDataStart = (index) => {
    return {
        type: actionTypes.FETCH_DATA_TABLE_START,
        index: index
    }
}

export const fetchDataFail = (error, index) => {
    return {
        type: actionTypes.FETCH_DATA_TABLE_FAIL,
        error: error,
        index: index
    }
}

export const setTableInitialized = (index) => {
    return {
        type: actionTypes.SET_TABLE_INITIALIZED,
        index: index
    }
}

export const fetchDataRequest = (url, index, numberOfRow, page, sortOrder = null, urlAddition = null, searchParams = null, activeTabFilter = null, resource = null, callback = null, routeFilter = null) => ({
    type: actionTypes.FETCH_DATA_REQUEST_SAGA,
    url,
    index,
    numberOfRow,
    page,
    sortOrder,
    urlAddition,
    searchParams,
    activeTabFilter,
    resource,
    callback,
    routeFilter
});

export const fetchTabs = (url, index, resource = null, callback = null) => {
    return dispatch => {
        dispatch(fetchDataStart(index));

        url += url.endsWith('/') ? "tabs" : "/tabs"

        const rest = resource ?? axios
        rest.get(url, {
            responseType: 'json',
        }).then(response => {
            if (callback) {
                callback(response.data)
            }
            dispatch(fetchDataSuccess(index, response.data));
        }).catch(error => {
            dispatch(fetchDataFail(error.message, index))
        })
    };
}
export const fetchData = (url, index, numberOfRow, page, sortOrder = null, urlAddition = null, searchParams = null, activeTabFilter = null, resource = null, callback = null, routeFilter) => {

    return dispatch => {
        dispatch(fetchDataStart(index));

        const rest = resource ?? axios

        let questionmarked = url.includes("?");

        if (numberOfRow !== null) {
            if (questionmarked === false) {
                questionmarked = true;
                url += "?";
            } else {
                url += "&";
            }
            url += "per-page=" + numberOfRow;
        }

        if (page !== null) {
            if (questionmarked === false) {
                questionmarked = true;
                url += "?";
            } else {
                url += "&";
            }
            url += "page=" + (page + 1);
        }

        if (urlAddition != null) {
            if (questionmarked === false) {
                questionmarked = true;
                url += "?";
            } else {
                url += "&";
            }
            url += urlAddition;
        }

        if (searchParams != null) {
            if (questionmarked === false) {
                questionmarked = true;
                url += "?";
            } else {
                url += "&";
            }
            url += searchParams;
        }

        if (routeFilter != null) {
            if (questionmarked === false) {
                questionmarked = true;
                url += "?";
            } else {
                url += "&";
            }
            url += routeFilter;
        }


        if (activeTabFilter) {
            if (questionmarked === false) {
                questionmarked = true;
                url += "?";
            } else {
                url += "&";
            }

            url += activeTabFilter;
        }

        if (sortOrder != null) {
            if (questionmarked === false) {
                url += "?";
            } else {
                url += "&";
            }
            url += "sort=" + sortOrder;
        }

        rest.get(url, {
            responseType: 'json',
        }).then(response => {
            if (callback) {
                callback(response.data)
            }
            dispatch(fetchDataSuccess(index, response.data));
        }).catch(error => {
            dispatch(fetchDataFail(error.message, index))
            dispatch(setFormAlert(true, 'error', error.message))
        })
    };
}

export const fetchTabsRequest = (url, index, resource = null, callback = null) => ({
    type: actionTypes.FETCH_TABS_REQUEST_SAGA,
    url,
    index,
    resource,
    callback
});


export const saveDataStart = () => {
    return {
        type: actionTypes.SAVE_DATA_START
    }
}

export const saveDataSucces = (data, index, dataId) => {
    return {
        type: actionTypes.SAVE_DATA_SUCCESS,
        data: data,
        index: index,
        dataId: dataId,
        filters: data.filters
    }
}

export const toggleLoading = (state) => {
    return {
        type: actionTypes.TOGGLE_LOADING,
        state: state,
    }
}


export const addDataSucces = (data, index) => {
    return {
        type: actionTypes.ADD_DATA_SUCCESS,
        data: data,
        index: index,
        filters: data.filters,
    }
}

export const deleteDataSuccess = (dataId, index) => {
    return {
        type: actionTypes.DELETE_DATA_SUCCESS,
        dataId: dataId,
        index: index
    }
}

export const saveDataFail = (error) => {
    return {
        type: actionTypes.SAVE_DATA_FAIL,
        error: error
    }
}

export const updateData = (url, data, index, dataId, callback = null, catchError = null, baseUrl) => {
    return dispatch => {
        dispatch(saveDataStart());
        axios.put(url, data, baseUrl)
            .then(response => {
                let data = response.data;

                dispatch(saveDataSucces(data, index, dataId))

                if (callback) {
                    callback(data)
                }
                // dispatch(closeDataRecord())
            })
            .catch((error) => {
                if (catchError && error.response) {
                    if (error.response.data.validationErrors !== undefined) {
                        catchError(error.response.data.validationErrors)
                    } else {
                        catchError(error.response.data);
                    }
                }

                dispatch(setFormAlert(true, 'error', error.response?.statusText || error.response))
            })
    };
}

export const patchData = (url, data, index, dataId, callback = null, catchError = null, baseUrl) => {
    return dispatch => {
        dispatch(saveDataStart());
        axios.patch(url, data, baseUrl)
            .then(response => {
                let data = response.data;

                dispatch(saveDataSucces(data, index, dataId))

                if (callback) {
                    callback(data)
                }
                // dispatch(closeDataRecord())
            })
            .catch((error) => {
                if (catchError && error.response) {
                    if (error.response.data.validationErrors !== undefined) {
                        catchError(error.response.data.validationErrors)
                    } else {
                        catchError(error.response.data);
                    }
                }

                dispatch(setFormAlert(true, 'error', error.response?.statusText || error.response))
            })
    };
}


export const postData = (url, data, index, keepOpen = false, callback = null, catchError = null, skipSaveData = false) => {
    return dispatch => {

        if (!skipSaveData) {
            dispatch(saveDataStart());
        }
        axios.post(url, data)
            .then(response => {
                let data = response.data;
                if (!Array.isArray(data)) {
                    data = [response.data];
                }
                if (!skipSaveData) {
                    dispatch(addDataSucces(data, index))
                }

                if (callback) {
                    callback(data[0])
                    catchError(null)
                }

                if (!keepOpen) {
                    dispatch(setAddFormOpen(false))
                }
            })
            .catch((error) => {
                if (catchError && error.response) {
                    if (error.response.data.validationErrors !== undefined) {
                        catchError(error.response.data.validationErrors)
                        dispatch(setFormAlert(true, 'error', catchError))
                    } else {
                        catchError(error.response.data);
                        if (error.response.data.length > 0) {
                            error.response.data.map((error) => {
                                if (error.field !== undefined) {
                                    dispatch(setFormAlert(true, 'error', error.message))
                                }
                            });
                        } else {
                            catchError = error.response.data.message;
                            dispatch(setFormAlert(true, 'error', catchError))
                        }
                    }
                }
            })
    };
}

export const deleteData = (url, index, dataId) => {
    return dispatch => {
        axios.delete(url)
            .then(() => {
                dispatch(deleteDataSuccess(dataId, index))
            })
            .catch((error) => {
                dispatch(fetchDataFail(error.message, index))
            })
    };
}

export const fetchDataSuccess = (index, data) => {
    return {
        type: actionTypes.FETCH_DATA_TABLE_SUCCESS,
        index: index,
        object: {
            items: data.items,
            meta: data._meta,
            links: data._links,
            columns: data.columns,
            filters: data.filters,
            tabs: data.tabs,
            open: false,
            tabsInitialized: true,
            defaultTabIndex: data.defaultTabIndex,
        },
    }
}

export const openDialogData = (state, index, disabled = true, openRecord, action) => {
    return {
        type: actionTypes.OPEN_DIALOG_TABLE,
        open: state,
        index: index,
        disabled: disabled,
        openRecord: openRecord,
        action: action,
    };
}

export const closeDialogData = (state, index, disabled = true) => {
    return {
        type: actionTypes.CLOSE_DIALOG_TABLE,
        open: false,
        index: index,
        disabled: disabled,
        openRecord: null
    };
}

// export const openDataRecord = (dataIndex, index, action = null) => {
export const openDataRecord = (dataIndex, index, action = null) => {
    return {
        type: actionTypes.SELECT_DATA_TABLE_ROW,
        openRecord: dataIndex,
        index: index,
        action: action
    };
}

// export const closeDataRow = (index) => {
export const closeDataRecord = (index) => {
    return {
        type: actionTypes.CLOSE_DATA_TABLE_ROW,
        index: index,
        openRecord: null,
        action: null
    };
}

export const setSelectedTableRows = (dataIndex, index) => {
    return {
        type: actionTypes.SELECT_TABLE_ROWS,
        selectedRows: dataIndex,
        index: index,
    };
}

export const setActionHandle = (handle) => {
    return {
        type: actionTypes.SET_ACTION_HANDLE,
        handle: handle,
    };
}

export const setDisabled = (state) => {
    return {
        type: actionTypes.SET_DISABLED,
        isDisabled: state,
    };
}
export const clearActionHandle = () => {
    return {
        type: actionTypes.SET_ACTION_HANDLE,
        handle: null,
    };
}
export const setFormNotEdited = () => {
    return {
        type: actionTypes.FORM_EDITED,
        isEdited: false,
    };
}

export const setFormEdited = () => {
    return {
        type: actionTypes.FORM_EDITED,
        isEdited: true,
    };
}

export const setFormError = (error) => {
    return {
        type: actionTypes.SET_ERROR,
        error: error,
    };
}
export const setFormAlert = (state, variant, message, persist, duration) => {
    return {
        type: actionTypes.SET_ALERT,
        state: state,
        variant: variant,
        message: message,
        persist: persist,
        duration: duration,
    };
}

export const setAddFormOpen = (isOpen) => {
    return {
        type: actionTypes.SET_ADD_FORM_OPEN,
        isOpen: isOpen
    };
}

export const setCurrentTab = (index, newTab, currentTab = null) => {

    if (newTab === currentTab) {
        return;
    }
    return {
        type: actionTypes.SET_TAB_REQUEST_SAGA,
        index: index,
        tab: newTab,
        currentTab: currentTab
    };
}

export const resetDataTable = () => {
    return {
        type: actionTypes.RESET_DATA_TABLE
    }
}

export const updateDataTableRecord = (index, dataId, data) => {
    return {
        type: actionTypes.UPDATE_DATA_TABLE_RECORD,
        index: index,
        dataId: dataId,
        data: data,
    }
}
export const insertDataTableRecord = (index, dataId, data) => {
    return {
        type: actionTypes.INSERT_DATA_TABLE_RECORD,
        index: index,
        dataId: dataId,
        data: data,
    }
}
export const removeDataTableRecord = (index, rowIndex) => {
    return {
        type: actionTypes.REMOVE_DATA_TABLE_RECORD,
        index: index,
        rowIndex: rowIndex,
    }
}

export const clearDataTableIndex = (index) => {
    return {
        type: actionTypes.CLEAR_DATA_TABLE_INDEX,
        index: index,
    }
}

export const setDataTableAction = (index, action) => {
    return {
        type: actionTypes.SET_DATA_TABLE_ACTION,
        index: index,
        action: action
    }
}

export const setExpandedTableRows = (index, toggled) => {
    return {
        type: actionTypes.EXPAND_TABLE_ROWS,
        index: index,
        toggled: toggled
    }
}

export const updateDataTableSingleValue = (index, rowIndex, columnName, newValue) => {
    return {
        type: actionTypes.UPDATE_DATA_TABLE_VALUE,
        index: index,
        rowIndex: rowIndex,
        columnName: columnName,
        newValue: newValue,
    }
}

export const setDataTableMode = (index, state, rowIndex) => {
    return {
        type: actionTypes.SET_DATA_TABLE_MODE,
        index: index,
        state: state,
        rowIndex: rowIndex,
    }
}

export const exportDataTable = (index, exportTable, companyUUID) => {
    return {
        type: actionTypes.EXPORT_DATA_TABLE,
        index: index,
        exportTable: exportTable,
        company: companyUUID
    };
}

export const clearTableSettings = () => {
    return {
        type: actionTypes.CLEAR_DATA_TABLE_SETTINGS,
    };
}

export const clearAllTableSettings = () => {
    return {
        type: actionTypes.CLEAR_ALL_DATA_TABLE_SETTINGS,
    };
}

export const updateReduxFilters = (index, field, value) => {
    return {
        type: actionTypes.UPDATE_REDUX_FILTERS,
        index: index,
        field: field,
        value: value
    };
}