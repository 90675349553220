import React, {useEffect} from "react";
import Loading from "../../../components/Animation/Loading";
import customClasses from "../Auth.module.css";
import customClasses2 from '../../../App.module.css'
import {activateAccount, checkActivationKey} from "../../../store/actions/register";
import {useDispatch, useSelector} from "react-redux";
import Logo from "../../../components/Logo/Logo";
import {Alert, Paper} from "@mui/material";
import {Navigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import i18n from "../../../i18n/i18n";
import {isLoggedIn} from "../../../store/selectors/userSelector";

export default function ActivateAccount() {
    const loading = useSelector(state => {
        return state.reg.loading ?? null
    });
    const error = useSelector(state => {
        return state.reg.activateError ?? null
    })
    const data = useSelector(state => {
        return state.reg.activationKeyFound
    })
    const activated = useSelector(state => {
        return state.reg.activated
    })
    const loggedIn = useSelector(state => isLoggedIn(state));
    const {activation_key} = useParams();
    const dispatch = useDispatch();

    const activateClick = () => {
        dispatch(activateAccount(data, activation_key, loggedIn))
    }

    useEffect(() => {
        dispatch(checkActivationKey(activation_key));
    }, [activation_key])

    if (activated) {
        return <Navigate to={"/"}/>
    }

    return (<React.Fragment>
        <Paper className={customClasses2.Login}
               sx={{position: 'absolute', top: loggedIn ? '50px' : 0, bottom: 0, right: 0, left: 0}}>
            <div className={customClasses.AuthData}>
                <Logo size={60}/>
                {loading ? <Loading size={300}/> : ''}

                {error ? <Alert severity="error">{error}</Alert> : ''}

                {data ?
                    <div>
                        <br/>
                        <Typography gutterBottom variant="h5">{i18n.t('WELCOME')} {data.first_name}, </Typography>
                        <Typography gutterBottom
                                    variant="body1">{i18n.t('ACTIVATE_ACCOUNT_INFO_1')}<br/> {i18n.t('ACTIVATE_ACCOUNT_INFO_2')}
                        </Typography>
                        <br/>
                        <Button variant="contained" color="primary" onClick={() => activateClick()}>
                            {i18n.t('BUTTON_ACTIVATE_NOW')}
                        </Button>
                    </div> : ""
                }
            </div>
        </Paper>
    </React.Fragment>)
}