import React from "react";
import {Grid,TextField, Typography} from "@mui/material";
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";

export default function RegistrationTabAddress(props) {

    return (
        <div>
            <Typography align='left' variant="h6">{i18n.t('TITLE_WHAT_IS_ADDRESS')}</Typography>
                <Grid container alignItems="flex-end" justifyContent="flex-end">
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="tabAddress-inputAddress"
                            label={i18n.t('ADDRESS')}
                            type="text"
                            fullWidth
                            autoFocus
                            variant="outlined"
                            margin="normal"
                            required
                            error={Boolean(props.address.error.length)}
                            value={props.address.value}
                            onChange={props.inputChangeAddress}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            id="tabAddress-inputHouseNumber"
                            label={i18n.t('HOUSE_NUMBER')}
                            type="number"
                            name="street_number"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            error={Boolean(props.houseNumber.error.length)}
                            value={props.houseNumber.value}
                            onChange={props.inputChangeHouseNumber}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            id="tabAddress-inputHouseNumberAddition"
                            label={i18n.t('HOUSE_NUMBER_ADDITION')}
                            type="text"
                            name="street_number_addition"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            value={props.houseNumberAddition.value}
                            onChange={props.inputChangeHouseNumberAddition}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="tabAddress-inputZipCode"
                            label={i18n.t('ZIPCODE')}
                            type="text"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            error={Boolean(props.zipcode.error.length)}
                            value={props.zipcode.value}
                            onChange={props.inputChangeZipCode}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="tabAddress-inputPlace"
                            label={i18n.t('CITY')}
                            type="text"
                            name="city"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            error={Boolean(props.place.error.length)}
                            value={props.place.value}
                            onChange={props.inputChangePlace}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="tabAddress-inputCountry"
                            label={i18n.t('COUNTRY')}
                            type="text"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            required
                            error={Boolean(props.country.error.length)}
                            value={props.country.value}
                            onChange={props.inputChangeCountry}/>
                            <br/>
                    </Grid>
                </Grid>
        </div>
    )
}

RegistrationTabAddress.propTypes = {
    address: PropTypes.object.isRequired,
    houseNumber: PropTypes.object.isRequired,
    houseNumberAddition: PropTypes.object.isRequired,
    zipcode: PropTypes.object.isRequired,
    place: PropTypes.object.isRequired,
    country: PropTypes.object.isRequired,
    inputChangeAddress: PropTypes.func.isRequired,
    inputChangeHouseNumber: PropTypes.func.isRequired,
    inputChangeHouseNumberAddition: PropTypes.func.isRequired,
    inputChangeZipCode: PropTypes.func.isRequired,
    inputChangePlace: PropTypes.func.isRequired,
    inputChangeCountry: PropTypes.func.isRequired,
}