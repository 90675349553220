
import React from "react";
import {Button, Grid, TextField, Typography} from "@mui/material";
import customClasses from '../Auth.module.css';
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";

export default function ForgotPasswordForm(props) {

    return(
            <Grid className={customClasses.contentSpacing} container alignItems="flex-end" justifyContent="flex-end">
                <Grid item xs={12}>
                    <Typography align='left' variant="h6">{i18n.t('BUTTON_FORGOT_PASSWORD')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="email"
                        label={i18n.t('EMAIL_ADDRESS')}
                        type="email"
                        margin="normal"
                        value={props.value}
                        onChange={props.onChange}
                        error={Boolean(props.value.length)}
                        fullWidth
                        autoFocus
                        required />
                    <br/>
                </Grid>
            <Grid item xs={12}>
                <br/>
                <br/>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    style={{ textTransform: "none" }}>{i18n.t('BUTTON_ASK_NEW_PASSWORD')}
                </Button>
            </Grid>
            </Grid>
    )
}

ForgotPasswordForm.propTypes = {
    value : PropTypes.string,
    onChange : PropTypes.func.isRequired,
}