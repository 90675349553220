import Grid from "@mui/material/Grid";
import React from "react";
import customClasses from "./TemplateForm.module.css";
import PropTypes from "prop-types";
import {FormTextField} from "./Components/FormTextField";
import {FastField} from "formik";
import WeegbrugDropdown from "../Templates/Onboarding/Dropdowns/WeegbrugDropdown";

export default function TemplateFormWeightbridge(props) {
    const {
        formField: {
            template_weight_bridge_uuid,
            template_reference,
        }
    } = props;
    return <React.Fragment>
        <Grid container item xs={12}>
            <Grid item xs={11} md={3}>
                {template_weight_bridge_uuid.label}
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={12} md={8}>
                <WeegbrugDropdown handleBlur={props.handleBlur} initialValues={props.initialValues}
                                  values={props.values} viewValueOnly={true} savedData={props.savedData}
                                  hideLabel={true}
                                  disabled={true} variant="standard" setFieldValue={props.setFieldValue} action={props.action}/>
            </Grid>
        </Grid>
        <Grid container item xs={12}>
            <Grid item xs={11} md={3}>
                {template_reference.label}
            </Grid>
            <Grid item xs={1}> </Grid>
            <Grid item xs={12} md={8}>
                <FastField
                    autoComplete="new-password"
                    sizeClass={customClasses.inputFull}
                    name={template_reference.name}
                    disabled={props.disabled}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <br/>
        <br/>
        <br/>
    </React.Fragment>
}

TemplateFormWeightbridge.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    action: PropTypes.string,
    initialValues: PropTypes.object,
    values: PropTypes.object,
    savedData: PropTypes.object,
    data: PropTypes.object,
    setFieldValue: PropTypes.func,
    handleBlur: PropTypes.func,
};