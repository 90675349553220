import * as actionTypes from './actionTypes';
import resource from "../../api/axios-peer";
import resourceAuth from "../../api/axios-auth";
import {authCheckState} from "./auth";
import {redirect} from "react-router-dom";

export const RegisterUserSubmit = (data) => {
    return dispatch => {
        dispatch(RegisterStartLoading());
        dispatch(RegisterUserAccount(data));
    };
}
export const HandleCloseIcon = (errorState) => {
    return {
        type: actionTypes.REGISTER_USER_FAIL_ALERT,
        data: errorState,
    };
}

export const RegisterUserAccount = (formData) => {
    return dispatch => {
        resourceAuth.post('user', {
            first_name: formData.user.inputFirstName.value,
            tussenvoegsel: formData.user.inputInsertion.value,
            last_name: formData.user.inputLastName.value,
            email: formData.account.inputEmail.value,
            password: formData.account.inputPassword.value,
            phone_number: formData.user.inputPhone.value
        }).then((response) => {
            dispatch(RegisterUserData(response.data, formData));
        }).catch((error) => {
            dispatch(RegisterUserFail(error.response.data))
        })
    };
}

export const RegisterUserData = (boaData, formData) => {
    return dispatch => {
        resource.post('/register/user', {...{boa: {...boaData}}, ...formData}).then(response => {
            dispatch(RegisterUserSuccess(response.data));
        }).catch((error) => {
            dispatch(RegisterUserFail(error.response.data))
        })
    };
}

export const checkActivationKey = (activation_key) => {
    return dispatch => {
        dispatch(RegisterStartLoading());
        resource.get('/register/activate/check/' + activation_key).then(response => {
            dispatch(CheckActivationKeySuccess(response.data));
        }).catch((error) => {
            dispatch(SetActivateError(error.response?.data?.message));
        })
    }
}

export const activateAccount = (user, activation_key, loggedIn) => {
    return dispatch => {
        dispatch(RegisterStartLoading());
        resource.put('/register/activate/' + user.id + '?activation_key=' + activation_key).then(response => {
            dispatch(ActivateSuccess(response.data));

            if (loggedIn) {
                dispatch(authCheckState())
                redirect("/Projecten")
            }
        }).catch((error) => {
            dispatch(SetActivateError(error.response?.data?.message));
        })
    }
}

export const RegisterStartLoading = () => {
    return {
        type: actionTypes.REGISTER_START_LOADING
    }
}

export const RegisterUserFail = (error) => {
    return {
        type: actionTypes.REGISTER_USER_FAIL,
        error: error.message
    }
}

export const RegisterUserSuccess = (data) => {
    return {
        type: actionTypes.REGISTER_USER_SUCCESS,
        data: data
    }
}

export const CheckActivationKeySuccess = (data) => {
    return {
        type: actionTypes.REGISTER_CHECK_ACTIVATION_KEY_SUCCESS,
        data: data
    }
}

export const SetActivateError = (error) => {
    return {
        type: actionTypes.SET_ACTIVATE_ERROR,
        error: error
    }
}

export const ActivateSuccess = (data) => {
    return {
        type: actionTypes.ACTIVATE_SUCCESS,
        data: data
    }
}