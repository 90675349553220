
import React, {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import PropTypes from "prop-types";

const Loading = (props) => {

    //Default Color and Size
    const [color, setColor] =  useState('#EF6C00');

    useEffect(() => {
        if (props.color !== undefined) {setColor(props.color)}
    },[props.color])

    let margin = props.margin;

    if(margin == null) {
        margin = '20px';
    }

    return(
            <CircularProgress size={props.size || 40} thickness={props.thickness || 3.6} style={{ color: color, margin: margin }}/>
    )
}
export default Loading;

Loading.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    margin: PropTypes.string,
    thickness: PropTypes.number
};