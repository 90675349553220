import {createSelector} from "reselect";

/**
 *  Get All scopes data
 *
 *  @returns {object}
 */
export const getScopes = (state) => {
    if (!state) {
        return;
    }
    return state.auth?.scopes || {};
}

/**
 *  Get main data per scope in array
 *
 *  @returns {array}
 */
export const getScopesArray = createSelector(
    [
        getScopes,
    ],
    (scopes) => {
        return Object.keys(scopes).map(key => ({
            scope: scopes[key]?.scope,
            isActive: scopes[key]?.isActive,
            introspect: scopes[key]?.introspect,
            initialized: scopes[key]?.initialized,
            isVerified: scopes[key]?.isVerified
        })) || [];
    }
);

/**
 *  Get scope info by passed variable
 *  @param {string} scope - An string (e.g., "PEER", "FORMKLUB", "GPS")
 *
 *  @returns {object}
 */
export const getScopesByScope = createSelector(
    [
        getScopes,
        (state, scope) => scope
    ],
    (scopes, scope) => {
        return scopes[scope];
    }
);


/**
 * ===========================================
 *  PEER
 *  ==========================================
 */

/**
 *  Get scope info PEER
 *
 *  @returns {object}
 */
export const getScopePeer = createSelector(
    [getScopes],
    (scopes) => {
        if (!scopes) {
            return;
        }
        return scopes?.PEER || {};
    }
);

/**
 *  Get info PEER Data user
 *
 *  @returns {object}
 */
export const getScopePeerDataUser = createSelector(
    [getScopePeer],
    (peer) => {

        return peer?.data || {};
    }
);

/**
 *  Get PEER Auth
 *
 *  @returns {array}
 */
export const GetScopePeerAuth = createSelector(
        [getScopePeer],
        (peer) => {
            return peer?.auth || null;
        }
    );

/**
 *  Get All companies
 *
 *  @returns {object}
 */
export const getScopePeerCompanies = createSelector(
    [getScopePeer],
    (peer) => {
        return peer?.companies || {};
    }
);

/**
 *  Count all companies
 *
 *  @returns {number}
 */
export const getScopePeerCompaniesAmount = createSelector(
    [getScopePeerCompanies],
    (companiesObject) => {
        const companies = Object.keys(companiesObject) || []
        return companies.length || 0;
    }
);

/**
 *  Check if scope PEER is active
 *
 *  @returns {boolean}
 */
export const getScopePeerState = createSelector(
    [getScopePeer],
    (peer) => {
        return peer?.isActive || false;
    }
)

/**
 *  Check if scope PEER is initialized
 *
 *  @returns {boolean}
 */
export const getScopePeerInitialized = createSelector(
    [getScopePeer],
    (peer) => {
        return peer?.initialized || false;
    }
);

/**
 * ===========================================
 *  FORMKLUB
 *  ==========================================
 */

/**
 *  Get scope info FORMKLUB
 *
 *  @returns {object}
 */
export const getScopeFormklub = createSelector(
    [getScopes],
    (scopes) => {
        if (!scopes) {
            return;
        }
        return scopes?.FORMKLUB || {};
    }
);

/**
 *  Get info FORMKLUB Data user
 *
 *  @returns {object}
 */
export const getScopeFormklubDataUser = createSelector(
    [getScopeFormklub],
    (formklub) => {

        return formklub?.data || {};
    }
);

/**
 *  Check if scope FORMKLUB is initialized
 *
 *  @returns {boolean}
 */
export const getScopeFormklubInitialized = createSelector(
    [getScopeFormklub],
    (formklub) => {
        return formklub?.initialized || false;
    }
);

/**
 *  Check if scope FORMKLUB is active
 *
 *  @returns {boolean}
 */
export const getScopeFormklubIsActive = createSelector(
    [getScopeFormklub],
    (formklub) => {
        return formklub?.isActive || false;
    }
);
