import React, {useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import i18n from "../../i18n/i18n";

const PREFIX = 'ColorPicker';

const classes = {
    button: `${PREFIX}-button`,
    activeButton: `${PREFIX}-activeButton`,
    labelBackground: `${PREFIX}-labelBackground`,
    label: `${PREFIX}-label`,
    fieldDescription: `${PREFIX}-fieldDescription`
};

const StyledGrid = styled(Grid)(() => ({
    [`& .${classes.button}`]: {
        width: '66px',
        border: 'none',
        margin: '4px',
        cursor: 'pointer',
        textTransform: "capitalize",
        lineHeight: '1.3',
        fontSize: '12px',
        "&:hover": {
            opacity: '0.6',
        },
    },

    [`& .${classes.activeButton}`]: {
        width: '66px',
        border: 'none',
        margin: '4px',
        textTransform: "capitalize",
        cursor: 'unset',
        lineHeight: '1.3',
        fontSize: '12px',
    },

    [`& .${classes.labelBackground}`]: {
        background: '#FFFFFF',
        opacity: '0.4',
        width: '100%',
        height: '20px',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        webkitBorderBottomRightRadius: "4px",
        webkitBorderBottomLeftRadius: "4px",
        mozBorderRadiusBottomright: "4px",
        mozBorderRadiusBottomleft: "4px",
        borderBottomRightRadius: "4px",
        borderBottomLeftRadius: "4px",
    },

    [`& .${classes.label}`]: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: "3px",
        zIndex: 2,
    },

    [`& .${classes.fieldDescription}`]: {
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '0 0 14px 10px'
    }
}));

const ColorPicker = (props) => {


    //Default props
    const [selectedColorHex, setSelectedColorHex] = useState('');

    const colorArray = [['#FF0000', 'Rood'], ['#FFA500', 'Oranje'], ['#FFFF00', 'Geel'], ['#008000', 'Groen'], ['#000080', 'Blauw'], ['#00FFFF', 'Licht blauw'], ['#EE82EE', 'Roze'], ['#800080', 'Paars'], ['#FFFFFF', 'Wit'], ['#808080', 'Grijs']];

//Set values
    useEffect(() => {

        if (props.values) {
            setSelectedColorHex(props.values[props.id])
        } else if (props.initialValues) {
            setSelectedColorHex(props.initialValues[props.id])
        } else {
            setSelectedColorHex(props.values)
        }
    }, [])

//Handle actions
    const HandleColorPick = (color, colorHex) => {
        if (colorHex === selectedColorHex) {
            colorHex = null;
        }

        if(colorHex){
            setSelectedColorHex(colorHex)
        }else{
            setSelectedColorHex("")
        }
  
        props.setFieldValue(props.id, colorHex);
    }


// Show colors picker popup
    const Displaycolors = colorArray.map((item, i) => {
        let colorStyle;
        let className = classes.button;

        if (item[0] === selectedColorHex) {
            colorStyle = {
                height: '50px',
                color: "#191919",
                borderWidth: `1px`,
                borderStyle: `solid`,
                borderColor: 'rgba(0, 0, 0, 0.23)',
                background: `${selectedColorHex}`
            };
            className = classes.activeButton;
        } else {
            colorStyle = {
                height: '50px',
                background: `${item[0]}`,
                borderWidth: `1px`,
                borderStyle: `solid`,
                borderColor: 'rgba(0, 0, 0, 0.23)',
                color: "#191919",
                opacity: `${selectedColorHex ? 0.5 : 1}`
            };
        }

        let buttonColorPicker
        if (!props.disabled) {
            buttonColorPicker = <StyledGrid item key={i} id={`color-${item[0]}`}>
                <Button disabled={props.disabled} onClick={() => HandleColorPick(item[1], item[0])} id={`${item[1]}`}
                        style={colorStyle} className={className}>
                    <div className={classes.label}>{item[1]}</div>
                    <div className={classes.labelBackground}></div>
                </Button>
            </StyledGrid>
        } else {
            const color = props.savedData?.show_color || '';
            if (item[0].toUpperCase() === color.toUpperCase()) {
                buttonColorPicker = <Grid item key={i} id={`color-${item[0]}`}>
                    <Button disabled={props.disabled} style={colorStyle} className={className}>
                        <div className={classes.label}>{item[1]}</div>
                        <div className={classes.labelBackground}></div>
                    </Button>
                </Grid>
            }
        }
        return buttonColorPicker;
    });

    let label = '';
    if (props.showLabel) {
        label = <Grid item xs={12}>
            <Typography variant="body1" display="block" className={classes.fieldDescription}>
                {props.disabled ? i18n.t('CHOOSEN_COLOR') : i18n.t('CHOOSE_COLOR') + ':'}</Typography>
        </Grid>
    }

// Show color picker
    return (
        <Grid container>
            {label}
            {Displaycolors}
        </Grid>
    )
}

export default ColorPicker;

ColorPicker.propTypes = {
    borderColor: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    initialValues: PropTypes.object,
    setFieldValue: PropTypes.func,
    showLabel: PropTypes.bool,
    values: PropTypes.object,
    id: PropTypes.string,
    savedData: PropTypes.object,
};