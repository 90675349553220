
import React, {useState} from "react";
import customClasses from "./Registration.module.css";
import {TabContext, TabPanel} from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import GridOnIcon from "@mui/icons-material/GridOn";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EuroIcon from '@mui/icons-material/Euro';
import RegistrationTabFind from "./RegistrationTabFind";
import RegistrationTabAddress from "./RegistrationTabAddress";
import RegistrationTabContact from "./RegistrationTabContact";
import RegistrationTabAccount from "./RegistrationTabAccount";
import RegistrationTabTemplate from "./RegistrationTabTemplate";
import RegistrationTabButton from "./RegistrationTabButton";
import RegistrationTabInvoiceAddress from "./RegistrationTabInvoiceAddress";
import i18n from "../../../i18n/i18n";

const RegistrationTab = (props) => {

    const [error, setError] = useState({
        tab: 0,
        state:false
    });

    let buttonAction;
    let buttonText;
    let onboarding;

    switch (props.stateValueTab) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
            buttonAction = (event) => props.nextTabHandler(event);
            buttonText = i18n.t('BUTTON_NEXT');
            break;
        case 5:
            buttonAction = (event) => props.submitHandler(event);
            buttonText = i18n.t('BUTTON_CREATE_ACCOUNT');
            break
        default:
            break;
    }

    const handleError = (tab, value)=>{
        setError({
            tab: tab,
            state:value
        })
    }

    onboarding =
        <form onSubmit={buttonAction} className={customClasses.registrationForm}>
            <TabContext value={props.stateValueTab.toString()}>
                <Tabs value={props.stateValueTab}
                      onChange={props.handleChangeTab}
                      indicatorColor="primary"
                      textColor="primary"
                      allowScrollButtonsMobile={true}
                >
                    <Tab id="tabFind" value={0} label={i18n.t('COMPANY')} icon={<SearchIcon/>}
                         className={props.getTabClasses('tabStateFind')} disabled={!props.stateTabFind}/>
                    <Tab id="tabAddress" value={1} label={i18n.t('ADDRESS')} icon={<HomeIcon/>}
                         className={props.getTabClasses('tabStateAddress')} disabled={!props.stateTabAddress}/>
                    <Tab id="tabInvoiceAddress" value={2} label={i18n.t('INVOICE_ADDRESS')} icon={<EuroIcon/>}
                         className={props.getTabClasses('tabStateInvoiceAddress')}
                         disabled={!props.stateTabInvoiceAddress}/>
                    <Tab value={3} label={i18n.t('CONTACT')} icon={<EmailIcon/>}
                         className={props.getTabClasses('tabStateContact')} disabled={!props.stateTabContact}/>
                    <Tab value={4} label={i18n.t('ACCOUNT')} icon={<AccountCircleIcon/>}
                         className={props.getTabClasses('tabStateAccount')} disabled={!props.stateTabAccount}/>
                    <Tab value={5} label={i18n.t('TEMPLATE')} icon={<GridOnIcon/>}
                         className={props.getTabClasses('tabStateTemplate')} disabled={!props.stateTabTemplate}/>
                </Tabs>
                <TabPanel value={"0"} index={0}>
                    <RegistrationTabFind
                        bedrijf={props.tabFind.inputBedrijf}
                        vihb={props.tabFind.inputVIHB}
                        address={props.tabAddress}
                        inputChangeBedrijf={props.handleInputChange('inputBedrijf')}
                        inputChangeVihb={props.handleInputChange('inputVIHB')}
                        handleCompanySelected={props.handleCompanySelected}
                        skipVihbSearch={props.skipVihbSearch}
                        setError={setError}
                    />
                </TabPanel>
                <TabPanel value={"1"} index={1} id="tabFind">
                    <RegistrationTabAddress
                        address={props.tabAddress.inputAddress}
                        houseNumber={props.tabAddress.inputHouseNumber}
                        houseNumberAddition={props.tabAddress.inputHouseNumberAddition}
                        zipcode={props.tabAddress.inputZipCode}
                        place={props.tabAddress.inputPlace}
                        country={props.tabAddress.inputCountry}
                        inputChangeAddress={props.handleInputChange('inputAddress')}
                        inputChangeHouseNumber={props.handleInputChange('inputHouseNumber')}
                        inputChangeHouseNumberAddition={props.handleInputChange('inputHouseNumberAddition')}
                        inputChangeZipCode={props.handleInputChange('inputZipCode')}
                        inputChangePlace={props.handleInputChange('inputPlace')}
                        inputChangeCountry={props.handleInputChange('inputCountry')}/>
                </TabPanel>
                <TabPanel value={"2"} index={2}>
                    <RegistrationTabInvoiceAddress
                        switchCopyAddress={props.tabInvoiceAddress.inputSwitchCopyAddress}
                        address={props.tabInvoiceAddress.inputAddress}
                        houseNumber={props.tabInvoiceAddress.inputHouseNumber}
                        houseNumberAddition={props.tabInvoiceAddress.inputHouseNumberAddition}
                        zipcode={props.tabInvoiceAddress.inputZipCode}
                        place={props.tabInvoiceAddress.inputPlace}
                        country={props.tabInvoiceAddress.inputCountry}
                        copyAddress={props.copyAddress}
                        inputChangeAddress={props.handleInputChange('inputAddress')}
                        inputChangeHouseNumber={props.handleInputChange('inputHouseNumber')}
                        inputChangeHouseNumberAddition={props.handleInputChange('inputHouseNumberAddition')}
                        inputChangeZipCode={props.handleInputChange('inputZipCode')}
                        inputChangePlace={props.handleInputChange('inputPlace')}
                        inputChangeCountry={props.handleInputChange('inputCountry')}/>
                </TabPanel>
                <TabPanel value={"3"} index={3}>
                    <RegistrationTabContact
                        firstName={props.tabContact.inputFirstName}
                        insertion={props.tabContact.inputInsertion}
                        lastName={props.tabContact.inputLastName}
                        phone={props.tabContact.inputPhone}
                        inputChangeFirstName={props.handleInputChange('inputFirstName')}
                        inputChangeInsertion={props.handleInputChange('inputInsertion')}
                        inputChangeLastName={props.handleInputChange('inputLastName')}
                        inputChangePhone={props.handleInputChange('inputPhone')}/>
                </TabPanel>
                <TabPanel value={"4"} index={4}>
                    <RegistrationTabAccount
                        handleError={handleError}
                        password={props.tabAccount.inputPassword}
                        showPassword={props.showPassword}
                        email={props.tabAccount.inputEmail}
                        inputChangeEmail={props.handleInputChange('inputEmail')}
                        inputChangePassword={props.handleInputChange('inputPassword')}
                        handleClickShowPassword={props.handleClickShowPassword}
                        handleMouseDownPassword={props.handleMouseDownPassword}/>
                </TabPanel>
                <TabPanel value={"5"} index={5}>
                    <RegistrationTabTemplate/>
                </TabPanel>
            </TabContext>
            <RegistrationTabButton
                disabled={(props.stateValueTab === error.tab) && error.state === true}
                buttonText={buttonText}
                buttonAction={props.submitHandler}
            />
        </form>
    return onboarding;
}

export default RegistrationTab;