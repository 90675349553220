import Grid from "@mui/material/Grid";
import React from "react";
import customClasses from "./TemplateForm.module.css";
import PropTypes from "prop-types";
import {FastField} from "formik";
import {FormTextField} from "./Components/FormTextField";
import UnitTypeDropdown from "../Templates/Onboarding/Dropdowns/UnitTypeDropdown";
import ColorPickerButtons from "../../Controls/ColorPickerButtons";
import {Typography} from "@mui/material";

export default function TemplateFormIntro(props) {

    const {
        values,
    } = props;

    return <React.Fragment>

        {/*===========================================================================*/}
        {/* Block Content
        {/*===========================================================================*/}
        <Grid item xs={12} md={12}>
            <Grid item xs={12} md={12}>
                <h1>Begeleidingsbrief</h1>
                {
                    values.form_type !== 'B2' ? (
                        <span>ADMINISTRATIE- / VRIJWARINGSBEWIJS(C1/A2) (voor transporteur(vak 5))
                                            Verplicht te gebruiken voor transport van afvalstoffen</span>
                    ) : (
                        <>
                        <span>INTERNE COPIE (D)- / EXTRA BEWIJS VAN ONTVANGST (B2) <span style={{fontSize:11}}>(voor ontdoenders)</span> <br/>
                                            Te gebruiken voor afvalvervoer of <br/></span>
                            <span style={{fontStyle: 'italic', fontSize:11}}>TRANSPORTEN WELKE VALLEN ONDER HET BESLUIT BODEMKWALITEIT</span>
                        </>
                    )
                }
                <br/><br/>
            </Grid>
        </Grid>
    </React.Fragment>
}

TemplateFormIntro.propTypes = {
    values: PropTypes.object.isRequired
};