import * as actionsTypes from './actionTypes'
import boa from '../../api/axios-auth'
import peer from '../../api/axios-peer'
import gps from '../../api/axios-gps'
import formklub from '../../api/axios-formklub'
import {jwtDecode} from "jwt-decode";
import resources from '../../resources.json'
import {resetDataTable} from "./dataTable";

export const authStart = () => {
    return {
        type: actionsTypes.AUTH_START
    }
}

const authRegisterToResourceServer = (key) => {
    return {
        type: actionsTypes.AUTH_REGISTER_TO_RESOURCE_SERVER,
        scope: key
    }
}

export const authSuccess = (token) => {
    return {
        type: actionsTypes.AUTH_SUCCESS,
        token: token,
        authRedirectPath: '/Dashboard',
    }
}

export const authSetIdentity = (data) => {
    return {
        type: actionsTypes.AUTH_IDENTITY,
        jwt: data,
    }
}

export const setSelectedRegions = (regions) => {
    localStorage.setItem('regionsSelected', JSON.stringify(Object.keys(regions)));
    return {
        type: actionsTypes.SET_SELECTED_REGIONS,
        data: {regionsSelected: regions},
    }
}

export const setLocalSelectedProjects = (projects) => {
    localStorage.setItem('projectsSelected', JSON.stringify(Object.keys(projects)));
    return {
        type: actionsTypes.SET_SELECTED_PROJECTS,
        data: {projectsSelected: projects},
    }
}

export const setActiveCompany = (company) => {
    if (!company) {
        return {
            type: actionsTypes.SET_ACTIVE_COMPANY,
            data: {},
        }
    }
    const storedCompany = localStorage.getItem('companySelected');
    let regions = [];
    if (storedCompany !== company.UUID) {
        localStorage.setItem('companySelected', company.UUID);
        regions = company.regions;
        localStorage.setItem('regionsSelected', JSON.stringify(Object.keys(regions)));
    } else {
        const selectedKeys = JSON.parse(localStorage.getItem('regionsSelected'));
        if (selectedKeys) {
            selectedKeys.map(item => {
                regions[item] = company.regions[item]
            });
        } else {
            regions = company.regions;
        }
    }

    return {
        type: actionsTypes.SET_ACTIVE_COMPANY,
        data: {companySelected: company, regionsSelected: regions},
    }
}

export const isManagerForCompany = (user, activeCompany) => {
    if (user?.authAssignments == null) {
        return false;
    } else {
        const authAssignments = user.authAssignments.find(authAssignment => authAssignment.company_id === activeCompany.id && authAssignment.item_name === "manager");

        if (authAssignments) {
            return true;
        }
        return false;
    }
}

export const isCompanyManagerForCompany = (user, activeCompany) => {
    if (user?.authAssignments == null) {
        return false;
    } else {
        const authAssignments = user.authAssignments.find(authAssignment => authAssignment.company_id === activeCompany.id && authAssignment.item_name === "manager" && authAssignment.region_id === null);

        if (authAssignments) {
            return true;
        }
        return false;
    }
}

export const authResetLogin = (path) => {
    localStorage.removeItem('token')
    localStorage.removeItem('loggedin')

    return {
        type: actionsTypes.AUTH_RESET_LOGIN,
        path: path,
    }
}

const authFail = (error) => {
    return {
        type: actionsTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    return dispatch => {
        let url = '/authenticate/logout';
        boa.get(url)
            .then(() => {
                dispatch(authLogout());
                dispatch(resetDataTable());
            })
            .catch(err => {
                dispatch(authLogout());
                if (err.statusCode === 401) {
                    dispatch(resetDataTable());
                } else {
                    if (err.response === undefined) {
                        dispatch(authFail("onbekende fout"))
                    }
                    dispatch(authFail(err.response?.data?.message ?? "onbekende fout"))
                    dispatch(resetDataTable());
                }
            })
    }
}

const authLogout = () => {
    localStorage.removeItem('regionsSelected');
    localStorage.removeItem('loggedin');
    localStorage.removeItem('companySelected');
    localStorage.removeItem('projectsSelected');
    localStorage.removeItem('token');
    return {
        type: actionsTypes.AUTH_LOGOUT
    }
}

const setAuthTimeoutHandle = (expirationTime) => {
    return dispatch => {
        let timeout = expirationTime - 120000;
        if (timeout < 5000) {
            timeout = 5000;
        }
        setTimeout(() => {
            dispatch(authCheckSession())
        }, timeout)
    }
}

export const authCheckState = () => {
    return dispatch => {
        const loggedin = localStorage.getItem('loggedin');
        if (!loggedin) {
            return;
        }
        let url = '/authenticate/verify';
        boa.get(url)
            .then(response => {
                if (response.data.access_token.length < 1) {
                    dispatch(logout())
                    dispatch(authFail("er ging iets fout"))
                    return;
                }
                dispatch(authValidateToken(response.data.access_token))
            })
            .catch(err => {
                dispatch(logout())
                if (err.response === undefined) {
                    dispatch(authFail("onbekende fout"))
                    return;
                }
                dispatch(authFail("Kan sessie niet herstellen"))
            })

        dispatch(initScopes())
    }
}

export const authCheckSession = () => {
    return dispatch => {
        let url = '/authenticate/verify';
        boa.get(url)
            .then(response => {
                if (response.data.access_token.length < 1) {
                    dispatch(logout())
                    dispatch(authFail("er ging iets fout"))
                }
                dispatch(authValidateToken(response.data.access_token, true))
            })
            .catch(err => {
                dispatch(logout())
                if (err.response === undefined) {
                    dispatch(authFail("onbekende fout"))
                    return;
                }
                dispatch(authFail("Sessie verlopen"))
            })
    }
}

const authValidateToken = (token, sessionActive = false) => {
    return dispatch => {
        var decoded = jwtDecode(token);
        if (decoded.jti.length < 1) {
            dispatch(logout())
            dispatch(authFail("Gegevens konden niet gevalideerd worden"))
            return;
        }
        if (decoded.client_id !== process.env.REACT_APP_AUTH_CLIENT_ID) {
            dispatch(logout())
            dispatch(authFail("Error 1001 issuer not verified"))
            return;
        }
        const expirationDate = new Date(decoded.exp * 1000)
        if (expirationDate < new Date()) {
            dispatch(logout())
            dispatch(authFail("Huidige sessie is verlopen"))
            return;
        }
        if (!sessionActive) {
            localStorage.setItem('loggedin', true)
            dispatch(authSetIdentity(decoded))
            dispatch(getAuthorisation(token))
        }
        dispatch(authSetAuthorisation(token))
    }
}

export const initScopes = () => {
    const config = resources;
    const scopes = {}
    config.resources.forEach(element => {
        let resource = null;
        const scope = element.scope
        switch (scope) {
            default:
            case 'PEER':
                resource = peer
                break;
            case 'GPS':
                resource = gps
                break;
            case 'FORMKLUB':
                resource = formklub
                break;
        }
        if (!resource) {
            return;
        }

        scopes[scope] = {
            ...element,
            initialized: false,
            registered: false,
            isVerified: false,
            resource: resource
        }
    });

    return {
        type: actionsTypes.AUTH_SET_SCOPES,
        scopes: scopes
    }
}


export const authSetAuthorisation = (token) => {
    return dispatch => {
        localStorage.setItem('token', token)

        var decoded = jwtDecode(token);
        dispatch(setAuthTimeoutHandle((decoded.exp * 1000) - new Date().getTime()))
        dispatch(authSuccess(token))
    }
}

export const auth = (username, password) => {
    return dispatch => {
        dispatch(initScopes());
        dispatch(authStart());

        const scopes = []
        resources.resources.forEach(element => {
            scopes.push(element.scope)
        });

        const authData = {
            username: username,
            password: password,
            grant_type: "password",
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
            client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
            redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI,
            scope: scopes.join(' '),
        }
        let url = '/authenticate';

        boa.post(url, authData)
            .then(response => {
                if (response.data.access_token.length < 1) {
                    dispatch(logout())
                    dispatch(authFail("er ging iets fout"))
                    return;
                }
                dispatch(authValidateToken(response.data.access_token))
            })
            .catch(err => {
                if (err.response === undefined) {
                    dispatch(logout())
                    dispatch(authFail("onbekende fout"))
                    return;
                }

                dispatch(authFail(err.response.data.message ?? "onbekende fout"))
                return;
            })
    }
}

export const getAuthorisation = (token) => {
    return (dispatch, getState) => {
        const scopes = getState().auth.scopes
        Object.keys(scopes).forEach(function (key) {
            const element = scopes[key]
            dispatch(authRegisterToResourceServer(key));

            const resource = element.resource;
            if (element.introspect) {
                dispatch(introSpect(resource, token, element, key));
            } else {
                dispatch(authSetRegistered(element.scope, {}, resource, false, true))
            }
        })
    }
}

export const authSetScopeData = (scope, object) => {
    return {
        type: actionsTypes.AUTH_SET_SCOPE_DATA,
        scope: scope,
        data: object
    }
}


export const introSpect = (resource, token, element, key) => {
    return (dispatch) => {
        const authData = {
            token: token
        }

        let url = '/authorize/introspect';
        resource.post(url, authData)
            .then(response => {
                const data = response.data;
                let extraFields = {};

                if (key === "PEER") {
                    const uuid = localStorage.getItem('companySelected');
                    let companySelected = null;
                    //CHECK:  If localstorage company is set and valid
                    const regexUUid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
                    if ((uuid) && regexUUid.test(uuid)) {
                        companySelected = data.companies.uuid

                        //CHECK: if localstorage uuid is correct
                        if ((Object.entries(data.companies).length === 1) && (uuid !== Object.keys(data.companies)[0])) {
                            localStorage.setItem('companySelected', Object.keys(data.companies)[0]);
                        }

                        //CHECK: if all regions in localstate are still active
                        const localRegions = localStorage.getItem('regionsSelected');
                        if (localRegions && data?.companies) {
                            const companyRegions = Object.keys(data?.companies[uuid].regions)
                            let localRegionsBrowser = JSON.parse(localRegions);

                            // const elementsToAdd = companyRegions.filter(value => !localRegionsBrowser.includes(value));
                            const elementsToRemove = localRegionsBrowser.filter(value => !companyRegions.includes(value));

                            // Remove elements from localRegionsBrowser
                            if (elementsToRemove) {
                                localRegionsBrowser = localRegionsBrowser.filter(value => !elementsToRemove.includes(value));
                            }
                            // Dont add
                            // if(elementsToAdd) {
                            //     localRegionsBrowser.push(...elementsToAdd);
                            // }

                            localStorage.setItem('regionsSelected', JSON.stringify(localRegionsBrowser));
                        }
                    } else {
                        // CHECK: autoselect company when user has 1 company
                        if (Object.entries(data.companies).length === 1) {
                            let key = Object.keys(data.companies)[0];
                            companySelected = data.companies[key]
                            localStorage.setItem('companySelected', companySelected.UUID);
                            // CHECK: autoselect when user has 1 company fill regions
                            if (companySelected?.regions) {
                                localStorage.setItem('regionsSelected', JSON.stringify(Object.keys(companySelected?.regions)));
                            }
                        } else {
                            // Just Clear everything
                            localStorage.removeItem('companySelected');
                            localStorage.removeItem('regionsSelected');
                        }
                    }

                    extraFields = {
                        companies: {...data.companies},
                        companySelected: companySelected,
                        regionsSelected: companySelected?.regions || []
                    };
                    delete data.companies;
                }
                dispatch(authSetRegistered(element.scope, data, resource, data.isActive, true, extraFields, data.isActive))
            })
            .catch(err => {
                if (err.response === undefined) {
                    dispatch(authSetRegisteredFail(key, "onbekende fout", resource))
                    return;
                }
                if (err.response.status >= 400) {
                    dispatch(authSetRegistered(element.scope, err.response.data, resource, false, true));
                    return;
                }
                dispatch(authSetRegisteredFail(key, err.response.data.message ?? "onbekende fout", resource))
            })
    }
}

export const authSetRegisteredFail = (key, message, resource) => {
    return {
        type: actionsTypes.SET_AUTH_RESOURCE_REGISTERED_FAILED,
        scope: key,
        registered: false,
        error: message,
        resource: resource
    }
}

export const authSetRegistered = (scope, data, resource, isActive, initialized, extraFields, loginAllowed) => {
    return {
        type: actionsTypes.SET_AUTH_RESOURCE_REGISTERED,
        scope: scope,
        registered: true,
        resource: resource,
        data: data,
        extraFields: extraFields,
        isActive: isActive,
        initialized: initialized,
        loginAllowed: loginAllowed,
    }
}

export const refreshToken = () => {
    return dispatch => {
        const scopes = []
        resources.resources.forEach(element => {
            scopes.push(element.scope)
        });
        const authData = {
            grant_type: "refresh_token",
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
            client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
            scope: scopes.join(" "),
        }
        let url = '/refresh-token';

        boa.post(url, authData)
            .then(response => {
                if (response.data.access_token.length < 1) {
                    dispatch(logout())
                    dispatch(authFail("er ging iets fout"))
                    return;
                }

                dispatch(authValidateToken(response.data.access_token))
            })
            .catch(err => {
                if (err.response === undefined) {
                    dispatch(logout())
                    dispatch(authFail("onbekende fout"))
                    return;
                }
                dispatch(authFail(err.response.data.message ?? "onbekende fout"))
                return;
            })
    }
}

export const getAuthItemsMe = () => {
    //test
    return (dispatch) => {
        peer.get('/auth-assignment/me/')
            .then(response => {
                dispatch(setgetAuthItemsMe(response.data))
            }).catch(err => {
            dispatch(setgetAuthItemsMe({}))
            console.error(err)
        })
    }
}
export const setgetAuthItemsMe = (data) => {
    return {
        type: actionsTypes.SET_AUTH_ITEM_CHILD,
        data: data
    }
}

export const setAuthRedirect = (path) => {
    return {
        type: actionsTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}