export const FETCH_DATA_TABLE_START = 'FETCH_DATA_TABLE_START';
export const FETCH_DATA_TABLE_SUCCESS = 'FETCH_DATA_TABLE_SUCCESS';
export const FETCH_DATA_TABLE_FAIL = 'FETCH_DATA_TABLE_FAIL';
export const UPDATE_DATA_TABLE_RECORD = 'UPDATE_DATA_TABLE_RECORD';
export const CLEAR_DATA_TABLE_INDEX = 'CLEAR_DATA_TABLE_INDEX';
export const SET_DATA_TABLE_ACTION = 'SET_DATA_TABLE_ACTION';
export const INSERT_DATA_TABLE_RECORD = 'INSERT_DATA_TABLE_RECORD';
export const REMOVE_DATA_TABLE_RECORD = 'REMOVE_DATA_TABLE_RECORD';
export const SET_DATA_TABLE_MODE = "SET_DATA_TABLE_MODE";
export const RESET_DATA_TABLE = 'RESET_DATA_TABLE';
export const EXPORT_DATA_TABLE = "EXPORT_DATA_TABLE";
export const LOAD_SAVED_DATA_TABLE = "LOAD_DATA_TABLE";
export const UPDATE_DATA_TABLE_VALUE = 'UPDATE_DATA_TABLE_VALUE';
export const SELECT_TABLE_ROWS = 'SELECT_TABLE_ROWS';

export const EXPAND_TABLE_ROWS = 'EXPAND_TABLE_ROWS';

export const SELECT_DATA_TABLE_ROW = 'SELECT_DATA_TABLE_ROW';
export const CLOSE_DATA_TABLE_ROW = 'CLOSE_DATA_TABLE_ROW';
export const OPEN_DIALOG_TABLE = 'OPEN_DIALOG_TABLE';
export const CLOSE_DIALOG_TABLE = 'CLOSE_DIALOG_TABLE';
export const SET_ACTION_HANDLE = 'SET_ACTION_HANDLE';
export const SET_DISABLED = 'SET_DISABLED';

export const SAVE_DATA_START = 'SAVE_DATA_START';
export const SAVE_DATA_SUCCESS = 'SAVE_DATA_SUCCESS';

export const ADD_DATA_SUCCESS = 'ADD_DATA_SUCCESS';
export const DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS';
export const SAVE_DATA_FAIL = 'SAVE_DATA_FAIL';

export const FORM_EDITED = 'FORM_EDITED';
export const FORM_NOT_EDITED = 'FORM_NOT_EDITED';

export const SET_LOADING = 'SET_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';

export const SET_ERROR= 'SET_ERROR';
export const SET_ALERT= 'SET_ALERT';

export const SET_ADD_FORM_OPEN = 'SET_ADD_FORM_OPEN';

export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';

export const AUTH_START = "AUTH_START";
export const AUTH_REGISTER_TO_RESOURCE_SERVER = "AUTH_REGISTER_TO_RESOURCE_SERVER";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_IDENTITY = "AUTH_IDENTITY";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SET_SCOPES = "AUTH_SET_SCOPES";
export const AUTH_SET_SCOPE_DATA = "AUTH_SET_SCOPE_DATA";

export const AUTH_ACCOUNT_VERIFIED = 'AUTH_ACCOUNT_VERIFIED';
export const AUTH_ACCOUNT_DISABLED = 'AUTH_ACCOUNT_DISABLED';
export const AUTH_COMPANY_DISABLED = 'AUTH_COMPANY_DISABLED';
export const AUTH_RESET_LOGIN = 'AUTH_RESET_LOGIN';

export const SET_AUTH_RESOURCE_REGISTERED = "SET_AUTH_RESOURCE_REGISTERED";
export const SET_AUTH_RESOURCE_REGISTERED_FAILED = "SET_AUTH_RESOURCE_REGISTERED_FAIL";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const SET_ACTIVE_COMPANY = 'SET_ACTIVE_COMPANY';
export const SET_SELECTED_REGIONS = 'SET_SELECTED_REGIONS';
export const SET_SELECTED_PROJECTS = 'SET_SELECTED_PROJECTS';
export const SET_AUTH_ITEM_CHILD = 'SET_AUTH_ITEM_CHILD';

export const FETCH_GPS_START = 'FETCH_GPS_START';
export const FETCH_GPS_SUCCESS = 'FETCH_GPS_SUCCESS';
export const CLOSE_GPS = 'CLOSE_GPS';
export const FETCH_GPS_FAIL = 'FETCH_GPS_FAIL';
export const RESET_GPS = 'RESET_GPS';

export const INIT_AUTH_UPDATE = "INIT_AUTH_UPDATE";
export const START_UPDATE_ACCOUNT = "START_UPDATE_ACCOUNT";
export const END_UPDATE_ACCOUNT = "END_UPDATE_ACCOUNT";
export const USER_UPDATED = "USER_UPDATED";
export const PASSWORD_CHANGE_FAIL = "PASSWORD_CHANGE_FAIL";
export const SET_TABLE_INITIALIZED = "SET_TABLE_INITIALIZED";

export const REGISTER_START_LOADING = "START_LOADING";
export const REGISTER_USER_SUBMIT = "REGISTER_USER_SUBMIT";
export const REGISTER_USER_START = "REGISTER_USER_START";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";
export const REGISTER_USER_FAIL_ALERT = "REGISTER_USER_FAIL_ALERT";
export const REGISTER_CHECK_ACTIVATION_KEY_SUCCESS = "REGISTER_CHECK_ACTIVATION_KEY_SUCCESS";
export const SET_ACTIVATE_ERROR = "SET_ACTIVATE_ERROR";
export const ACTIVATE_SUCCESS = "ACTIVATE_SUCCESS";

export const PASSWORD_REQUESTED = "PASSWORD_REQUESTED";
export const PASSWORD_RESET_START = "PASSWORD_RESET_START";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASSWORD_RESET_FAIL_ALERT = "PASSWORD_RESET_FAIL_ALERT";
export const PASSWORD_AUTH_KEY_VALID = "PASSWORD_AUTH_KEY_VALID";

export const TEMPLATE_AUTH_START_LOADING = "TEMPLATE_AUTH_START_LOADING";
export const TEMPLATE_AUDIT_SET_INFO = "TEMPLATE_AUDIT_SET_INFO";
export const TEMPLATE_AUDIT_SET_ERROR = "TEMPLATE_AUDIT_SET_ERROR";
export const TEMPLATE_AUDIT_SET_COMMENT_DATA = "TEMPLATE_AUDIT_SET_COMMENT_DATA";
export const TEMPLATE_AUDIT_SEND_COMMENT_SUCCESS = "TEMPLATE_AUDIT_SEND_COMMENT_SUCCESS";
export const CLEAR_DATA_TABLE_SETTINGS = "CLEAR_DATA_TABLE_SETTINGS";
export const CLEAR_ALL_DATA_TABLE_SETTINGS = "CLEAR_ALL_DATA_TABLE_SETTINGS";
export const UPDATE_REDUX_FILTERS = "UPDATE_REDUX_FILTERS";

export const FETCH_DATA_REQUEST_SAGA = 'FETCH_DATA_REQUEST_SAGA';
export const FETCH_DATA_SUCCESS_SAGA  = 'FETCH_DATA_SUCCESS_SAGA';
export const FETCH_DATA_FAILURE_SAGA  = 'FETCH_DATA_FAILURE_SAGA';

export const FETCH_TABS_REQUEST_SAGA  = 'FETCH_TABS_REQUEST_SAGA';
export const FETCH_TABS_SUCCESS_SAGA  = 'FETCH_TABS_SUCCESS_SAGA';
export const FETCH_TABS_FAILURE_SAGA  = 'FETCH_TABS_FAILURE_SAGA';
export const SET_TAB_REQUEST_SAGA  = 'SET_TAB_REQUEST_SAGA';
export const SET_TAB_SUCCESS_SAGA  = 'SET_TAB_SUCCESS_SAGA';
export const SET_TAB_FAILURE_SAGA  = 'SET_TAB_FAILURE_SAGA';

export const FETCH_GPS_REQUEST_SAGA = 'FETCH_GPS_REQUEST_SAGA';
export const FETCH_GPS_SUCCESS_SAGA  = 'FETCH_GPS_SUCCESS_SAGA';
export const FETCH_GPS_FAILURE_SAGA  = 'FETCH_GPS_FAILURE_SAGA';