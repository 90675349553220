
import React, {useEffect} from "react";
import {Button, Grid, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import customClasses from '../Auth.module.css';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";

export default function ResetPasswordForm(props) {
    const [showPassword, setShowPassword] = React.useState(false);
    const [passwordValid, setPasswordValid] = React.useState(false);

    //Check so user can not go further until password is strong enough
    useEffect(() => {
        const regexPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/
        const valid = regexPattern.test(props.value);
        if (!valid) {
            setPasswordValid(false)
        }else{
            setPasswordValid(true)
        }
    },[props.value])

    return(
            <Grid className={customClasses.contentSpacing} container alignItems="flex-end" justifyContent="flex-end">
                <Grid item xs={12}>
                    <Typography align='left' variant="h6">{i18n.t('TITLE_NEW_PASSWORD')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align='left' variant="h6">{props.email}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="wachtwoord"
                        label={i18n.t('PASSWORD')}
                        type={showPassword ? 'text' : 'password'}
                        margin="normal"
                        value={props.value}
                        onChange={props.onChange}
                        fullWidth
                        autoFocus
                        required
                        error={(props.value.length > 0 && !passwordValid) || false}
                        helperText={i18n.t('REQUIREMENTS_PASSWORD')}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>setShowPassword(!showPassword)}
                                        size="large">
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    <br/>
                </Grid>
            <Grid item xs={12}>
                <br/>
                <Button
                    disabled={!passwordValid}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                    style={{ textTransform: "none" }}>{i18n.t('BUTTON_SAVE_PASSWORD')}
                </Button>
            </Grid>
            </Grid>
    )
}

ResetPasswordForm.propTypes = {
    value : PropTypes.string,
    email : PropTypes.string,
    onChange : PropTypes.func.isRequired,
}