
import React, {useEffect, useState} from "react";
import { styled } from '@mui/material/styles';
import Tooltip from "@mui/material/Tooltip";
import IconSearch from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import i18n from "../../i18n/i18n";


const PREFIX = 'SearchIcon';

const classes = {
    searchIcon: `${PREFIX}-searchIcon`
};

const StyledTooltip = styled(Tooltip)(() => ({
    [`& .${classes.searchIcon}`]: {
        cursor: 'pointer',
    }
}));

const SearchIcon = (props) => {


    //Default props
    const [color, setColor] =  useState('#CCCCCC');
    const [title, setTitle] =  useState(i18n.t('TOOLTIP_SEARCH'));
    const [size, setSize] =  useState('1.5rem');
    const [labelDirection, setLabelDirection] =  useState('right');

    useEffect(() => {
        if(props.color){setColor(props.color)}
        if(props.size){setSize(props.size)}
        if(props.title){setTitle(props.title)}
        if(props.labelDirection){setLabelDirection(props.labelDirection)}
    },[])

    return props.disabled ? (
        <div/>
    ) : (
        <StyledTooltip
            placement={labelDirection}
            title={title}>
            <IconSearch
                onClick={() => props.handleClick()}
                className={classes.searchIcon}
                style={{ color: color, width: size, height: size, cursor:'pointer'}}
            />
        </StyledTooltip>
    );
}
export default SearchIcon;

SearchIcon.propTypes = {
    disabled: PropTypes.bool.isRequired,
    handleClick: PropTypes.func,
    color: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.string,
    labelDirection: PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-start', 'right-end', 'right', 'top-end', 'top-start', 'top']),

};