import React, {useEffect} from "react";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import {ExitToApp, Lock, Settings, Help} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import {Link as RouterLink} from "react-router-dom";
import Divider from "@mui/material/Divider";
import {isCompanyManagerForCompany, logout, setActiveCompany} from "../../../store/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import HeaderAccountMenuCompanySelector from "./HeaderAccountMenuCompanySelector";
import {getActiveCompany} from "../../../store/selectors/companySelector";
import {getScopePeerCompanies, getScopePeerState} from "../../../store/selectors/scopeSelector";
import {ListItem, ListItemButton} from "@mui/material";
import {isScoretraceAdmin} from "../../../store/selectors/userSelector";
import i18n from "../../../i18n/i18n";
import {styled} from "@mui/material/styles";
import PropTypes from "prop-types";

export default function HeaderAccountMenu(props) {
    const scopePeerCompanies = useSelector(state => getScopePeerCompanies(state));
    const scopePeerState = useSelector(state => getScopePeerState(state));
    const activeCompany = useSelector(state => getActiveCompany(state));
    const manager = useSelector(state => isCompanyManagerForCompany(state));
    const admin = useSelector(state => isScoretraceAdmin(state));

    const handleClose = props.handleClose;
    const anchorEl = props.anchorEl;
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        const uuid = localStorage.getItem('companySelected');
        if (uuid !== null && scopePeerCompanies && !activeCompany) {
            dispatch(setActiveCompany(scopePeerCompanies[uuid]));
        }
    }, [scopePeerCompanies])

    const FireNav = styled(List)({
        '& .MuiListItemButton-root': {
            paddingLeft: 24,
            paddingRight: 24,
        },
        '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: 16,
        },
        '& .MuiSvgIcon-root': {
            fontSize: 20,
        },
    });

    return <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}>
        <FireNav component="nav">
            {/*Company selector*/}
            {
                (props.headerOnly || scopePeerState) ?
                    <HeaderAccountMenuCompanySelector handleClose={props.handleClose}/> : ''
            }
            <Divider variant="middle" component="li"/>
            {/*Change password*/}
            <ListItemButton onClick={() => handleClose()} key="Wachtwoord wijzigen" to={'/change-password'}
                            component={RouterLink}>
                <ListItemIcon> <Lock/> </ListItemIcon>
                <ListItemText primary={i18n.t('BUTTON_CHANGE_PASSWORD')}/>
            </ListItemButton>
            {
                manager || admin ? (
                    <ListItemButton onClick={() => handleClose()} key="Instellingen" to={'/Bedrijf/Instellingen'}
                                    component={RouterLink}>
                        <ListItemIcon> <Settings/> </ListItemIcon>
                        <ListItemText primary={i18n.t('SETTINGS')}/>
                    </ListItemButton>
                ) : null
            }
            {/*<Divider/>*/}
            {/*Logout*/}
            <ListItemButton onClick={() => dispatch(logout())} key="Uitloggen">
                <ListItemIcon> <ExitToApp/> </ListItemIcon>
                <ListItemText primary={i18n.t('BUTTON_LOGOUT')}/>
            </ListItemButton>
            <Divider variant="middle" component="li"/>
            <Divider variant="middle" component="li"/>
            <ListItemButton onClick={() => handleClose()} key="Handleiding" to={'/hulp-nodig'}
                            component={RouterLink}>
                <ListItemIcon> <Help/> </ListItemIcon>
                <ListItemText primary={i18n.t('NEED_HELP')} secondary={i18n.t('TITLE_OPEN_MANUALS')}/>
            </ListItemButton>
            <Divider variant="middle" component="li"/>
            <Divider variant="middle" component="li"/>
            {/*Portal Version*/}
            <ListItem sx={{textAlign: 'center'}}>
                <ListItemText
                    disabled
                    secondary={<Typography component="div" variant="caption">{(new Date().getFullYear())} ScoreTrace All
                        rights reserved <br/>{i18n.t('VERSION')}: {process.env.REACT_APP_VERSION}</Typography>}/>
            </ListItem>
        </FireNav>
    </Popover>
}

HeaderAccountMenu.propTypes = {
    Component : PropTypes.element,
    handleClose: PropTypes.func,
    anchorEl : PropTypes.object,
    headerOnly : PropTypes.bool,
}