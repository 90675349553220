import {states} from "../../constants/States";
import {createSelector} from "reselect";
import {getScopePeer, GetScopePeerAuth} from "./scopeSelector";

/**
 * Returns active company
 *
 *  @returns {object}
 */
export const getActiveCompany = createSelector(
    [getScopePeer],
    (scopePeer) => {
        if(!scopePeer){
            return;
        }
        return scopePeer.companySelected;
    }
);

/**
 * Returns name of active company
 *
 *  @returns {string}
 */
export const getActiveCompanyName = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return;
        }
        return activeCompany.name;
    }
);

/**
 * Returns ID of active company
 *
 *  @returns {number}
 */
export const getActiveCompanyId = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return;
        }
        return activeCompany.id;
    }
);

/**
 * Returns VIHB of active company
 *
 *  @returns {string}
 */
export const getActiveCompanyVIHB = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return;
        }
        return activeCompany.vihb;
    }
);

/**
 * Returns UUID of active company
 *
 *  @returns {string}
 */
export const getActiveCompanyUUID = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return;
        }
        return activeCompany.UUID;
    }
);

/**
 * Returns Logo of active company
 *
 *  @returns {string | nullable}
 */
export const getActiveCompanyLogo = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return;
        }
        return activeCompany.logo_image || null;
    }
);


/**
 * Returns settings of activeCompany
 *
 *  @returns {object}
 */
export const getActiveCompanySettings = createSelector(
    [getScopePeer],
    (scopePeer) => {
        if(!scopePeer){
            return;
        }
        return scopePeer.companySelected || {};
    }
);


/**
 * Returns all companies
 *
 *  @returns {object}
 */
export const getAllCompanies = createSelector(
    [getScopePeer],
    (scopePeer) => {
        if(!scopePeer){
            return;
        }
        return scopePeer.companies;
    }
);

/**
 * Returns all active company roles
 *
 *  @returns {array}
 */
export const getCompanyRoles = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return;
        }

        return activeCompany.roles || [];
    }
);

/**
 * Returns an array with auth rights
 *
 *  @returns {array}
 */
export const getPeerAuthByCompany = createSelector(
    [
        getScopePeer,
        getActiveCompanyId
    ],
    (peer, company_id) => {
        if(!peer || !peer?.auth){
            return [];
        }

        return peer?.auth.filter(item=>(item.company_id === company_id || item.company_id === null)) || [];
    }
);

/**
 * Check if peerAuth is initialized
 *
 *  @returns {boolean}
 */
export const peerAuthInitialized = createSelector(
    [
        GetScopePeerAuth,
        getActiveCompanyId
    ],
    (peer, company_id) => {
        if(peer === null){
            return false;
        }

        const rolesForCompany = peer?.filter(item=>(item.company_id === company_id || item.company_id === null)) || []
        return Boolean(rolesForCompany.length || peer.constructor === Array) || false;
    }
);

/**
 * Returns an array with all Companies
 *
 *  @returns {array}
 */
export const getAllCompaniesAsArray = createSelector(
    [getAllCompanies],
    (companies) => {
        if(!companies){
            return;
        }

        let companyArray = [];
        companyArray = Object.keys(companies).map(key => {
            return companies[key]
        });
        return companyArray;
    }
);


/**
 * Check if manager for active company (can be region manager or uppermanager)
 *
 *  @returns {boolean}
 */
export const isManagerForCompany = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return;
        }
        return activeCompany?.roles?.includes(states.STATE_ROLE_MANAGER) || activeCompany?.roles?.includes(states.STATE_ROLE_REGION_MANAGER) || false;
    }
);

/**
 * Check if (upper)manager for active company
 *
 *  @returns {boolean}
 */
export const isUpperManagerForCompany = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return;
        }
        return activeCompany?.roles?.includes(states.STATE_ROLE_MANAGER) || false;
    }
);

/**
 * Check if employee for active company
 *
 *  @returns {boolean}
 */
export const isEmployeeForCompany = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return;
        }
        return activeCompany?.roles?.includes(states.STATE_ROLE_EMPLOYEE) || false;
    }
);

/**
 * Check if driver for active company
 *
 *  @returns {boolean}
 */
export const isDriverForCompany = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return false;
        }

        //Check if user has driver role
        if(activeCompany?.roles?.includes(states.STATE_ROLE_DRIVER)){
            //User has role driver but is still allowed to access portal if it has higher role like employee
            if(activeCompany.role === states.STATE_ROLE_DRIVER){
                return true
            }
        }
        return false
    }
);

/**
 * Check if inactive for active company
 *
 *  @returns {boolean}
 */
export const isInactiveForCompany = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return false;
        }
        return activeCompany?.roles?.includes(states.STATE_ROLE_INACTIVE) || false;
    }
);

/**
 * Check if has Project manager role
 *
 *  @returns {boolean}
 */
export const isProjectManager = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return false;
        }
        return activeCompany?.roles?.includes(states.STATE_ROLE_PROJECT_MANAGER) || false;
    }
);

/**
 * Check if has Project member role
 *
 *  @returns {boolean}
 */
export const isProjectEmployee = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return false;
        }
        return activeCompany?.roles?.includes(states.STATE_ROLE_PROJECT_MEMBER) || false;
    }
);


/**
 * Check is company has access to overload module
 *
 *  @returns {number}
 */
export const getCompanyOverloadModuleRight = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if(!activeCompany){
            return;
        }

        return activeCompany.can_see_overload_module || 0;
    }
);