import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";
import update from "immutability-helper";

const initialState = {
    loading: false,
    error: null,
    data: {},
}

const startLoading = (state) => {
    return updateObject(state, {loading: true});
}

const setError = (state, action) => {
    return updateObject(state, {loading: false, error: action.error});
}

const fetchDataSuccess = (state, action) => {
    return update(state , {$set : {data: action.data, loading: false}});
}

const reset = () => {
    return initialState;
}

const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.FETCH_GPS_START:
        case actionTypes.FETCH_GPS_REQUEST_SAGA : return startLoading(state, action);
        case actionTypes.FETCH_GPS_SUCCESS_SAGA :
        case actionTypes.FETCH_GPS_SUCCESS: return fetchDataSuccess(state, action);
        case actionTypes.CLOSE_GPS: return fetchDataSuccess(state, action);
        case actionTypes.FETCH_GPS_FAILURE_SAGA :
        case actionTypes.FETCH_GPS_FAIL: return setError(state, action);
        case actionTypes.RESET_GPS: return reset(state, action);
        default: return state;
    }
}

export default reducer;