import React from 'react';
import {useField} from 'formik';
import {TextField} from '@mui/material';
import PropTypes from "prop-types";

export default function InputTextArea(props) {

    const {errorText, ...rest} = props;
    const [field, meta] = useField(props);

    function _renderHelperText() {
        if (meta.touched && meta.error) {
            return meta.error;
        }
    }

    return (
        <TextField
            {...field}
            {...rest}
            multiline
            fullWidth
            variant="outlined"
            type="text"
            margin="none"
            disabled={props.disabled}
            error={meta.touched && meta.error && true}
            helperText={_renderHelperText()}
        />
    );
};

InputTextArea.propTypes = {
    disabled: PropTypes.bool.isRequired,
    name: PropTypes.string,
    errorText: PropTypes.string,
};