import React, {useRef, useState} from 'react';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from "../Controls/CloseButton";
import PropTypes from "prop-types";
import ConfirmDialogWithContent from "./ConfirmDialogWithContent";
import i18n from "../../i18n/i18n";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

const PREFIX = 'SimpleDialog';

const classes = {
    dialogTitle: `${PREFIX}-dialogTitle`,
    contentPadding: `${PREFIX}-contentPadding`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.dialogTitle}`]: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },

    [`& .${classes.contentPadding}`]: {
        padding: '12px 24px',
    }
}));

function PaperComponent(props) {
    const nodeRef = useRef(null);

    return (
        <Draggable
            nodeRef={nodeRef}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper ref={nodeRef}{...props} />
        </Draggable>
    );
}

export default function SimpleDialog(props) {
    const {title, handleClose, open, size, fullWidth, fullScreen} = props;
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    // =======================
    // Confirm closing modal
    // =======================
    const openConfirm = () => {
        setOpenConfirmDialog(true);
    }
    const closeConfirm = () => {
        setOpenConfirmDialog(false);
    };

    // ==========================================
    // Handle Dialog actions
    // ==========================================
    const handleConfirmDialogYes = () => {
        closeConfirm()
        handleClose()
    }
    const handleConfirmDialogNo = () => {
        closeConfirm()
    }

    // ConfirmDialog
    const content = i18n.t('UNSAVED_DATE_WILL_BE_LOST')
    const dialogConfirm = <ConfirmDialogWithContent title={i18n.t('TITLE_ARE_YOU_SURE')}
                                                    content={content} open={openConfirmDialog}
                                                    handleClose={closeConfirm} handleNo={handleConfirmDialogNo}
                                                    handleAgreed={handleConfirmDialogYes}/>;

    // ==========================================
    // Dialog
    // ==========================================
    const handleCloseDialog = props.closeConfirmation ? openConfirm : handleClose;
    return (
        <Root>
            {dialogConfirm}
            <Dialog
                className={classes.dialog}
                PaperProps={{
                    style: {
                        overflowY: 'hidden',
                    },
                }}
                PaperComponent={PaperComponent}
                open={open}
                onClose={props.closeConfirmation ? openConfirm : handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={fullWidth}
                fullScreen={fullScreen || false}
                maxWidth={size}>
                <DialogTitle className={classes.dialogTitle} style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                    <CloseIcon handleClose={handleCloseDialog}/>
                </DialogTitle>
                <DialogContent className={classes.contentPadding}>
                    {props.children}
                </DialogContent>
            </Dialog>
        </Root>
    );
}

SimpleDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    open: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['xs', 'xl', 'sm', 'md', 'lg', false]),
    closeConfirmation: PropTypes.bool,
    fullScreen: PropTypes.bool,
    fullWidth: PropTypes.bool,
    scroll: PropTypes.oneOf(['body', 'paper']),
    children: PropTypes.node,
};