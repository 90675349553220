import {Alert, AlertTitle, Snackbar} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import PropTypes from 'prop-types'
import IconButton from "@mui/material/IconButton";

const AlertNotification = (props) => {
    return <Snackbar open={props.open ?? false} autoHideDuration={4000} onClose={props.handleCloseAlert}>
        <div>
            <Alert severity={props.priority}
                   action={
                       <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={props.handleCloseAlert}
                       >
                           <CloseIcon key="close"/>
                       </IconButton>
                   }
            >
                {props.title ? <AlertTitle>{props.title}</AlertTitle> : ''}
                {props.message ?? null}
            </Alert>
        </div>
    </Snackbar>
}

AlertNotification.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    priority: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
    message: PropTypes.string.isRequired,
    handleCloseAlert: PropTypes.func.isRequired
};

export default AlertNotification;