import * as actionTypes from './actionTypes';
import resource from "../../api/axios-gps";
import {setFormAlert} from "./dataTable";

export const fetchDataStart = (index) => {
    return {
        type: actionTypes.FETCH_GPS_START,
        index: index
    }
}

export const fetchDataFail = () => {
    return {
        type: actionTypes.FETCH_GPS_FAIL,
    }
}

export const fetchGpsRequest = (url) => ({
    type: actionTypes.FETCH_GPS_REQUEST_SAGA,
    url,
});

export const fetchGPSData = (url) => {
    return dispatch => {
        dispatch(fetchDataStart());
        resource.get(url, {
            responseType: 'json',
        }).then(response => {
            dispatch(fetchDataSuccess(response.data));
        }).catch(error => {
            dispatch(fetchDataFail(error.message))
            dispatch(setFormAlert(error, 'error', 'GPS coördinaten kunnen niet worden opgehaald'))
        })
    };
}

export const fetchDataSuccess = (data) => {
    return {
        type: actionTypes.FETCH_GPS_SUCCESS,
        data: data,
    }
}

export const closeGPS = () => {
    return {
        type: actionTypes.CLOSE_GPS,
        selected: null
    };
}

export const resetGPS = () => {
    return {
        type: actionTypes.RESET_GPS,
    }
}