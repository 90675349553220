import axios, {InternalAxiosRequestConfig} from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BOA_URL,
    withCredentials: true,
    headers: {
        "Content-Type": `application/json`,
        Accept: `application/json`,
    }
});

instance.interceptors.request.use(
    function (config: InternalAxiosRequestConfig) {
        const token = localStorage.getItem('token')

        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    }, function (err) {
        return Promise.reject(err);
    });
//instance.defaults.withCredentials = true

export default instance;