import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from "prop-types";
import i18n from "../../i18n/i18n";

export default function ConfirmDialogWithContent(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                {props.content}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleNo} variant="outlined" color="primary">
                    {i18n.t('NO')}
                </Button>
                <Button onClick={props.handleAgreed} variant="contained" color="primary" autoFocus>
                    {i18n.t('YES')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}


ConfirmDialogWithContent.propTypes = {
    handleAgreed: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleNo: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
};