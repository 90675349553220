import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import customClasses from '../Auth.module.css';
import Logo from "../../../components/Logo/Logo";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ForgotPasswordComplete from "./ForgotPasswordComplete";
import Loading from "../../../components/Animation/Loading";
import AuthBackButton from "../../../components/Controls/AuthBackButton";
import {requestNewPassword} from "../../../store/actions/resetPassword";
import {authResetLogin} from "../../../store/actions/auth";
import ForgotPasswordError from "./ForgotPasswordError";
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";

export default function ForgotPassword() {
    const dispatch = useDispatch()
    const resetPassword = useSelector(state => {
        return state.resetPassword ?? null
    });
    const [ email, setEmail] = useState("")


    const handleSubmit = event => {
        event.preventDefault()
        dispatch(requestNewPassword(email))
    }

    const backToLogin= () => {
        dispatch(authResetLogin('/'))
        window.location.href='/'
    };

    let render = <ForgotPasswordForm value={email} onChange={(e)=>setEmail(e.target.value)}/>;
    if (resetPassword.loading){
        render = <Loading size={300}/>;
    }
    if(resetPassword.errorState) {
        render = <ForgotPasswordError error={resetPassword.error} onClick={()=>backToLogin()}/>
    }
    if(resetPassword.success) {
        render = <ForgotPasswordComplete onClick={()=>backToLogin()}/>
    }

    return <React.Fragment>
        <form onSubmit={handleSubmit} className={customClasses.AuthData}>
            {/*@todo language selector*/}
            {/*<div style={{right: 20, top: 20, position: 'absolute'}}><LanguageSelector/></div>*/}
            <Logo size={60}/>
            <AuthBackButton title={i18n.t('BACK_TO_LOGIN')}/>
            {render}
        </form>
    </React.Fragment>
}

ForgotPassword.propTypes = {
    value : PropTypes.string,
    onChange : PropTypes.func,
}