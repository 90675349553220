
// =====================
//  Display Button
// =====================
import {Button} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const RegistrationTabButton = (props) => {

        return (
            <Button
                disabled={props.disabled}
                onClick={props.submitHandler}
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                fullWidth
                style={{textTransform: "none"}}>{props.buttonText}
            </Button>
        )
    }

export default RegistrationTabButton;

RegistrationTabButton.propTypes = {
    disabled : PropTypes.bool,
    submitHandler: PropTypes.func,
    buttonText: PropTypes.string,
}