import React, {useEffect, useState} from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";
import {authResetLogin} from "../../store/actions/auth";
import {useDispatch} from "react-redux";
import {IconButton, Tooltip} from "@mui/material";
import i18n from "../../i18n/i18n";

const AuthBackButton = (props) => {

    const dispatch = useDispatch();
    const {color, size, title, labelDirection, href} = props;

    //Default props
    const [stateColor, setStateColor] = useState('#CCCCCC');
    const [stateTitle, setStateTitle] = useState(i18n.t('TOOLTIP_BACK'));
    const [stateSize, setStateSize] = useState('1.5rem');
    const [stateLabelDirection, setStateLabelDirection] = useState('right');
    const [stateHref, setStateHref] = useState('/');

    useEffect(() => {
        if (color) {
            setStateColor(color)
        }
        if (size) {
            setStateSize(size)
        }
        if (title) {
            setStateTitle(title)
        }
        if (labelDirection) {
            setStateLabelDirection(labelDirection)
        }
        if (href) {
            setStateHref(href)
        }
    }, [color, size, title, labelDirection, href])


    const submitHandler = (event) => {
        event.preventDefault();
        dispatch(authResetLogin('/'))
        window.location.href = '/'
    };


    return (
        <Tooltip
            placement={stateLabelDirection}
            title={stateTitle}>
            <IconButton
                aria-label="close"
                onClick={submitHandler}
                sx={{
                    color: stateColor, width: stateSize, height: stateSize,
                    position: 'absolute', left: 22, top: 21
                }}
            >
                <ArrowBackIcon
                />
            </IconButton>
        </Tooltip>
    );
}
export default AuthBackButton;

AuthBackButton.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.string,
    labelDirection: PropTypes.oneOf(['bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-start', 'right-end', 'right', 'top-end', 'top-start', 'top']),
    href: PropTypes.string,
};