import React from "react";
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import {Typography} from "@mui/material";
import PropTypes from "prop-types";

const PREFIX = 'Logo';

const classes = {
    Logo: `${PREFIX}-Logo`,
    LogoHeaderBalk: `${PREFIX}-LogoHeaderBalk`,
    LogoMarginLeft: `${PREFIX}-LogoMarginLeft`,
    SvgLogoColors: `${PREFIX}-SvgLogoColors`,
    SvgLogoTextDark: `${PREFIX}-SvgLogoTextDark`,
    SvgLogoTextLight: `${PREFIX}-SvgLogoTextLight`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.Logo}`]: {
        margin:'auto',
        width:'80%',
        height: '100%',
        boxSizing: 'border-box',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        cursor:'pointer',
        background:'#ffffff',
    },

    [`& .${classes.LogoHeaderBalk}`]: {
        boxSizing: 'border-box',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        cursor:'pointer',
        background:'#ffffff',
        height:'90px',
        borderRadius:'0 0 20px 20px',
        boxShadow: '0px 5px 10px rgb(0 0 0 / 20%)',
        marginTop: '-15px',
        padding: '27px 15px',
        position:'absolute',
        top:'0',
    },

    [`& .${classes.LogoMarginLeft}`]: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: '220px'
    },

    [`& .${classes.SvgLogoColors}`]: {
        fill:"#ee710d",
        opacity:'0.65',
        mixBlendMode: 'multiply'
    },

    [`& .${classes.SvgLogoTextDark}`]: {
        fontSize: "36.21242904663086px",
        fill: "#233a56",
        fontFamily: "DaxMedium",
    },

    [`& .${classes.SvgLogoTextLight}`]: {
        fontSize: "36.21242904663086px",
        fill: "#233a56",
        fontFamily: "DaxLight",
    }
}));

export default function Logo(props) {


    const className = clsx(classes.Logo, {
        [classes.LogoMarginLeft]: props.leftDrawerOpened,
    });

    return (
        <Root className={props.class ? props.class: className}>
            {/*<img draggable={false} src={logoImage} height={props.size ?? 38} alt="Logo" onClick={props.onClick}/>*/}

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.35 42.58"><title>Formklub</title>
                <g style={{isolation: "isolate"}}>
                    <g id="FormKlub_logo" data-name="FormKlub logo">
                        <path d="M38.17,29.88a3.38,3.38,0,0,1-3.41,3.35L9.93,33.17a3.4,3.4,0,0,1-3.36-3.42l.14-14.11a3.39,3.39,0,0,1,3.43-3.35L35,12.35a3.38,3.38,0,0,1,3.35,3.42Z" className={classes.SvgLogoColors}/>
                        <path d="M44.07,35a3.37,3.37,0,0,1-3.42,3.35l-24.82-.06a3.4,3.4,0,0,1-3.36-3.42l.15-14.11A3.38,3.38,0,0,1,16,17.37l24.82.06a3.4,3.4,0,0,1,3.36,3.42Z" className={classes.SvgLogoColors}/>
                        <path d="M31.6,25.26a3.4,3.4,0,0,1-3.42,3.36L3.35,28.55A3.38,3.38,0,0,1,0,25.13L.14,11A3.4,3.4,0,0,1,3.56,7.67l24.83.07a3.38,3.38,0,0,1,3.35,3.42Z" className={classes.SvgLogoColors}/>
                        <path d="M18.81,8.44a3.39,3.39,0,0,1,3.42-3.36l24.83.06a3.38,3.38,0,0,1,3.35,3.42l-.14,14.11A3.38,3.38,0,0,1,46.85,26L22,26a3.4,3.4,0,0,1-3.36-3.42Z" className={classes.SvgLogoColors}/>
                        <path d="M12.91,3.35A3.38,3.38,0,0,1,16.33,0L41.16.06a3.38,3.38,0,0,1,3.35,3.42l-.14,14.11A3.38,3.38,0,0,1,41,20.94l-24.82-.06a3.4,3.4,0,0,1-3.36-3.42Z" className={classes.SvgLogoColors}/>
                        <path d="M25.39,13.05A3.38,3.38,0,0,1,28.81,9.7l24.83.06A3.38,3.38,0,0,1,57,13.18l-.14,14.11a3.38,3.38,0,0,1-3.42,3.35L28.6,30.58a3.38,3.38,0,0,1-3.35-3.42Z" className={classes.SvgLogoColors}/>
                        <text transform="translate(62.76 31.36)" pointerEvents="none" style={{userSelect:'none'}}>
                            <tspan className={classes.SvgLogoTextDark} style={{letterSpacing: "-0.010989299233077116em"}}>F</tspan>
                            <tspan className={classes.SvgLogoTextDark} x="15.64" y="0" >orm</tspan>
                            <tspan className={classes.SvgLogoTextLight} x="78.62" y="0" >Klub</tspan>
                        </text>
                    </g>
                </g>
            </svg>
            {process.env.REACT_APP_ENV !== 'Production' ? <Typography sx={{textAlign:'center', fontWeight:'700', marginTop:'-10px', pointerEvents: 'none', userSelect: 'none'}} color="error" variant="body1">{process.env.REACT_APP_ENV}!</Typography>:''}
        </Root>
    );
}

Logo.propTypes = {
    leftDrawerOpened : PropTypes.bool,
    className: PropTypes.string,
    class : PropTypes.string,
}