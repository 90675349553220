import React from 'react';
import { Navigate } from 'react-router-dom'
import PropTypes from "prop-types";

export const AuthRoute = ({ component: Component, isAuthorized: isAuthorized}) => {
    if (!isAuthorized) {
        // not logged in so redirect to login page with the return url
        return <Navigate to={{pathname: '/'}}/>
    }

    // authorised so return component
    return Component;
}

AuthRoute.propTypes = {
    component : PropTypes.element,
    isAuthorized : PropTypes.bool.isRequired,
}