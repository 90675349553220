import {createSelector} from "reselect";
import {getActiveCompany} from "./companySelector";
import {getScopePeer} from "./scopeSelector";
import {states} from "../../constants/States";

/**
 * Return all available Regions under activeCompany in array
 *
 *  @returns {array}
 */
export const getAvailableRegions = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if (!activeCompany) {
            return;
        }
        let regions = [];
        Object.keys(activeCompany.regions).map((UUID) => {
            regions.push(activeCompany.regions[UUID])
        })
        return regions;
    }
);


/**
 * Return All Selected regions by UUID
 *
 *  @returns {object}
 */
export const getStateSelectedRegions = createSelector(
    [getScopePeer],
    (peer) => {
        if (!peer) {
            return;
        }
        return peer.regionsSelected;
    }
);

/**
 * Return All projects by UUID
 *
 *  @returns {object}
 */
export const getStateSelectedProjects = createSelector(
    [getScopePeer],
    (peer) => {
        if (!peer) {
            return;
        }
        return peer?.projectsSelected;
    }
);

/**
 * Return all available Regions under activeCompany by UUID
 *
 *  @returns {object}
 */
export const getActiveCompanyRegionsObject = createSelector(
    [getActiveCompany],
    (activeCompany) => {
        if (!activeCompany) {
            return;
        }

        return activeCompany.regions;
    }
);

/**
 * Get active regions
 *
 *  @returns {array}
 */
export const getActiveRegions = createSelector(
    [getStateSelectedRegions],
    (selectedRegions) => {
        if (!selectedRegions) {
            return;
        }
        const regionArray = [];
        Object.keys(selectedRegions).map(key => {
            if (selectedRegions[key]) {
                regionArray.push(selectedRegions[key]);
            }
        });
        return regionArray;
    }
);

/**
 * Check if transport supervisor for region
 *
 *  @returns {boolean}
 */
export const isTransportSupervisorForRegion = createSelector(
    [getActiveRegions],
    (activeRegion) => {
        if(!activeRegion){
            return;
        }

        if(activeRegion.length === 1 && activeRegion[0].roles?.length === 1 && activeRegion[0].roles.includes(states.STATE_ROLE_TRANSPORT_SUPERVISOR)){
            return true;
        }
        return false;
    }
);