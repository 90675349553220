
import {I18n} from "i18n-js";
import { nl, en } from "./supportedLanguages";

const i18n = new I18n();

i18n.enableFallback = true;
i18n.translations = { nl, en };
i18n.locale = 'nl'

//@todo enable later when all have translations are complete
// const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
// i18n.locale = browserLanguage;

export default i18n;
