
import React from "react";
import {Button, Grid, Typography} from "@mui/material";
import customClasses from '../Auth.module.css';
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";

export default function ResetPasswordError(props) {


    return(
            <Grid className={customClasses.contentSpacing} container alignItems="flex-end" justifyContent="flex-end">
                <Grid item xs={12}>
                    <Typography align='center' variant="h6">
                        {props.error}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <br/>
                    <Button
                        onClick={props.onClick}
                        variant="contained"
                        color="primary"
                        size="medium"
                        fullWidth
                        style={{ textTransform: "none" }}>{i18n.t('BACK_TO_LOGIN')}
                    </Button>
                </Grid>
            </Grid>
    )
}

ResetPasswordError.propTypes = {
    error : PropTypes.string,
    onClick : PropTypes.func.isRequired,
}