export const states = {
    STATE_ROLE_DRIVER: 'driver',
    STATE_ROLE_TRANSPORT_SUPERVISOR: 'transportSupervisor',
    STATE_ROLE_ADMIN: 'admin',
    STATE_ROLE_MANAGER: 'manager',
    STATE_ROLE_REGION_MANAGER: 'regionManager',
    STATE_ROLE_REGION_MEMBER: 'regionMember',
    STATE_ROLE_EMPLOYEE: 'employee',
    STATE_ROLE_INACTIVE: 'inactive',
    STATE_ROLE_PROJECT_MANAGER: 'projectManager',
    STATE_ROLE_PROJECT_MEMBER: 'projectMember',
    STATE_ROLE_TEMPLATE_MANAGER: 'templateManager',
    STATE_ROLE_TEMPLATE_MEMBER: 'templateMember',
    STATE_ROLE_WAYBILL_MANAGER: 'waybillManager',
    STATE_ROLE_WAYBILL_MEMBER: 'waybillMember',
    STATE_ROLE_EXTERNAL: 'external',

    STATE_TEMPLATE_RECEIVED: '1',
    STATE_TEMPLATE_CREATED: '10',
    STATE_TEMPLATE_BUSY: '30',
    STATE_TEMPLATE_TEMP_CLOSED: '80',
    STATE_TEMPLATE_FINISHED: '50',
    STATE_TEMPLATE_DELETED: '99',
    STATE_TEMPLATE_REJECTED: '90',

    STATE_ACTIVE: '10',
    STATE_INACTIVE: '90',
    DEVICE_CONDITION_REFERENCE_REQUIRED: 'reference_required',
    DEVICE_CONDITION_REFERENCE_NOTE: 'reference_note',
};