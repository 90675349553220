import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from "prop-types";
import i18n from "../../i18n/i18n";

export default function ConfirmDialog(props) {
    return (
        <Dialog
            maxWidth="sm"
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogActions>
                <Button onClick={props.handleClose} variant="outlined" color="primary">
                    {i18n.t('NO')}
                </Button>
                <Button onClick={props.handleAgreed} variant="contained" color="primary" autoFocus>
                    {i18n.t('YES')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmDialog.propTypes = {
    handleAgreed: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired
};