import React, {useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import {FixedSizeList as List} from "react-window";
import Grid from "@mui/material/Grid";
import SearchBar from './../../../Controls/SearchBar'
import PropTypes from "prop-types";
import axios from "../../../../api/axios-peer";
import {fetchDataFail} from "../../../../store/actions/dataTable";
import {useDispatch, useSelector} from "react-redux";
import WindowDimensions from "../../../WindowDimensions/WindowDimensions";
import {Typography} from "@mui/material";
import {getScopeFormklub} from "../../../../store/selectors/scopeSelector";

const PREFIX = 'SearchEuralDialog';

const classes = {
    rowEven: `${PREFIX}-rowEven`,
    rowOdd: `${PREFIX}-rowOdd`,
    emptyContainer: `${PREFIX}-emptyContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
    [`& .${classes.rowEven}`]: {
        background: "#F5F5F5",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        cursor: 'pointer',
        "& .MuiSvgIcon-root": {color: "rgba(0, 0, 0, 0.54)",},
        '&:hover': {
            backgroundColor: '#D2D8DD',
            "& .MuiSvgIcon-root": {color: "#203A56",},
        },
    },

    [`& .${classes.rowOdd}`]: {
        background: "#FFFFFF",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        cursor: 'pointer',
        "& .MuiSvgIcon-root": {color: "rgba(0, 0, 0, 0.54)",},
        '&:hover': {
            backgroundColor: '#D2D8DD',
            "& .MuiSvgIcon-root": {color: "#203A56",},
        }
    },

    [`& .${classes.emptyContainer}`]: {
        background: "#F5F5F5",
        padding: '20px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        height: '100px',
        marginTop: '20px',
    }
}));

export default function SearchEuralDialog(props) {

    const dispatch = useDispatch();

    const [searchInput, setSearchInput] = useState('');
    const [emptylist, setEmptyList] = useState(false);
    const [parentCode, setParentCode] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const resourceFormklub = useSelector(state => getScopeFormklub(state))
    let {height, width} = WindowDimensions();

    // ======================
    // Get Data
    // ======================
    useEffect(() => {
        let isMounted = true;
        axios({
            url: 'eural', baseURL: resourceFormklub.resource?.defaults.baseURL,
        }).then(response => {
            if (isMounted) setData(response.data);
        }).catch(error => {
            dispatch(fetchDataFail(error.message))
        })
        return () => {
            isMounted = false
        };
    }, [])

    // =====================
    // Handle Selected Company
    // =====================
    const onItemClickHandler = (selectedEural) => {

        //Set selected company
        const code = selectedEural.code
        setParentCode(code)

        if (code.length > 5) {
            props.handleEuralSelected(selectedEural)

            //Reset
            setFilteredData(data);
            setEmptyList(false)
            setParentCode(null)
        }
    }

    // =====================
    // Clear filter
    // =====================
    const clearFilter = () => {
        setFilteredData(data);
        setEmptyList(false)
    }

    // =====================
    // Handle Search
    // =====================
    const handleChange = (searchInput) => {

        setSearchInput(searchInput);

        if(!data.length){
            return;
        }
        //Filtered data
        const result = data.filter((item) => {
            return item.code.includes(searchInput) && item.parent_code === parentCode;
        });
        setFilteredData(result);
    }

    const parentHandler = (code) => {
        const result = data.filter((item) => {
            return item.parent_code === code;
        });
        setFilteredData(result);

    }

    // =====================
    // Check if list is empty
    // =====================
    useEffect(() => {
        if (filteredData === undefined) {
            return
        }
        if (filteredData.length === 0 && searchInput.length > 0) {
            setEmptyList(true);
        } else {
            setEmptyList(false);
        }
    }, [searchInput])


    // =====================
    // Filtered array
    // =====================
    useEffect(() => {
        if (data === null || data === undefined) {
            return
        }
        const result = data.filter((item) => {
            return item.parent_code === null;
        });
        setFilteredData(result);
    }, [data])


    useEffect(() => {
        if (filteredData === undefined || !data) {
            return
        }
        parentHandler(parentCode)
    }, [parentCode])


    // =====================
    // Company rows
    // =====================
    const list = filteredData
    const renderRow = ({index, style}) => (
        <div>
            <div key={list[index]} style={style} className={index % 2 === 0 ? classes.rowEven : classes.rowOdd}
                 onClick={onItemClickHandler.bind(this, list[index])}>
                <Typography variant="body1"><strong>Code:</strong> {list[index].code}</Typography>
                <Typography variant="body1"><strong>Omschrijving:</strong> {list[index].description}</Typography>
            </div>
        </div>
    );

    return (
        <Root>
            <Grid container>
                <Grid item xs={12}>
                    <SearchBar
                        clearFilter={clearFilter}
                        handleChange={handleChange}
                    />
                </Grid>
            </Grid>
            {
                emptylist ? (
                    <div>
                        <div className={classes.emptyContainer}>
                            <span>Niks gevonden</span><br/>
                        </div>
                    </div>
                ) : (
                    <List
                        height={700}
                        itemCount={list ? list.length : null}
                        itemSize={width < 768 ? 150 : 100}
                    >
                        {renderRow}
                    </List>
                )
            }
            <br/>
        </Root>
    );
}

SearchEuralDialog.propTypes = {
    handleEuralSelected: PropTypes.func.isRequired,
    data: PropTypes.array
};