import React from 'react';
import {FastField, useField} from "formik";
import customClasses from "./../TemplateForm.module.css";
import {FormHelperText} from "@mui/material";
import PropTypes from "prop-types";

const RadioButton = (props) => {
    const {label, name, options, ...rest} = props;
    const [field, meta] = useField(props);

    return (
        <React.Fragment>
            <FastField name={name} error={meta.touched && meta.error && true} {...rest} >
                {
                    ({field}) => {
                        return options.map(option => {
                            const optionValueNumber = Number(option.value)
                            const fieldValueNumber = Number(field.value)

                            return (
                                <React.Fragment key={option.value}>
                                    <input
                                        className={customClasses.templateRadio}
                                        type='radio'
                                        id={option.labelNumber + "-" + name}
                                        {...field}
                                        value={option.value}
                                        checked={fieldValueNumber === optionValueNumber}
                                        disabled={props.disabled}
                                        onChange={()=>props.setFieldValue(props.name, optionValueNumber)}
                                    />
                                    <label className={customClasses.radioButtonLabel}
                                           htmlFor={option.value}>
                                        {props.labelNumbers ? `${option.labelNumber}. ${option.label}` : option.label}
                                    </label>
                                </React.Fragment>
                            );
                        })
                    }
                }
            </FastField>
            <FormHelperText error={meta.touched}>{meta.touched ? meta.error : ''}</FormHelperText>
        </React.Fragment>
    );
};

export default RadioButton;

RadioButton.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    labelNumbers: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};