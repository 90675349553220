import React, {useEffect} from "react";
import {Grid, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";

export default function RegistrationTabAccount(props) {

    //Check so user can not go further until password is strong enough
    useEffect(() => {
        const regexPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/
        const valid = regexPattern.test(props.password.value);
        if (!valid) {
            props.handleError(4, true);
        } else {
            props.handleError(4, false);
        }
    }, [props.password.value])

    return (
        <div>
            <Typography align='left' variant="h6">{i18n.t('ACCOUNT_DATA')}</Typography>
            <Grid container alignItems="flex-end" justifyContent="flex-end">
                <Grid item xs={12}>
                    <TextField
                        id="tabAccount-inputEmail"
                        label={i18n.t('EMAIL_ADDRESS')}
                        type="email"
                        name="email"
                        margin="normal"
                        autoFocus
                        fullWidth
                        variant="outlined"
                        required
                        error={Boolean(props.email.error.length)}
                        value={props.email.value}
                        onChange={props.inputChangeEmail}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="tabAccount-inputPassword"
                        label={i18n.t('PASSWORD')}
                        type={props.showPassword ? 'text' : 'password'}
                        value={props.password.value}
                        onChange={props.inputChangePassword}
                        error={Boolean(props.password.error.length)}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                        helperText={i18n.t('REQUIREMENTS_PASSWORD')}
                        inputProps={{
                            title: i18n.t('REQUIREMENTS_PASSWORD')
                        }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={props.handleClickShowPassword}
                                        onMouseDown={props.handleMouseDownPassword}
                                        size="large">
                                        {props.showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>

                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

RegistrationTabAccount.propTypes = {
    password: PropTypes.object.isRequired,
    showPassword: PropTypes.bool.isRequired,
    email: PropTypes.object.isRequired,
    handleError: PropTypes.func.isRequired,
    inputChangeEmail: PropTypes.func.isRequired,
    inputChangePassword: PropTypes.func.isRequired,
    handleClickShowPassword: PropTypes.func.isRequired,
    handleClickMouseDownPassword: PropTypes.func,
    handleMouseDownPassword: PropTypes.func
}