import React from "react";
import {Avatar, Button, Grid, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {Cancel, Visibility, VisibilityOff} from "@mui/icons-material";
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";

export default function LoginPassword(props) {

    return (
        <React.Fragment>
            <Grid container spacing={4} alignItems="flex-end">
                <Grid item md={true} sm={true} xs={true}>
                    <Avatar>{props.email.substring(0, 2).toUpperCase()}</Avatar>
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                    <Typography style={{marginTop: '5px'}} variant="subtitle1">{props.email}</Typography>
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                    <IconButton aria-label="Cancel login" onClick={props.toggleLoginAccount} size="large">
                        <Cancel />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container spacing={4} alignItems="flex-end">
                <Grid item md={true} sm={true} xs={true}>
                    <br/>
                    <TextField
                        id="password"
                        label={i18n.t('PASSWORD')}
                        type={props.showPassword ? 'text' : 'password'}
                        value={props.password}
                        onChange={props.handlePasswordChange}
                        autoFocus
                        inputProps={{
                            autoComplete: 'new-password',
                        }}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={props.handleClickShowPassword}
                                        onMouseDown={props.handleMouseDownPassword}
                                        size="large">
                                        {props.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>

                        }}
                        fullWidth
                        required
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                <Button
                    onClick={props.handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={props.loading}
                    fullWidth
                    style={{ textTransform: "none" }}>{props.loading ? i18n.t('LOGGING_IN') : "Login"}</Button>
            </Grid>
        </React.Fragment>
    );
}

LoginPassword.propTypes = {
    email : PropTypes.string.isRequired,
    toggleLoginAccount : PropTypes.func.isRequired,
    showPassword : PropTypes.bool.isRequired,
    password : PropTypes.string.isRequired,
    handlePasswordChange : PropTypes.func.isRequired,
    handleClickShowPassword : PropTypes.func.isRequired,
    handleMouseDown : PropTypes.func,
    handleMouseDownPassword: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
}