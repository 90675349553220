import React, {Component} from "react";
import customClasses from './Registration.module.css';
import {compose} from "redux";
import Logo from "../../../components/Logo/Logo";
import Loading from "../../../components/Animation/Loading";
import AuthBackButton from "../../../components/Controls/AuthBackButton";
import AlertNotification from "../../../components/Controls/AlertNotification";
import RegistrationTab from "./RegistrationTab";
import RegistrationComplete from "./RegistrationComplete";
import {connect} from "react-redux";
import * as register from '../../../store/actions/register'
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";

class Registration extends Component {
    state = {
        tabStateFind: true,
        tabStateAddress: false,
        tabStateInvoiceAddress: false,
        tabStateAccount: false,
        tabStateContact: false,
        tabStateTemplate: false,
        showPassword: false,
        registrationComplete: false,
        valueTab: 0,
        tabFind: {
            inputBedrijf: {value: '', error: '', required: true},
            inputVIHB: {value: null, error: '', required: true},
        },
        tabAccount: {
            inputPassword: {value: '', error: '', required: true},
            inputEmail: {value: '', error: '', required: true},
        },
        tabAddress: {
            inputCompanyName: {value: ''},
            inputCompanyVihb: {value: null},
            inputAddress: {value: '', error: '', required: true},
            inputHouseNumber: {value: '', error: '', required: true},
            inputHouseNumberAddition: {value: '', error: '', required: false},
            inputZipCode: {value: '', error: '', required: true},
            inputPlace: {value: '', error: '', required: true},
            inputCountry: {value: 'Nederland', error: '', required: true},
        },
        tabInvoiceAddress: {
            inputSwitchCopyAddress: {value: false, error: '', required: false},
            inputAddress: {value: '', error: '', required: false},
            inputHouseNumber: {value: '', error: '', required: false},
            inputHouseNumberAddition: {value: '', error: '', required: false},
            inputZipCode: {value: '', error: '', required: false},
            inputPlace: {value: '', error: '', required: false},
            inputCountry: {value: '', error: '', required: false},
        },
        tabContact: {
            inputFirstName: {value: '', error: '', required: true},
            inputInsertion: {value: '', error: '', required: false},
            inputLastName: {value: '', error: '', required: true},
            inputPhone: {value: '', error: '', required: true},
        },
    }

    // =====================
    // Toggle show Password
    // =====================
    handleClickShowPassword = () => {
        this.setState({...this.state, showPassword: !this.state.showPassword});
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // =====================
    // Handle selecting Company by vihb or name
    // =====================
    handleCompanySelected = (selectedCompany) => {
        const info = {
            ...this.state.tabAddress,
            inputCompanyName: { value: selectedCompany.name },
            inputCompanyVihb: { value: selectedCompany.vihb_full },
            inputAddress: { value: selectedCompany.street_name, error: '' },
            inputHouseNumber: { value: selectedCompany.building_number, error: '' },
            inputHouseNumberAddition: { value: selectedCompany.building_number_addition, error: '' },
            inputZipCode: { value: selectedCompany.postal_zone, error: '' },
            inputPlace: { value: selectedCompany.city_name, error: '' },
            inputCountry: { value: selectedCompany.country_name, error: '' },
        };

        this.setState((prevState) => ({
            ...prevState,
            tabAddress: info,
        }));
    };

    // ========================
    // Submit registration form
    // ========================
    submitHandler = (event) => {
        event.preventDefault();

        //Complete registration
        if (this.state.valueTab === 5) {
            this.setState({...this.state, registrationComplete: true});
            this.props.submitRegistration(this.state)
        }
    }

    // =====================
    // NO VIHB
    // =====================
    skipVihbSearch = (event) => {
        event.preventDefault();
        this.setState({...this.state, tabStateAddress: true, valueTab: 1});
    }

    // =====================
    // Copy address to invoiceaddress
    // =====================
    copyAddressToInvoiceAddress = (event) => {

        const checkbox = event.target.checked;
        let address = this.state.tabAddress;
        let empty = false;

        if (!checkbox) {
            empty = true;
        }

        const info = {
            ...this.state.tabAddress,
            inputSwitchCopyAddress: {value: checkbox, error: ''},
            inputAddress: !empty ? address.inputAddress : {value: '', error: ''},
            inputHouseNumber: !empty ? address?.inputHouseNumber : {value: '', error: ''},
            inputHouseNumberAddition: !empty ? address?.inputHouseNumberAddition : {value: '', error: ''},
            inputZipCode: !empty ? address?.inputZipCode : {value: '', error: ''},
            inputPlace: !empty ? address?.inputPlace : {value: '', error: ''},
            inputCountry: !empty ? address?.inputCountry : {value: '', error: ''},
        }

        this.setState({...this.state, tabInvoiceAddress: info})
    }


    render() {
        // ============================
        // Handle changing between tabs
        // ============================
        const handleChangeTab = (event, newValue) => {

            //Extra check if user tried to break login form by going back and empty input fields.
            //=================================
            let stop = false;
            let inputArray;
            const oldValue = this.state.valueTab;
            const getTabName = handleActiveTab(this.state.valueTab, newValue);

            if (this.state.valueTab < 5) {
                inputArray = Object.values(this.state[getTabName]);
                inputArray.slice(0, inputArray.length).map((element) => {
                    if (!element.value && element.error && element.required) {
                        stop = true;
                    }
                    return stop;
                });
            }
            //== End extra check

            // Go to selected tab if allowed
            if ((newValue < oldValue || !stop) && (this.state.valueTab <= 5)) {
                this.setState({...this.state, valueTab: newValue});
            }
        };

        // =====================
        // Button Click
        // =====================
        const onClickNextTabButton = (event) => {
            event.preventDefault();
            if (this.state.valueTab < 5) {
                this.setState((prevState) => ({
                    ...prevState,
                    valueTab: prevState.valueTab + 1
                }));
            }
            //Set active tab
            handleActiveTab(this.state.valueTab);
        }

        // =======================
        // Handle on change input
        // =======================
        const handleInputChange = () => (event, valueAutoComplete, field) => {

            const target = event.target;
            const split = target.id.split('-');
            const activeTab = split[0]
            const fieldId = split[1]
            let error = '';
            let newValue;

            // Set value depending on source. Source can be textfield or autocomplete field
            if (valueAutoComplete && typeof valueAutoComplete != "undefined") {
                newValue = valueAutoComplete;
            } else {
                newValue = target.value;
            }

            //Extra check if value is set cannot be undefined
            if (typeof newValue === "undefined" || newValue === "") {
                newValue = "";
            }

            //Error message if input is empty
            if (!newValue) {
                error = `Field cannot be empty`
            }
            if (fieldId === 'inputPassword') {
                const regexPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
                const valid = regexPattern.test(newValue);
                if (!valid) {
                    error = 'not valid';
                    this.setState((prevState) => ({
                        ...prevState,
                        tabAccount: {
                            ...prevState.tabAccount,
                            inputPassword: {
                                ...prevState.tabAccount.inputPassword,
                                value: newValue,
                                required: true,
                                error: error,
                            },
                        }
                    }));
                }
            }

            if (valueAutoComplete && fieldId !== 'inputSwitchCopyAddress') {
                const newVIHBState = {
                    ...this.state.tabFind.inputVIHB,
                    value: newValue.vihb_full,
                    required: false,
                };

                const newBedrijfState = {
                    ...this.state.tabFind.inputBedrijf,
                    value: newValue.name,
                    required: false,
                };

                this.setState((prevState) => ({
                    ...prevState,
                    tabFind: {
                        ...prevState.tabFind,
                        inputVIHB: newVIHBState,
                        inputBedrijf: newBedrijfState,
                    },
                }));

            } else {
                if (field === 'inputBedrijf' && !valueAutoComplete) {
                    //Empty fields
                    const newVIHBState = {
                        ...this.state.tabFind.inputVIHB,
                        value: null,
                        error: '',
                        required: true,
                    };

                    const newBedrijfState = {
                        ...this.state.tabFind.inputBedrijf,
                        value: '',
                        error: '',
                        required: true,
                    };

                    this.setState((prevState) => ({
                        ...prevState,
                        tabFind: {
                            ...prevState.tabFind,
                            inputVIHB: newVIHBState,
                            inputBedrijf: newBedrijfState,
                        },
                    }));
                } else {
                    this.setState((prevState) => ({
                        ...prevState,
                        [activeTab]: {
                            ...prevState[activeTab],
                            [fieldId]: {
                                ...prevState[activeTab][fieldId],
                                value: newValue,
                                error: error,
                            },
                        },
                    }));
                }
            }
        };

        // =========================
        // Handle states active tabs
        // =========================
        const handleActiveTab = (currentStateValue) => {
            let tabState;
            let tabName;
            switch (currentStateValue) {
                default:
                    tabState = "tabStateFind";
                    tabName = "tabFind";
                    break;
                case 0:
                    tabState = "tabStateFind";
                    tabName = "tabFind";
                    break;
                case 1:
                    tabState = "tabStateAddress";
                    tabName = "tabAddress";
                    break;
                case 2:
                    tabState = "tabStateInvoiceAddress";
                    tabName = "tabInvoiceAddress";
                    break;
                case 3:
                    tabState = "tabStateContact";
                    tabName = "tabContact";
                    break;
                case 4:
                    tabState = "tabStateAccount";
                    tabName = "tabAccount";
                    break;
                case 5:
                    tabState = "tabStateTemplate";
                    tabName = "tabTemplate";
                    break;
            }

            if (!this.state[tabState]) {
                this.setState((prevState) => ({
                    ...prevState,
                    [tabState]: true
                }));
            }

            return tabName
        }

        // Set classes for Tabs
        let tabClasses = `${customClasses.onboardingTabs}`;
        const getTabClasses = (tab) => {
            if (this.state[tab]) {
                tabClasses = `${customClasses.active} ${customClasses.onboardingTabs}`;
            } else {
                tabClasses = `${customClasses.onboardingTabs}`;
            }
            return tabClasses
        }

        // ==========================
        // Registration form
        // ==========================
        const onboarding =
            <RegistrationTab
                copyAddress={this.copyAddressToInvoiceAddress}
                skipVihbSearch={this.skipVihbSearch}
                nextTabHandler={onClickNextTabButton}
                submitHandler={this.submitHandler}
                handleChangeTab={handleChangeTab}
                handleInputChange={handleInputChange}
                handleClickShowPassword={this.handleClickShowPassword}
                handleMouseDownPassword={this.handleMouseDownPassword}
                getTabClasses={getTabClasses}
                showPassword={this.state.showPassword}
                stateValueTab={this.state.valueTab}
                stateTabFind={this.state.tabStateFind}
                handleCompanySelected={this.handleCompanySelected}
                stateTabAddress={this.state.tabStateAddress}
                stateTabInvoiceAddress={this.state.tabStateInvoiceAddress}
                stateTabContact={this.state.tabStateContact}
                stateTabAccount={this.state.tabStateAccount}
                stateTabTemplate={this.state.tabStateTemplate}
                tabFind={this.state.tabFind}
                tabAddress={this.state.tabAddress}
                tabInvoiceAddress={this.state.tabInvoiceAddress}
                tabContact={this.state.tabContact}
                tabAccount={this.state.tabAccount}
            />

        // =====================
        // Building Page
        // =====================

        return (

            <div className={customClasses.registrationContainer}>
                {/*@todo language selector*/}
                {/*<div style={{right: 20, top: 20, position: 'absolute'}}><LanguageSelector/></div>*/}
                <Logo size={60}/>
                <AuthBackButton title={i18n.t('BACK_TO_LOGIN')}/>
                {
                    //Loading
                    this.props.loading ?
                        <Loading size={300}/>
                        :
                        //Registration complete
                        this.props.success ?
                            <RegistrationComplete/>
                            :
                            //Registration form
                            onboarding
                }
                <AlertNotification
                    handleCloseAlert={this.props.handleCloseAlert}
                    message={this.props.error || 'Error'}
                    open={this.props.errorState}
                    priority={"error"}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.reg.loading,
        error: state.reg.error,
        errorState: state.reg.errorState,
        success: state.reg.success,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // Dispatching plain actions
        submitRegistration: (state) => dispatch(register.RegisterUserSubmit({
            'user': state.tabContact,
            'company': state.tabAddress,
            'invoice': state.tabInvoiceAddress,
            'account': state.tabAccount,
            'find': state.tabFind
        })),
        handleCloseAlert: () => dispatch(register.HandleCloseIcon(false)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(Registration);


Registration.propTypes = {
    submitRegistration : PropTypes.func.isRequired,
    handleCloseAlert: PropTypes.func.isRequired,
    error: PropTypes.string,
    errorState: PropTypes.bool,
    success: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
}