import {FormControl, Grid, TextField, Typography} from "@mui/material";
import customClasses from "./Check.module.css";
import Button from "@mui/material/Button";
import React from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import {setCommentData} from "../../../../store/actions/templateAudit";
import {useDispatch} from "react-redux";
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from "prop-types";

const validationSchema = yup.object({
    comment: yup
        .string()
        .nullable(),
    template_id: yup
        .number()
        .required('Template id is verplicht'),
    template_auditor_id: yup
        .number()
        .required('Template auditor id is verplicht'),
    is_approved: yup
        .boolean()
        .required('Goedgekeurd is verplicht')
});

export default function CommentForm(props) {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            comment: '',
            template_id: props.template_id,
            template_auditor_id: props.template_auditor_id,
            is_approved: 0
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(setCommentData(values));
        }
    });

    const onApprovedClick = () => {
        formik.setFieldValue('is_approved', 1);
        formik.submitForm();
    }

    const onDeniedClick = () => {
        formik.setFieldValue("is_approved", 0);
        formik.submitForm();
    }

    return <FormControl fullWidth>
        <TextField
            label="Opmerkingen"
            id="outlined-size-normal"
            placeholder="Type hier uw opmerking..."
            variant="outlined"
            name="comment"
            multiline
            minRows={5}
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.comment || ""}
            error={formik.touched.comment && Boolean(formik.errors.comment)}
            helpertext={formik.touched.comment && formik.errors.comment}
        />
            <Grid container spacing={3} className={customClasses.buttonContainer}>
                <Grid item xs={12} md={6}>
                    <Button
                        variant="contained"
                        color="error"
                        fullWidth
                        className={customClasses.button}
                        onClick={() => onDeniedClick()}>
                        Afkeuren
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        className={customClasses.button}
                        onClick={() => onApprovedClick()}>
                        Goedkeuren
                    </Button>
                </Grid>
            </Grid>

            <div className={customClasses.mobileScrollInfo}>
                <InfoIcon/><Typography style={{paddingLeft:'10px'}} variant='caption'> Scroll naar beneden om de template te bekijken</Typography>
            </div>
    </FormControl>
}

CommentForm.propTypes = {
    template_id : PropTypes.number,
    template_auditor_id : PropTypes.number,
}