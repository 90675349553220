
import React from "react";
import {LinearProgress} from "@mui/material";

const LoadingBar = () => {
    return(
        <LinearProgress
            style={{
                zIndex: 99,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                width: "100%",
                height: "4px",
                position: 'absolute'
            }}/>
    )
}
export default LoadingBar;

// Loading.propTypes = {
//
// };