// sagas.js
import {put, call, takeLatest} from 'redux-saga/effects';
import {
    FETCH_DATA_REQUEST_SAGA,
    FETCH_DATA_FAILURE_SAGA,
    FETCH_DATA_SUCCESS_SAGA,
    FETCH_TABS_REQUEST_SAGA,
    FETCH_TABS_SUCCESS_SAGA,
    FETCH_TABS_FAILURE_SAGA,
    SET_LOADING, SET_TAB_REQUEST_SAGA, SET_TAB_SUCCESS_SAGA, SET_TAB_FAILURE_SAGA, STOP_LOADING,
} from "../actions/actionTypes";
import axios from "../../api/axios-peer";

function* fetchDataSaga(action) {
    try {
        const {
            url,
            index,
            numberOfRow,
            page,
            sortOrder,
            urlAddition,
            searchParams,
            activeTabFilter,
            resource,
            callback,
            routeFilter
        } = action;

        yield put({type: SET_LOADING, index});

        // Construct the URL with the query parameters
        let fullUrl = url;

        let questionmarked = url.includes("?");

        if (numberOfRow !== null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += "per-page=" + numberOfRow;
        }

        if (page !== null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += "page=" + (page + 1);
        }

        if (urlAddition != null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += urlAddition;
        }

        if (searchParams != null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += searchParams;
        }

        if (routeFilter != null) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += routeFilter;
        }

        if (activeTabFilter) {
            if (questionmarked === false) {
                questionmarked = true;
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }

            fullUrl += activeTabFilter;
        }

        if (sortOrder != null) {
            if (questionmarked === false) {
                fullUrl += "?";
            } else {
                fullUrl += "&";
            }
            fullUrl += "sort=" + sortOrder;
        }

        // Use the provided resource or the default axios
        const rest = resource || axios;

        // Make the API request using the provided HTTP client library
        const response = yield call(rest.get, fullUrl, {responseType: 'json'});

        // If a callback is provided, invoke it with the response data
        if (callback) {
            callback(response.data);
        }
        // Dispatch the success action with the response data
        yield put({type: FETCH_DATA_SUCCESS_SAGA, index, data: response.data});
    } catch (error) {
        // Dispatch the failure action in case of an error
        yield put({type: FETCH_DATA_FAILURE_SAGA, index: action.index, error: action.index + ': ' + error.message});
    } finally {
        yield put({type: STOP_LOADING, index: action.index});
    }
}

function* fetchTabsSaga(action) {
    try {
        const {
            url,
            index,
            resource
        } = action;

        let fullUrl = url;

        fullUrl += fullUrl.endsWith('/') ? "tabs" : "/tabs"

        // Use the provided resource or the default axios
        const rest = resource || axios;

        // Make the API request using the provided HTTP client library
        const response = yield call(rest.get, fullUrl, {responseType: 'json'});
        // Dispatch the success action with the response data
        yield put({type: FETCH_TABS_SUCCESS_SAGA, index, data: response.data});
    } catch (error) {
        // Dispatch the failure action in case of an error
        yield put({type: FETCH_TABS_FAILURE_SAGA, index: action.index, error: error.message});
    }
}

function* setTabsSaga(action) {
    try {
        const {
            index,
            tab,
            currentTab
        } = action;
        yield put({type: SET_TAB_SUCCESS_SAGA, index, tab, currentTab});
    } catch (error) {
        // Dispatch the failure action in case of an error
        yield put({type: SET_TAB_FAILURE_SAGA, index: action.index, error: `${error.message} - cannot set tab`});
    }
}

// Watch for FETCH_TABS_REQUEST and run fetchTabsSaga on latest occurrence
export function* watchFetchTabs() {
    yield takeLatest(FETCH_TABS_REQUEST_SAGA, fetchTabsSaga);
}

// Watch for FETCH_DATA_REQUEST and run fetchDataSaga on latest occurrence
export function* watchFetchData() {
    yield takeLatest(FETCH_DATA_REQUEST_SAGA, fetchDataSaga);
}

// Watch for FETCH_DATA_REQUEST and run fetchDataSaga on latest occurrence
export function* watchSetTab() {
    yield takeLatest(SET_TAB_REQUEST_SAGA, setTabsSaga);
}
