import {
    TEMPLATE_AUDIT_SEND_COMMENT_SUCCESS,
    TEMPLATE_AUDIT_SET_COMMENT_DATA,
    TEMPLATE_AUDIT_SET_ERROR,
    TEMPLATE_AUDIT_SET_INFO,
    TEMPLATE_AUTH_START_LOADING
} from "../actions/actionTypes";
import {updateObject} from "../../shared/utility";

const initialState = {
    loading: false,
    error: null,
    auditor: null,
    template: null,
    commentData: null,
    commentSended: false,
    state_id: null
}
const startLoading = (state) => {
    return updateObject(state, {loading: true});
}

const setInfo = (state, action) => {
    return updateObject(state,{state_id:action.data.state_id, auditor: action.data.auditor, template: action.data.template, loading: false});
}

const setError = (state, action) => {
    return updateObject(state, {loading: false, error: action.error});
}

const setCommentData = (state, action) => {
    return updateObject(state, { commentData: action.data })
}

const setCommentSuccess = (state) => {
    return updateObject(state, {
        loading: false,
        error: null,
        auditor: null,
        template: null,
        commentData: null,
        commentSended: true
    })
}

const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case TEMPLATE_AUTH_START_LOADING: return startLoading(state, action);
        case TEMPLATE_AUDIT_SET_INFO : return setInfo(state, action);
        case TEMPLATE_AUDIT_SET_ERROR: return setError(state, action);
        case TEMPLATE_AUDIT_SET_COMMENT_DATA: return setCommentData(state, action);
        case TEMPLATE_AUDIT_SEND_COMMENT_SUCCESS: return setCommentSuccess(state, action);
        default: return state;
    }
}

export default reducer;