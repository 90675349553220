import {createSelector} from "reselect";
import {getActiveCompanyUUID} from "./companySelector";
import i18n from "../../i18n/i18n";

/**
 * Get table index without prop index
 **
 *  @returns {string}
 */
export const getIndex = createSelector(
    [
        state => state.data,
    ],
    (data) => {
        return data.index;
    }
);

/**
 * Get table index by prop index
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {string}
 */
export const getIndexWithProp = (state, index = null) => {
    if (!index) {
        index = getIndex(state)
    }
    return index;
}

/**
 * Is table loading
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {boolean}
 */
export const isTableLoading = createSelector(
    [
        (state, index) => getTableData(state, index),
    ],
    (data) => {
        return data.loading || false;
    }
);

/**
 * Is table tabs initialized
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {boolean}
 */
export const tabsInitialized = createSelector(
    [
        (state, index) => getTableData(state, index),
    ],
    (data) => {
        return data.tabsInitialized || false;
    }
);

/**
 * Is table initialized
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {boolean}
 */
export const getTableInitialized = createSelector(
    [
        (state, index) => getTableData(state, index),
    ],
    (data) => {
        return data.initialized || false;
    }
);

/**
 * Get table Filters
 *
 * @param {string} passedIndex - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {object | boolean}
 */
export const getTableFilters = createSelector(
    [
        (state, index) => getTableData(state, index),
    ],
    (data) => {
        return data.filters || false;
    }
);

/**
 * Get table filters in array of a certain field
 *
 * @param {string} passedIndex - The index of the table (e.g., "project", "template", "business_relation").
 * @param {string} field - The field from which to get filter options.
 *
 * @returns {array} An array of filter options for the specified field.
 */
export const getTableFiltersInArray = createSelector(
    [
        (state, index) => getTableFilters(state, index),
        (state, index, field) => field,
    ],
    (data = {}, field) => {
        if (!data || typeof data !== 'object') {
            return [];
        }

        const filterOptions = data[field];
        if (!filterOptions || typeof filterOptions !== 'object') {
            return [];
        }

        return Object.values(filterOptions);
    }
);

/**
 * Get table meta data
 *
 * @param {string} passedIndex - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {object | boolean}
 */
export const getTableMeta = createSelector(
    [
        (state, index) => getTableData(state, index),
    ],
    (data) => {
        return data.meta || false;
    }
);


/**
 * Is table loading
 *
 *  @returns {boolean}
 */
export const isLoading = (state) => {
    return state.data?.loading || false;
}

/**
 * Get All table data
 *
 * @param {string} passedIndex - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {object}
 */
export const getTableData = createSelector(
    [
        state => state,
        (state, passedIndex) => passedIndex,
    ],
    (state, passedIndex) => {
        const index = getIndexWithProp(state, passedIndex);
        return state.data[index] || {};
    }
);

/**
 * Get tableRow data
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 * @param {number} rowId - RowIndex (not row ID)
 *
 *  @returns {object}
 */
export const getTableDataRow = createSelector(
    [
        state => state,
        (state, index) => index,
        (state, index, rowId) => rowId,
        (state, index, rowId, hideExtraData) => hideExtraData
    ],
    (state, index, rowId, hideExtraData) => {
        if (!index || typeof rowId !== 'number') {
            return {};
        }
        const items = getTableItems(state, index);
        const data = {...items[rowId]};

        if (hideExtraData) {
            delete data?._links;
            delete data?.tableAction;
            delete data?.created_at;
            delete data?.created_by;
        }
        return data || {};
    }
);


/**
 * Get alerts
 *  @returns {object}
 */
export const getNotifications = (state) => {
    return state.data?.alert || {}
}

/**
 * @param {string} passedIndex - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {object}
 */
export const getTableExportReduxStore = createSelector(
    [
        state => state,
        (state, passedIndex) => passedIndex,
    ],
    (state, passedIndex,) => {
        const index = getIndexWithProp(state, passedIndex);
        return state.data.tableExport[index] || {};
    }
);

/**
 * Get table export settings
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 * @param {string} company - UUID of the active company
 * @param {bool} hasTableSettings - check if table has tablesettings
 *
 *  @returns {object | null}
 */
export const getTableExport = createSelector(
    [
        state => state,
        (state, index) => index,
        (state, index, company) => company,
        (state, index, company, hasTableSettings) => hasTableSettings,
    ],
    (state, index, company, hasTableSettings) => {
        if (!hasTableSettings) {
            return;
        }
        if (!index) {
            index = getIndex(state)
        }
        let activeCompanyUUID = state.auth?.scopes?.PEER?.companySelected?.UUID;
        let tableSettings = state.data.tableExport[index];
        if ((activeCompanyUUID) && activeCompanyUUID !== company) {
            return tableSettings = null;
        }
        if (!tableSettings) {
            const localStorage = getLocalStorageTableSettings(state, index, activeCompanyUUID)
            if (localStorage) {
                tableSettings = localStorage;
            }
            if (!tableSettings) {
                tableSettings = null;
            }
        }

        if ((tableSettings) && !tableSettings.aggregation) {
            tableSettings.aggregation = {model: {}}
        }

        return tableSettings;
    }
);

/**
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {array}
 */
export const getTableSettingsColumnOrder = createSelector(
    [
        (state, index, hasTableSettings) => getTableExport(state, index, getActiveCompanyUUID(state, index), hasTableSettings)
    ],
    (data) => {
        if (!data || !data?.columns.orderedFields) {
            return []
        }
        return data?.columns?.orderedFields || []
    }
);

/**
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {object}
 */
export const getTableSettingsColumnWidth = createSelector(
    [
        (state, index, hasTableSettings) => getTableExport(state, index, getActiveCompanyUUID(state, index), hasTableSettings)
    ],
    (data) => {
        if (!data || !data?.columns.dimensions) {
            return {}
        }
        return data?.columns?.dimensions || {}
    }
);


/**
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {object}
 */
export const getTableTabs = createSelector(
    [
        (state, index) => getTableData(state, index),
    ],
    (data) => {
        return data.tabs || {};
    }
);

/**
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {array}
 */
export const getTableTabsLabels = createSelector(
    [
        (state, index) => getTableTabs(state, index),
    ],
    (tabs) => {
        return tabs && Object.keys(tabs) || [];
    }
);

/**
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {array}
 */
export const getTableTabsStates = createSelector(
    [
        getTableTabs
    ],
    (tabs) => {
        if (!tabs) {
            return [];
        }
        const excludeKeys = new Set([i18n.t('STATE_ALL')]);
        return Object.keys(tabs)
            .filter(key => !excludeKeys.has(key))
            .map(key => {
                const value = tabs[key];
                const decodedValue = decodeURIComponent(value);
                const match = decodedValue.match(/state_id](?:%5D)?=(\d+)/);
                return {
                    key,
                    label: key,
                    value: match ? match[1] : ''
                };
            });
    }
);

/**
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {array}
 */
export const getTableTabAsArray = createSelector(
    [
        (state, index) => getTableTabs(state, index),
    ],
    (tabs) => {
        return Object.keys(tabs).map(function (key) {
            return [key, tabs[key]]
        }) || [];
    }
);


/**
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {array}
 */
export const getTableItems = createSelector(
    [
        (state, index) => getTableData(state, index),
    ],
    (data = []) => {
        return data.items || [];
    }
);

/**
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {array}
 */
export const getTableColumns = createSelector(
    [
        (state, index) => getTableData(state, index),
    ],
    (data) => {
        return data.columns || [];
    }
);

/**
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {string | null}
 */
export const getTableCurrentTabFilter = createSelector(
    [
        (state, index) => getTableCurrentTab(state, index),
        (state, index) => getTableTabs(state, index),
    ],
    (currentTab, tabs) => {
        if (!tabs) {
            return null
        }

        return Object.values(tabs)[currentTab];
    }
);

/**
 * Get Table current tab
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {number }
 */
export const getTableCurrentTab = createSelector(
    [
        (state, index) => getTableData(state, index),
        (state, index) => getTableDefaultTab(state, index),
    ],
    (data, defaultTab) => {

        if (typeof data.currentTab === 'number') {
            return data.currentTab
        }
        return defaultTab || 0
    }
);

/**
 * Get Table default tab
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {number | null}
 */
export const getTableDefaultTab = createSelector(
    [
        (state, index) => getTableData(state, index),
    ],
    (data) => {
        return data.defaultTabIndex || null;
    }
);

/**
 * Get table Actions
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {object | null}
 */
export const getTableAction = createSelector(
    [
        (state, index) => getTableData(state, index),
    ],
    (data) => {
        return data.action || null;
    }
);

/**
 * Get open record
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {boolean | number}
 */
export const getTableOpenRecord = createSelector(
    [
        (state) => state,
        (state, index) => getTableData(state, index),
    ],
    (state, data) => {
        return typeof data?.openRecord === 'number' ? data.openRecord : false;
    }
);

/**
 * Get all selected row for passed index in a array
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {array}
 */
export const getTableSelectedRows = createSelector(
    [
        (state) => state,
        (state, index) => getTableData(state, index),
    ],
    (state, data) => {
        return data.selectedRows || [];
    }
);

/**
 * Get all selected row id's for passed index in a array
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {array}
 */
export const getTableSelectedRowsIds = createSelector(
    [
        (state) => state,
        (state, index) => getTableSelectedRows(state, index),
        (state, index) => getTableItems(state, index),
    ],
    (state, selectedData, rows) => {
        const array = []
        selectedData.map((item) => {
            array.push(rows[item].id)
        })

        return array || [];
    }
);


/**
 * Get Dialog open state
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {bool}
 */
export const getTableDialogOpen = createSelector(
    [
        (state) => state,
        (state, index) => getTableData(state, index),
    ],
    (state, data) => {
        return data.open || false;
    }
);


/**
 * Get data of expanded rows
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {object}
 */
export const getTableExpandedRows = createSelector(
    [
        (state, index) => getTableData(state, index),
    ],
    (data) => {
        return data.expanded || {};
    }
);

/**
 * Get table settings from local storage
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 * @param {string} company - The uuid of active company
 *
 *  @returns {object}
 */

export const getLocalStorageTableSettings = (state, index, company) => {
    const tableSettings = JSON.parse(localStorage.getItem('tableSettings'));
    if ((!tableSettings) || !Object.prototype.hasOwnProperty.call(tableSettings, company) || !Object.prototype.hasOwnProperty.call(tableSettings[company], index)) {
        return false;
    }

    return tableSettings[company][index];
}


/**
 * Get table actions
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {array}
 */
export const getTableIconActions = (state, index) => {
    if (!index) {
        index = getIndex(state)
    }
    const tableData = state.data[index];

    if (!tableData?.openRecord) {
        return;
    }
    const TableIconActions = tableData.items[tableData.openRecord].tableAction?.actions;

    const tempActionsArray = Object.keys(TableIconActions).map(key => {
        return {action: TableIconActions[key]}
    });
    return tempActionsArray;
}

/**
 * Find a dataRow by a specific Id
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 * @param {number} id - id of table row.
 *
 *  @returns {object}
 */
export const getTableRowByColumnId = createSelector(
    [
        state => state,
        (state, index) => index,
        (state, index, id) => id,
    ],
    (state, index, id) => {
        if (!index || typeof id !== 'number') {
            return {};
        }
        //All items of index
        const items = getTableItems(state, index);

        //Found row by column id
        const foundRow = items.find((item) => {
            return item.id === id
        });
        return foundRow || {};
    }
);

/**
 * Get data from redux store of Selected Rows
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 * @returns {array}
 */
export const getDataOfTableSelectedRows = createSelector(
    [
        state => state,
        (state, index) => index
    ],
    (state, index) => {
        const array = []
        const selectedRows = getTableSelectedRows(state, index)

        selectedRows.map((item) => {
            const row = getTableDataRow(state, index, item);
            array.push(row)
        })

        return array;
    }
);

/**
 * Get All uuids from table
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 * @param {string} uuid_field - Name of the field uuid (e.g., "template_uuid", "uuid", "project_uuid").
 *
 *  @returns {array}
 */
export const getUuidsFromTableItems = createSelector(
    [
        (state, index) => getTableItems(state, index),
        (state, index, uuid_field) => uuid_field,
    ],
    (data, uuid_field) => {
        const tempTemplateUuidArray = []
        data.map(item => {
            if (item[uuid_field]) {
                tempTemplateUuidArray.push(item[uuid_field])
            }
        })
        return tempTemplateUuidArray;
    }
);

/**
 * Get data from redux store by open record (detailview)
 *
 * @param {string} index - The index of table (e.g., "project", "template", "business_relation").
 *
 *  @returns {object}
 */
export const getDataOfOpenRecord = createSelector(
    [
        state => state,
        (state, index) => index
    ],
    (state, index) => {
        const openRecord = getTableOpenRecord(state, index);
        const items = getTableItems(state, index);
        return items[openRecord]
    }
);