import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";
import update from "immutability-helper";

const initialState = {
    loading: false,
    error: null,
    errorState:false,
    success:false,
    data: {},
}

const startLoading = (state) => {
    return updateObject(state, {loading: true,errorState:false});
}
const endLoading = (state) => {
    return updateObject(state, {loading: false});
}

const setError = (state, action) => {
    return updateObject(state, {loading: false, error: action.error, errorState:true});
}
const closeError = (state) => {
    return updateObject(state, {error: "", errorState:false});
}

const fetchDataSuccess = (state, action) => {
    return update(state , {$set : {data: action.data, loading: false, success:true,  errorState:false}});
}

const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.PASSWORD_REQUESTED: return fetchDataSuccess(state, action);
        case actionTypes.PASSWORD_RESET_START: return startLoading(state, action);
        case actionTypes.PASSWORD_RESET_SUCCESS: return fetchDataSuccess(state, action);
        case actionTypes.PASSWORD_RESET_FAIL: return setError(state, action);
        case actionTypes.PASSWORD_RESET_FAIL_ALERT: return closeError(state, action);
        case actionTypes.PASSWORD_AUTH_KEY_VALID: return endLoading(state, action);
        default: return state;
    }
}

export default reducer;