
import React from "react";
import {Button, Grid, Typography} from "@mui/material";
import customClasses from '../Auth.module.css';
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";

export default function ForgotPasswordComplete(props) {

    return(
            <Grid className={customClasses.contentSpacing} container alignItems="flex-end" justifyContent="flex-end">
                <Grid item xs={12}>
                    <Typography align='left' variant="h6">{i18n.t('BUTTON_FORGOT_PASSWORD')}</Typography>
                    <Typography align='left' variant="body2">{i18n.t('INFO_MAIL_NEW_PASSWORD')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <br/>
                    <Button
                        onClick={props.onClick}
                        variant="contained"
                        color="primary"
                        size="medium"
                        fullWidth
                        style={{ textTransform: "none" }}>{i18n.t('BACK_TO_LOGIN')}
                    </Button>
                </Grid>
            </Grid>
    )
}
ForgotPasswordComplete.propTypes = {
    onClick : PropTypes.func.isRequired
}