import React from "react";
import {styled} from '@mui/material/styles';
import {
    Checkbox, Chip,
    FormControl, IconButton,
    ListItemText,
    MenuItem,
    Select,
    Tooltip, Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedRegions} from "../../../store/actions/auth";
import {Cancel, CenterFocusWeak} from "@mui/icons-material";
import WindowDimensions from "../../WindowDimensions/WindowDimensions";
import {getActiveCompany} from "../../../store/selectors/companySelector";
import {getAvailableRegions, getStateSelectedRegions} from "../../../store/selectors/regionSelector";
import {isTableLoading} from "../../../store/selectors/datatableSelector";
import i18n from "../../../i18n/i18n";

const PREFIX = 'HeaderRegionSelector';

const classes = {
    formControl: `${PREFIX}-formControl`,
    chips: `${PREFIX}-chips`,
    chip: `${PREFIX}-chip`,
    chipDeleteIcon: `${PREFIX}-chipDeleteIcon`,
    regionMarker: `${PREFIX}-regionMarker`,
    regionMarkerContainer: `${PREFIX}-regionMarkerContainer`,
    select: `${PREFIX}-select`,
    selectArrow: `${PREFIX}-selectArrow`
};

const StyledFormControl = styled(FormControl)((
    {
        theme
    }
) => ({
    [`& .${classes.chips}`]: {
        overflow: 'auto',
        '&::-webkit-scrollbar': {height: 0, borderBottom: '1px solid #EE6E2C'},
    },

    [`& .${classes.chip}`]: {
        marginRight: 4,
        cursor: "pointer",
        backgroundColor: '#FBE2D4',
        color: '#EE6E2C',
        border: 'none',
        boxShadow: ' inset 1px 1px 1px #d66327, 0 0 1px #d66327',
        fontSize: '0.9rem',
        fontWeight: 500,
        justifyItems: 'flex-end',
        textTransform: 'capitalize',
        [theme.breakpoints.down('lg')]: {
            fontSize: '0.7rem',
        },
    },

    [`& .${classes.chipDeleteIcon}`]: {
        color: '#EE6E2C !important',
    },

    [`& .${classes.regionMarker}`]: {
        color: '#FBE2D4',
    },

    [`& .${classes.regionMarkerContainer}`]: {
        [theme.breakpoints.down('lg')]: {
            padding: '6px',
        },
    },

    [`& .${classes.select}`]: {
        padding: '8px',
        border: 'none',
        [theme.breakpoints.down('lg')]: {
            paddingRight: "20px",
        },
    },

    [`& .${classes.selectArrow}`]: {
        fill: '#FBE2D4',
    }
}));

export default function HeaderRegionSelector() {
    const selectedCompany = useSelector(state => getActiveCompany(state));
    const availableRegions = useSelector(state => getAvailableRegions(state));
    const selectedRegions = useSelector(state => getStateSelectedRegions(state));
    const loading = useSelector(state => isTableLoading(state));
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    let {height, width} = WindowDimensions();

    const handleChange = (event) => {
        const {target: {value}} = event;
        const selected = value.filter((item) => {
            return typeof item === "string"
        })

        //Should always be 1 active region
        if (JSON.stringify(selected) === JSON.stringify(Object.keys(selectedRegions)) && Object.keys(selectedRegions).length === 1) {
            return;
        }
        const newRegions = {...selectedRegions};
        selected.map((item) => {
            if (newRegions[item]) {
                delete newRegions[item]
            } else {
                newRegions[item] = selectedCompany.regions[item]
            }
        })
        dispatch(setSelectedRegions(newRegions));
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = (event, value) => {
        const newRegions = {...selectedRegions};
        delete newRegions[value.UUID]
        if (loading) {
            return;
        }
        dispatch(setSelectedRegions(newRegions));
    };

    if (!selectedCompany || !availableRegions) {
        return "";
    }

    return (
        <StyledFormControl variant="standard" className={classes.formControl}>
            <Select
                labelId="region-select-label"
                id="region-select"
                multiple
                autoWidth
                className={classes.select}
                value={availableRegions}
                onChange={handleChange}
                disabled={(availableRegions.length === 1)}
                startAdornment={
                    <Tooltip title={i18n.t('REGION')}>
                        <span>
                            <Typography variant="body2">
                                <IconButton
                                    disabled={availableRegions.length === 1}
                                    className={classes.regionMarkerContainer}
                                    onClick={handleOpen}
                                    size="large">
                                    <CenterFocusWeak className={classes.regionMarker}/>
                                </IconButton>
                            </Typography>
                        </span>
                    </Tooltip>}
                disableUnderline={true}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                inputProps={{
                    classes: {
                        icon: classes.selectArrow,
                    },
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                }}

                renderValue={(availableRegions) => (
                    width >= 768 ? (
                        <div className={classes.chips}>
                            {
                                availableRegions.map((value) => {
                                    if (selectedRegions[value.UUID]) {
                                        return (
                                            Object.keys(selectedRegions).length > 1 ? (
                                                <Chip
                                                    deleteIcon={
                                                        <Tooltip title={i18n.t('TOOLTIP_HIDE_REGIONS')}>
                                                            <Cancel
                                                                className={classes.chipDeleteIcon}
                                                                onMouseDown={(event) => event.stopPropagation()}
                                                            />
                                                        </Tooltip>
                                                    } key={value.UUID}
                                                    label={value.name}
                                                    className={classes.chip}
                                                    onDelete={(event) => {
                                                        handleDelete(event, value)
                                                    }}/>
                                            ) : (
                                                <Chip variant="outlined"
                                                      key={value.UUID}
                                                      label={value.name}
                                                      className={classes.chip}
                                                />)
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </div>
                    ) : null
                )}>
                <MenuItem style={{marginLeft: '10px'}} disabled value="">
                    <em>{i18n.t('BUTTON_SELECT_REGION')}</em>
                </MenuItem>
                {
                    availableRegions.map((availableRegion) => {
                        let checked = selectedRegions[availableRegion.UUID] ? true : false;
                        return <MenuItem key={availableRegion.UUID} value={availableRegion.UUID} disabled={loading}>
                            <Checkbox
                                disabled={(Object.keys(selectedRegions).length === 1 && checked == true) || loading}
                                checked={checked}/>
                            <ListItemText primary={availableRegion.name}/>
                        </MenuItem>
                    })}
            </Select>
        </StyledFormControl>
    );
}