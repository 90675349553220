import React, {useEffect} from "react";
import customClasses from "../Auth.module.css";
import Logo from "../../../components/Logo/Logo";
import AuthBackButton from "../../../components/Controls/AuthBackButton";
import Loading from "../../../components/Animation/Loading";
import {compose} from "redux";
import {connect, useDispatch} from "react-redux";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {authResetLogin, logout} from "../../../store/actions/auth";
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";

function AccountNotVerified (props) {

    const dispatch = useDispatch();

    const submitHandler=()=>{
        dispatch(authResetLogin('/'))
    }

    useEffect(() => {
        dispatch(logout())
    }, [])

    return(
            <React.Fragment>
                <form className={customClasses.AuthData}>
                    <Logo size={60}/>
                    <AuthBackButton title={i18n.t('BACK_TO_LOGIN')}/>
                    {
                        props.loading ?
                            <Loading size={300}/>
                        :
                            <>
                                <br/>
                                <Typography variant="h5">{i18n.t('ERROR_ACCOUNT_NOT_VERIFIED')}</Typography>
                                <br/>
                                <Typography paragraph variant="body2">{i18n.t('ERROR_ACCOUNT_NOT_VERIFIED_MAIL')}</Typography>
                                <Link style={{textDecoration:'none'}} to='/'>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitHandler}
                                        type="submit"
                                        fullWidth
                                        style={{ textTransform: "none", marginBottom:'10px'}}>Vraag nieuwe activatie mail aan</Button>
                                </Link>
                            </>
                    }
                </form>
            </React.Fragment>
        )
}


const mapStateToProps = state => {
    return {
        loading: state.reg.loading,
        error: state.reg.error,
        errorState: state.reg.errorState,
        success: state.reg.success,
    }
}

export default compose(
    connect(mapStateToProps)
)(AccountNotVerified);

AccountNotVerified.propTypes = {
    loading: PropTypes.bool,
}