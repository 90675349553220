import TemplateFormCarrier from "./TemplateFormCarrier";
import TemplateFormDisposer from "./TemplateFormDisposer";
import TemplateFormInvoice from "./TemplateFormInvoice";
import TemplateFormAgent from "./TemplateFormAgent";
import TemplateFormOrigin from "./TemplateFormOrigin";
import TemplateFormDestination from "./TemplateFormDestination";
import TemplateFormSender from "./TemplateFormSender";
import TemplateFormSettings from "./TemplateFormSettings";
import TemplateFormGoodsItems from "./TemplateFormGoodsItems";
import TemplateFormFooter from "./TemplateFormFooter";
import TemplateFormWeightbridge from "./TemplateFormWeightbridge";
import TemplateFormActivity from "./TemplateFormActivity";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import customClasses from "./TemplateForm.module.css";
import {Formik, Form} from 'formik';
import formValidationSchema from './FormModel/formValidationSchema';
import formInitialValues from './FormModel/formInitialValues';
import formModel from './FormModel/formModel';
import PropTypes from "prop-types";
import {Alert, AlertTitle} from "@mui/material";
import {useSelector} from "react-redux";
import TemplateFormReportStatement from "./TemplateFormReportStatement";
import {getTableItems} from "../../../store/selectors/datatableSelector";
import TemplateFormIntro from "./TemplateFormIntro";

export default function TemplateForm(props) {
    const savedData = props.data ?? null;
    const {formField} = formModel;
    const currentValidationSchema = formValidationSchema[0];
    const myRef = React.createRef()
    const projectItems = useSelector(state => getTableItems(state, 'project'));
    const [selectedProject, setSelectedProject] = useState({});
    const projectId = props.formRef?.current?.values?.project_id || savedData.project_id || ''
    const disabledWithCertainState = ((savedData?.state_id === '30' && props.action !== 'copy') || (savedData?.state_id === '50') || savedData?.state_id === '80');
    //Submit
    const handleSubmit = (values) => {
        props.handleData(values);
    }

    //Handle submit errors
    useEffect(() => {
        if (props.errors) {
            scroll(myRef)
        }
    }, [props.errors])

    //on Error scroll to top
    const scroll = (ref) => (
        ref.current.scrollIntoView({behavior: 'smooth'})
    )

    let templateErrors = '';
    if (props.errors) {
        templateErrors =
            props.errors.map((item) => {
                const tabArray = Object.keys(item).map(function (key) {
                    return [key, item[key]]
                });

                return (
                    Object.keys(tabArray).map(key => {
                        return (
                            <Alert key={tabArray[key][0]} severity="error">
                                <AlertTitle>Fout(en) bij: {tabArray[key][0]}</AlertTitle>
                                {
                                    tabArray[key][1].map((e, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <span key={index}>{e}</span>
                                                <br/>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </Alert>
                        )
                    })
                )
            });
    }

    //Handle submit errors
    useEffect(() => {
        if (!projectItems || !projectItems.length || !projectId) {
            return;
        }
        const found = projectItems.find((element) => {
            if (element.id === savedData.project_id) {
                return element;
            }
        })
        setSelectedProject(found)
    }, [projectId])

    return <React.Fragment>
        <div ref={myRef}/>

        {templateErrors}

        <Formik
            initialValues={savedData ?? formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={(e) => {
                handleSubmit(e)
            }}
            innerRef={props.formRef}>
            {({
                  initialValues,
                  setFieldValue,
                  setFieldError,
                  values,
              }) => (
                <Form id="templateForm" className={customClasses.templateForm}>
                    <Grid container className={customClasses.templateGridContainer}
                          direction="row"
                          justifyContent="space-around"
                          alignItems="flex-start">
                        <Grid container item>
                            {/*====================================================================================================================*/}
                            <Grid item xs={12} md={6}>
                                <Grid className={customClasses.container} item xs={12}>
                                    {/*==Intro Text==*/}
                                    <TemplateFormIntro
                                        values={values}
                                    />
                                </Grid>
                            </Grid>
                            {/*====================================================================================================================*/}
                            <Grid container item
                                  className={`${customClasses.containerBorderLeft} ${customClasses.disabledContainer}`}
                                  xs={12} md={6}>
                                {/*==Block settings==*/}
                                <TemplateFormSettings
                                    values={values}
                                    action={props.action}
                                    setFieldValue={setFieldValue}
                                    disabled={true}
                                    formField={formField}
                                    initialValues={initialValues}
                                    copyId={props.copyId}
                                    handleCopyId={props.handleCopyId}
                                />
                            </Grid>
                            {/*====================================================================================================================*/}
                            <Grid container item direction="row" className={customClasses.containerRequired} xs={12}
                                  md={6}>
                                {/*==Block 1==*/}
                                <TemplateFormSender
                                    values={values}
                                    action={props.action}
                                    project={selectedProject}
                                    setFieldError={setFieldError}
                                    setFieldValue={setFieldValue}
                                    disabled={props.disabled}
                                    formField={formField}
                                    autoFillReplaceData={props.autoFillReplaceDataAllowed}
                                    templateFormInitialised={props.templateFormInitialised}
                                />
                            </Grid>
                            {/*====================================================================================================================*/}
                            <Grid container
                                  className={`${customClasses.containerBorderLeft} ${customClasses.disabledContainer}`}
                                  item xs={12} md={6}>
                                <TemplateFormActivity
                                    initialValues={savedData ?? formInitialValues}
                                    setFieldValue={setFieldValue}
                                    disabled={true}
                                    formField={formField}
                                    values={values}
                                    savedData={savedData}
                                    action={props.action}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.container} item xs={12} md={6}>
                                {/*==Block 2==*/}
                                <TemplateFormInvoice
                                    values={values}
                                    action={props.action}
                                    data={props.data}
                                    project={selectedProject}
                                    setFieldValue={setFieldValue}
                                    disabled={props.disabled}
                                    formField={formField}
                                    templateFormInitialised={props.templateFormInitialised}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container
                                  className={`${customClasses.containerBorderLeft} ${customClasses.disabledContainer}`}
                                  item xs={12} md={6}>
                                {/*==Weegbrug info==*/}
                                <TemplateFormWeightbridge
                                    action={props.action}
                                    initialValues={savedData ?? formInitialValues}
                                    values={values}
                                    data={props.data}
                                    setFieldValue={setFieldValue}
                                    disabled={true}
                                    formField={formField}
                                    handleBlur={props.handleBlur}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.containerRequired} item xs={12} md={6}>
                                {/*==Block 3A==*/}
                                <TemplateFormDisposer
                                    action={props.action}
                                    values={values}
                                    project={selectedProject}
                                    disabled={props.disabled}
                                    formField={formField}
                                    data={props.data}
                                    setFieldValue={setFieldValue}
                                    autoFillReplaceData={props.autoFillReplaceDataAllowed}
                                    templateFormInitialised={props.templateFormInitialised}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.containerRequired} item xs={12} md={6}>
                                {/*==Block 3B==*/}
                                <TemplateFormOrigin
                                    project={selectedProject}
                                    setFieldValue={setFieldValue}
                                    disabled={props.disabled}
                                    formField={formField}
                                    data={props.data}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.containerMaybeRequired} item xs={12} md={6}>
                                {/*==Block 4A==*/}
                                <TemplateFormAgent
                                    setFieldValue={setFieldValue}
                                    disabled={true}
                                    formField={formField}
                                    organisationData={props.organisationData}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.containerRequired} item xs={12} md={6}>
                                {/*==Block 4B==*/}
                                <TemplateFormDestination
                                    project={selectedProject}
                                    setFieldValue={setFieldValue}
                                    disabled={props.disabled}
                                    formField={formField}
                                    values={values}
                                    data={props.data}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.containerRequired} item xs={12}>
                                {/*==Block 5==*/}
                                <TemplateFormCarrier
                                    action={props.action}
                                    project={selectedProject}
                                    setFieldValue={setFieldValue}
                                    disabled={(disabledWithCertainState) || props.disabled}
                                    formField={formField}
                                    values={values}
                                    autoFillReplaceData={props.autoFillReplaceDataAllowed}
                                    templateFormInitialised={props.templateFormInitialised}
                                    handleSetTemplateFormInitialised={props.handleSetTemplateFormInitialised}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container className={customClasses.containerRequired} item xs={12}>
                                {/*==Block 6==*/}
                                <TemplateFormGoodsItems
                                    disabled={props.disabled}
                                    euralData={props.euralData}
                                    values={values}
                                    formField={formField}
                                    setFieldValue={setFieldValue}
                                    // processingData={props.processingMethodData}
                                />
                            </Grid>
                            {/*/!*====================================================================================================================*!/*/}
                            {
                                values.form_type === "B2" ? (
                                    <Grid container className={customClasses.containerRequired} item xs={12}>
                                        <TemplateFormReportStatement
                                            disabled={props.disabled}
                                            values={values}
                                            formField={formField}/>
                                    </Grid>
                                ) : ('')
                            }
                            {/*/!*====================================================================================================================*!/*/}
                            <Grid container item xs={12}><br/><br/></Grid>
                            {/*====================================================================================================================*/}
                            <Grid container className={customClasses.footerContainer} item xs={12}>
                                {/*==OUDFooter==*/}
                                <TemplateFormFooter
                                    disabled={props.disabled}
                                    form_type={values.form_type}
                                    formField={formField}
                                />
                            </Grid>
                            {/*====================================================================================================================*/}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    </React.Fragment>;
};

TemplateForm.propTypes = {
    data: PropTypes.object,
    formRef: PropTypes.object,
    handleData: PropTypes.func,
    templateFormInitialised: PropTypes.bool,
    handleSetTemplateFormInitialised: PropTypes.func,
    disabled: PropTypes.bool,
    action: PropTypes.string,
    copyId: PropTypes.number,
    handleCopyId: PropTypes.func,
    autoFillReplaceDataAllowed: PropTypes.bool,
    errors: PropTypes.string,
    handleBlur : PropTypes.func,
    organisationData : PropTypes.array,
    euralData : PropTypes.array
};
