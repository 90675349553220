import Grid from "@mui/material/Grid";
import React, {useState} from "react";
import customClasses from "./TemplateForm.module.css";
import SearchIcon from "../../Controls/SearchIcon";
import PropTypes from "prop-types";
import SearchDialog from "../Templates/DialogDetails/SearchVihbDialog"
import SimpleDialog from "../../Dialog/SimpleDialog";
import {FormTextField} from "./Components/FormTextField";
import {FastField} from "formik";

export default function TemplateFormAgent(props) {

    const group = "agentparty";
    const [openDialog, setOpenDialog] = useState(false);

    const {
        formField: {
            agentparty_name,
            agentpartyaddress_street_name,
            agentpartyaddress_house_no,
            agentpartyaddress_house_no_addition,
            agentpartyaddress_postal_code,
            agentpartyaddress_city_name,
        }
    } = props;

    // =====================
    // Handle selected company
    // =====================
    const handleCompanySelected = (selectedCompany) => {
        props.setFieldValue(group + '.name', selectedCompany.name || '');
        props.setFieldValue(group + '.address_street_name', selectedCompany.street_name || '');
        props.setFieldValue(group + '.address_house_no', selectedCompany.building_number || '');
        props.setFieldValue(group + '.address_postal_code', selectedCompany.postal_zone || '');
        props.setFieldValue(group + '.address_city_name', selectedCompany.city_name || '');

        closeModal();
    };

    // =====================
    // Handle Modal
    // =====================
    const openModal = () => {
        setOpenDialog(true);
    }
    const closeModal = () => {
        setOpenDialog(false);
    };

    // =====================
    // Dialog
    // =====================
    const dialog = <SimpleDialog
        title="Zoek uitbesteed vervoerder"
        fields={props}
        size="lg"
        fullWidth={true}
        open={openDialog}
        handleClose={closeModal}>
        <SearchDialog data={props.organisationData || []}
                      handleCompanySelected={handleCompanySelected}/>
    </SimpleDialog>

    return <React.Fragment>
        {dialog}
        {/*===========================================================================*/}
        {/*Block Divider*/}
        {/*===========================================================================*/}
        <Grid className={customClasses.borderDivider} container item xs={12} md={12}/>
        <div className={customClasses.blockIdentifier}><strong>4<span className={customClasses.blockPart}>A</span></strong></div>
        {/*===========================================================================*/}
        {/*Block Content
        {/*===========================================================================*/}
        <Grid container item xs={12} md={12}>
            <Grid item xs={11} md={3}>
                {agentparty_name.label}
            </Grid>
            <Grid item xs={1}>
                <SearchIcon
                    color="rgb(113, 43, 1)"
                    disabled={props.disabled}
                    handleClick={openModal}
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <FastField
                    autoComplete="new-password"
                    sizeClass={customClasses.inputFull}
                    name={group + "." + agentparty_name.name}
                    disabled={props.disabled}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container item xs={12} md={12}>
            <Grid item xs={11} md={3}>
                {agentpartyaddress_street_name.label}
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={12} md={8}>
                <FastField
                    name={group + "." + agentpartyaddress_street_name.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputMedium}
                    autoComplete="new-password"
                    component={FormTextField}
                />
                <FastField
                    name={group + "." + agentpartyaddress_house_no.name}
                    type='number'
                    disabled={props.disabled}
                    sizeClass={customClasses.inputSmall}
                    autoComplete="new-password"
                    component={FormTextField}
                />
                <FastField
                    name={group + "." + agentpartyaddress_house_no_addition.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputSmall}
                    autoComplete="new-password"
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container item xs={12} md={12}>
            <Grid item xs={11} md={3}>
                {agentpartyaddress_postal_code.label}
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={12} md={8}>
                <FastField
                    name={group + "." + agentpartyaddress_postal_code.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputSmall}
                    autoComplete="new-password"
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + agentpartyaddress_city_name.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputLarge}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </React.Fragment>
}

TemplateFormAgent.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func,
    organisationData: PropTypes.object,
};