import React, {useEffect, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import {IconButton} from "@mui/material";
import i18n from "../../i18n/i18n";

const CloseButton = (props) => {
    //Default props
    const [color, setColor] = useState("#CCCCCC");
    const [title, setTitle] = useState(i18n.t("TOOLTIP_CLOSE"));
    const [size, setSize] = useState("1.5rem");
    const [labelDirection, setLabelDirection] = useState("top");
    const positionRef = React.useRef({
        x: 0,
        y: 0,
    });
    const popperRef = React.useRef(null);
    const areaRef = React.useRef(null);

    useEffect(() => {
        if (props.color) {
            setColor(props.color);
        }
        if (props.size) {
            setSize(props.size);
        }
        if (props.title) {
            setTitle(props.title);
        }
        if (props.labelDirection) {
            setLabelDirection(props.labelDirection);
        }
    }, []);

    const handleMouseMove = (event) => {
        positionRef.current = {x: event.clientX, y: event.clientY};

        if (popperRef.current != null) {
            popperRef.current.update();
        }
    };

    return (
        <Tooltip
            placement={labelDirection}
            title={title}
            PopperProps={{
                popperRef,
                anchorEl: {
                    getBoundingClientRect: () => {
                        return new DOMRect(
                            positionRef.current.x,
                            areaRef.current.getBoundingClientRect().y,
                            0,
                            0,
                        );
                    },
                },
            }}
        >
            <IconButton
                onMouseMove={handleMouseMove}
                ref={areaRef}
                aria-label="close"
                onClick={() => props.handleClose()}
                sx={{
                    position: 'absolute',
                    right: 22,
                    top: 21,
                    color: color,
                    width: size,
                    height: size
                }}
            >
                <CloseIcon/>
            </IconButton>

        </Tooltip>
    );
};

CloseButton.propTypes = {
    handleClose: PropTypes.func,
    color: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.string,
    labelDirection: PropTypes.oneOf([
        "bottom-end",
        "bottom-start",
        "bottom",
        "left-end",
        "left-start",
        "left",
        "right-start",
        "right-end",
        "right",
        "top-end",
        "top-start",
        "top",
    ]),
};

export default CloseButton;