import {Route, Routes} from "react-router-dom";
import ActivateAccount from "../../containers/Auth/Activate/ActivateAccount";
import Registration from "../../containers/Auth/Registration/Registration";
import ForgotPassword from "../../containers/Auth/ForgotPassword/ForgotPassword";
import Check from "../../components/Forms/Templates/Check/Check";
import Auth from "../../containers/Auth/Auth";
import React from "react";
import AccountNotVerified from "../../containers/Auth/Errors/AccountNotVerified";
import AccountInactive from "../../containers/Auth/Errors/AccountInactive";
import CompanyInactive from "../../containers/Auth/Errors/CompanyInactive";
import ResetPassword from "../../containers/Auth/ResetPassword/ResetPassword";

export default function LoginLayout() {

    return (
        <Routes>
            <Route exact path="/notVerified" element={<AccountNotVerified/>}/>
            <Route exact path="/inactive" element={<AccountInactive/>}/>
            <Route exact path="/inactiveCompany" component={CompanyInactive}/>
            <Route exact path="/activate/:activation_key" element={<ActivateAccount/>}/>
            <Route exact path="/sign-up" element={<Registration/>}/>
            <Route exact path="/reset-password" element={<ForgotPassword/>}/>
            <Route exact path="/reset-password/:token/:email" element={<ResetPassword />}/>
            <Route exact path="/check/:auth_key" element={<Check/>}/>
            <Route path="*" element={<Auth/>}/>
            {/*<Route exact path="/" component={Auth}/>*/}
            {/*<Redirect from="*" to="/"/>*/}
        </Routes>
    )
}