import axios from "axios";
import formklub from "../api/axios-formklub";
import peer from "../api/axios-peer";

const isValidHttpUrl = (string) => {
    let url;

    try {
        url = new URL(string);
    } catch (_e) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export const fetchContent = (url, contentType = null, contentEncoding = null, loadingCallback = null, contentCallback = null) => {
    if (!isValidHttpUrl(url)) {
        return url;
    }

    let axiosObject = axios;
    if (loadingCallback) {
        loadingCallback(true);
    }

    let formklubFrontendDomain = formklub.defaults.baseURL.replace("https://api.", "");
    formklubFrontendDomain = formklubFrontendDomain.replace("http://api.", "");

    let config = {
        headers: {}
    };

    if (url.includes(peer.defaults.baseURL)) {
        axiosObject = peer;
    } else if (url.includes(formklub.defaults.baseURL)) {
        axiosObject = formklub;
    } else if (url.includes(formklubFrontendDomain)) {
        axiosObject = formklub;
    } else if (contentType !== null && contentType.includes('application/pdf+base64')) {
        contentType = 'application/pdf';
    }

    if (contentType === 'application/pdf') {
        config.responseType = "arraybuffer";
        config.responseEncoding = "binary";
    }

    if (contentType !== null) {
        config.headers["Content-Type"] = contentType;
        config.headers.Accept = contentType;
    }
    config.headers["Content-Encoding"] = 'base64';
    axiosObject.get(
        url + "#no-region-filter",
        config
    )
        .then(response => {
            if (response.data === null) {
                return;
            }
            contentCallback(response);
            if (loadingCallback) {
                loadingCallback(false);
            }
        })
        .catch(() => {
            if (loadingCallback) {
                loadingCallback(false);
            }
        })
}
