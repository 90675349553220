
import React, {useEffect, useState} from "react";
import customClasses from '../Auth.module.css';
import Logo from "../../../components/Logo/Logo";
import AuthBackButton from "../../../components/Controls/AuthBackButton";
import ResetPasswordForm from "./ResetPasswordForm";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {checkAuthKey, doResetPassword} from "../../../store/actions/resetPassword";
import Loading from "../../../components/Animation/Loading";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import ResetPasswordError from "./ResetPasswordError";
import {authResetLogin} from "../../../store/actions/auth";
import i18n from "../../../i18n/i18n";
import PropTypes from "prop-types";

export default function ResetPassword() {
    const dispatch = useDispatch()
    const { token, email } = useParams();
    const resetPassword = useSelector(state => {
        return state.resetPassword ?? null
    });
    const [ password, setPassword] = useState("")

    useEffect(() => {
       dispatch(checkAuthKey(token,email))
    },[])

    const handleSubmit = event => {
        event.preventDefault()
        dispatch(doResetPassword(token,email,password))
    }

    const backToLogin= () => {
        dispatch(authResetLogin('/'))
        window.location.href='/'
    };

    let render = <ResetPasswordForm email={email} value={password} onChange={(e)=>setPassword(e.target.value)}/>;
    if (resetPassword.loading){
        render = <Loading size={300}/>;
    }
    if(resetPassword.errorState) {
        render = <ResetPasswordError error={resetPassword.error} onClick={()=>backToLogin()}/>
    }
    if(resetPassword.success) {
        render = <ResetPasswordSuccess onClick={()=>backToLogin()}/>
    }

    return(
        <React.Fragment>
            <form onSubmit={handleSubmit} className={customClasses.AuthData}>
                {/*@todo language selector*/}
                {/*<div style={{right: 20, top: 20, position: 'absolute'}}><LanguageSelector/></div>*/}
                <Logo size={60}/>
                <AuthBackButton title={i18n.t('BACK_TO_LOGIN')}/>
                {render}
            </form>
        </React.Fragment>
    )
}

ResetPassword.propTypes = {
    value : PropTypes.string,
    email : PropTypes.string,
}