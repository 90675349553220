import Grid from "@mui/material/Grid";
import React from "react";
import customClasses from "./TemplateForm.module.css";
import PropTypes from "prop-types";
import {FastField} from "formik";
import {FormTextField} from "./Components/FormTextField";
import UnitTypeDropdown from "../Templates/Onboarding/Dropdowns/UnitTypeDropdown";
import ColorPickerButtons from "../../Controls/ColorPickerButtons";

export default function TemplateFormActivity(props) {

    const {
        formField: {
            order_type,
            gooditem_unit_type_id,
            template_show_color,
            remarks,
            remarks_driver,
            template_work_description,
        }
    } = props;

    return <React.Fragment>

        {/*===========================================================================*/}
        {/* Block Content
        {/*===========================================================================*/}
        <Grid container item xs={12}>
            <Grid item xs={11} md={3}>
                {order_type.label}
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={12} md={8}>
                <FastField
                    name={order_type.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputFull}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container item xs={12}>
            <Grid container item xs={12}>
                <Grid item xs={11} md={3}>
                    {template_work_description.label}
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={12} md={8}>
                    <FastField
                        name={template_work_description.name}
                        disabled={props.disabled}
                        sizeClass={customClasses.inputFull}
                        component={FormTextField}
                    />
                </Grid>
            </Grid>
            <Grid item xs={11} md={3}>
                {remarks.label}
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={12} md={8}>
                <FastField
                    name={remarks.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputFull}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container item xs={12}>
            <Grid item xs={11} md={3}>
                {remarks_driver.label}
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={12} md={8}>
                <FastField
                    name={remarks_driver.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputFull}
                    component={FormTextField}
                />
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={11} md={3}>
                    {gooditem_unit_type_id.label}
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={12} md={8}>
                    <UnitTypeDropdown hideLabel={true} savedData={props.values} viewValueOnly={true} disabled={true} variant="standard" action={props.action}/>
                </Grid>
            </Grid>
        </Grid>
        <Grid container item xs={12}>
            <Grid item xs={11} md={3}>
                {template_show_color.label}
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={12} md={8}>
                <ColorPickerButtons
                    savedData={props.savedData}
                    values={props.values}
                    id="show_color"
                    initialValues={props.initialValues}
                    disabled={props.disabled}/>
            </Grid>
        </Grid>
        <br/>
    </React.Fragment>
}

TemplateFormActivity.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    initialValue: PropTypes.object,
    setFieldvalue: PropTypes.func,
    action: PropTypes.string,
    values: PropTypes.object,
    savedData: PropTypes.object,
    initialValues: PropTypes.object,
};