import { createTheme } from '@mui/material/styles';

const theme = createTheme({
        fontSizes: [12, 14, 16, 20, 24, 32, 48],
        fontWeights: {
            body: 400,
            heading: 700,
        },
        lineHeights: {
            body: 1.5,
            heading: 1.125,
        },
        space: [0, 4, 8, 16, 32, 48],

        //===============================
        // Color Palette
        //===============================
        // common:{
        //     black: "#000",
        //     white: "#fff",
        // },
        palette: {
            mode: 'light',
            primary: {
                light: '#ff9f5a',
                main: '#EE6E2C',
                dark: '#b53f00',
                contrastText: '#ffffff',
            },
            //Secondary light blue
            // secondary:{
            //     light:'#D2D8DD',
            //     main: '#D2D8DD',
            //     dark: '#D2D8DD',
            //     contrastText: '#395069',
            // },

            //Secondary Dark blue
            secondary: {
                light: '#D2D8DD',
                main: '#395069',
                dark: '#395069',
                contrastText: '#D2D8DD',
            },

            // Secondary light orange
            // secondary: {
            //     light:'#FFF',
            //     main: '#EDE9E9',
            //     dark: '#BBB7B7',
            //     contrastText: '#EE6E2C',
            // },

            //Extra Colors
            //===================
            // yellow:{
            //     light:'#ffc107',
            //     main: '#ffc107',
            //     dark: '#ffc107',
            //     contrastText: '#fff',
            // },
            //===================

            // error: {
            //     light:'#FF4081',
            //     main: '#F50057',
            //     dark: '#C51162',
            //     contrastText: '#FFF',
            // },
            // warning: {
            //     light:'#FFB74D',
            //     main: '#FF9800',
            //     dark: '#F57C00',
            //     contrastText: 'rgba(0, 0, 0, 0.87)'
            // },
            // info: {
            //     light:'#64b5F6',
            //     main: '#2196F3',
            //     dark: '#1976D2',
            //     contrastText: '#FFF'
            // },
            // success: {
            //     light:'#81c784',
            //     main: '#4caf50',
            //     dark: '#388e3c',
            //     contrastText: 'rgba(0, 0, 0, 0.87)'
            // },
            // grey:{
            //     50:"#fafafa",
            //     100:"#f5f5f5",
            //     200:"#eeeeee",
            //     300:"#e0e0e0",
            //     400:"#bdbdbd",
            //     500:"#9e9e9e",
            //     600:"#757575",
            //     700:"#616161",
            //     800:"#424242",
            //     900:"#212121",
            //     A100:"#d5d5d5",
            //     A200:"#aaaaaa",
            //     A400:"#303030",
            //     A700:"#616161",
            // },
            background: {
                default: '#ffffff',
            },
        },

        //===============================
        // Typography
        //===============================
        typography: {
            // Use the system font.
            fontFamily:
                '-apple-system,system-ui,BlinkMacSystemFont,' +
                '"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
            // htmlFontSize: 16,
            // fontSize: 14,
            // fontWeightLight: 300,
            // fontWeightRegular: 400,
            // fontWeightMedium: 500,
            // fontWeightBold: 700,
            h1: {
                fontWeight: 500,
                // fontSize: "6rem",
                // lineHeight: 1.167,
                // letterSpacing: "-0.01562em"
            },
            // h2:{
            //     fontWeight:500,
            //     fontSize: "6rem",
            //     lineHeight: 1.167,
            //     letterSpacing: "-0.01562em"
            // },
            // h3:{
            //     fontWeight:500,
            //     fontSize: "6rem",
            //     lineHeight: 1.167,
            //     letterSpacing: "-0.01562em"
            // },
            // h4:{
            //     fontWeight:500,
            //     fontSize: "6rem",
            //     lineHeight: 1.167,
            //     letterSpacing: "-0.01562em"
            // },
            h5: {
                fontWeight: 500,
                fontSize: "1.25rem",
                lineHeight: 1.6,
                letterSpacing: "0.0075em",
            },
            h6: {
                fontWeight: 500,
                fontSize: "1rem"
                // color:"#203A56",
            },
            // subtitle1:{
            //     fontWeight:400,
            //     fontSize: "1rem",
            //     lineHeight: 1.75,
            //     letterSpacing: "0.00938em"
            // },
            // subtitle2:{
            //     fontWeight:400,
            //     fontSize: "0.875rem",
            //     lineHeight: 1.57,
            //     letterSpacing: "0.00714em"
            // },
            // body1:{
            //     fontWeight: 400,
            //     fontSize: "1rem",
            //     lineHeight: 1.5,
            //     letterSpacing: "0.00938em",
            // },
            // body2:{
            //     fontWeight: 400,
            //     fontSize: "0.875rem",
            //     lineHeight: 1.43,
            //     letterSpacing: "0.01071em",
            // },
            // button:{
            //     fontWeight: 500,
            //     fontSize: "0.875rem",
            //     lineHeight: 1.75,
            //     letterSpacing: "0.02857em",
            //     textTransform: "uppercase",
            // },
            // caption:{
            //     fontWeight: 400,
            //     fontSize: "0.75rem",
            //     lineHeight: 1.66,
            //     letterSpacing: "0.03333em",
            // },
            // overline:{
            //     fontWeight: 400,
            //     fontSize: "0.75rem",
            //     lineHeight: 2.66,
            //     letterSpacing: "0.08333em",
            //     textTransform: "uppercase",
            // },
        },

        //=======================================
        // Test new colors side menu
        //=======================================
        components: {
            //===================
            //Table Styling
            //===================
            MuiDataGrid: {
                styleOverrides: {
                    main: {
                        border: '1px solid #CCCCCC',
                        borderBottom: 'none',
                    },
                    footerContainer: {
                        border: '1px solid #ccc',
                        overflow: 'hidden'
                    }
                },
            },
        },
    })
;

export default theme;